import { replace } from "connected-react-router"
import { put, select } from "redux-saga/effects"

import api from "apis"
import {
  appendProductResult,
  setCollection,
  setLoading,
  setLoadingMoreProducts,
  setProductResult,
  setPriceRange,
  updateNewPageStartIndex,
} from "areas/collection/store/actions"
import { submit_ga_tracker } from "helpers/analytics-helpers"

import { interpolateProductsWithPages } from "./helpers/collection"

export default function* updateFilter(action) {
  let state = yield select()

  if (action.type === "UPDATE_CURRENT_PAGE") {
    yield put(setLoadingMoreProducts(true))
  } else {
    yield put(setLoading("content"))
  }

  let filteredTags = state.collection.filter_menu.all_collection_filters
    .filter((c) => c.design == state.collection.experiment_variation)
    .reduce((result, filter) => {
      filter.categories.forEach((category) => {
        if (category.is_active_filter) {
          result.push(category.filter_param)
        }
      })
      return result
    }, [])
    .reduce((acc, val) => acc.concat(val), [])

  let filteredPrice = state.collection.filtered_price_range

  // state.collection.pagination.current_sort
  let search = state.router.location.search
  let params = new URLSearchParams(search)
  let base_params_hash = {
    cat_path: state.collection.preserve_cat_path,
    default_price: state.collection.filter_menu.default_price_range,
    filtered_price: filteredPrice,
    filtered_tags: filteredTags,
    k: params.get("k") ? params.get("k") : undefined,
    price_range_updated: action.type == "UPDATE_PRICE_RANGE" ? true : null,
    utf8: params.get("utf8") ? params.get("utf8") : undefined,
  }
  let params_final = base_params_hash

  const filterSortAction =
    [
      "UPDATE_CURRENT_SORT",
      "UPDATE_FILTER",
      "UPDATE_PRICE_RANGE",
      "UPDATE_UNIQUE_FILTER",
      "CLEAR_CATEGORY_FILTERS",
      "RELOAD_COLLECTION",
    ].indexOf(action.type) !== -1

  if (filterSortAction || action.type == "UPDATE_CURRENT_PAGE") {
    let new_page_number =
      action.type == "UPDATE_CURRENT_PAGE" || action.type == "RELOAD_COLLECTION"
        ? state.collection.pagination.current_page_number
        : 1

    let newPageAndSort = {
      new_page: new_page_number,
      new_sort: state.collection.sort_menu.current_sort,
    }
    params_final = Object.assign(base_params_hash, newPageAndSort)
  }

  let result = yield api.post(
    `/api/v10/collections${state.router.location.pathname}/load_react_collection`,
    params_final
  )
  const currentProducts = state.collection.product_result

  if (result) {
    result = interpolateProductsWithPages(result)
    if (action.type === "UPDATE_CURRENT_PAGE") {
      yield put(updateNewPageStartIndex(currentProducts.length))
      yield put(appendProductResult(result.product_result))
    } else {
      yield put(setProductResult(result.product_result))
    }
    yield put(setCollection(result))
    yield put(
      setPriceRange(
        result.filtered_price_range.min,
        result.filtered_price_range.max
      )
    )

    if (!action.repeat) {
      yield put(replace(`/${result.pagination.current_page}`))
      submit_ga_tracker(`/${result.pagination.current_page}`)
    }
  }
}
