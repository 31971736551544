// import { replace } from "connected-react-router"
import { call, put, select } from "redux-saga/effects"

import api from "apis"
import {
  prependProductResult,
  replaceCollectionData,
} from "areas/collection/store/actions"

import { interpolateProductsWithPages } from "./helpers/collection"

export default function* loadReactCollection(action) {
  const state = yield select()

  let path = `/api/v10/collections/${
    action.data.url_path || "homepage"
  }/load_react_collection`
  let params = new URLSearchParams(state.router.location.search)

  let result = state.collection

  const currentPage = result.pagination.current_page_number

  // Get previous pages result for mobile
  if (currentPage > 1) {
    for (let page = currentPage - 1; page > 0; page = page - 1) {
      params.set("page", page)
      const url = `${path}?${params}`
      result = yield call(api.get, url)
      result = interpolateProductsWithPages(result)
      yield put(prependProductResult(result.product_result))
    }
  }

  yield put(
    replaceCollectionData({
      model: result.model,
    })
  )
}
