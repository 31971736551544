import Cookies from "js-cookie"
import queryString from "query-string"
import { call, put, select } from "redux-saga/effects"
import URI from "urijs"

import api from "apis"
import { setWishList } from "app-store-v2/app/actions"
import { setWishlistLoading } from "app-store-v2/wishlist/actions"
import { isTestMode } from "helpers/application"

export default function* addWishlist(action) {
  const wishlistToken = Cookies.get(sl.config.cookies_config.wishlist_token)

  var result
  const state = yield select()

  try {
    let addedLineItem = null

    if (action.source == "Product") {
      // adding from PDP
      const state = yield select()
      let itemChoices = {}
      Object.keys(state.choices).map((key) => {
        itemChoices[key] = state.choices[key].value
      })
      if (!state.wizardUI.prefStore) {
        return
      }

      yield put(setWishlistLoading(true))

      result = yield call(
        api.post,
        `/api/v10/wishlist/${wishlistToken}`,
        itemChoices
        // $("#item-form").serialize()
      )
      if (result) {
        const { data } = result
        try {
          addedLineItem = data.line_items[data.line_items.length - 1]
        } catch (e) {
          console.log("Added to Wishlist dataLayer error", e)
        }
      }
    } else if (
      action.source == "Collection" ||
      action.source == "Collection-Store"
    ) {
      // adding from collection page
      let productUrl = new URI(action.selectedProduct.product_url)
      let query = queryString.parse(productUrl.query())

      yield put(setWishlistLoading(true))

      result = yield call(api.post, "/api/v10/wishlist/add", {
        ...query,
        collection: state.router.location.pathname,
        line_item_id: "",
        price: action.selectedProduct.selling,
        style_code: action.style_code,
      })

      if (result) {
        const { data } = result
        try {
          addedLineItem = data.line_items[data.line_items.length - 1]
        } catch (e) {
          console.log("Added to Wishlist dataLayer error", e)
        }
      }
    }
    if (addedLineItem && !isTestMode) {
      const addedItem = {
        itemCurrencyCode: sl.config.currency_code,
        itemDetails: addedLineItem.line_item_details,
        itemImageUrl: addedLineItem.image_url,
        itemMetal: addedLineItem.sku,
        itemPrice: addedLineItem.price,
        itemShorthand: addedLineItem.item_shorthand,
        itemStyleCode: addedLineItem.style_code,
        itemTitle: addedLineItem.title,
        itemUrl: window.sl.config.secure_url.concat(addedLineItem.product_url),
      }

      dataLayer.push({
        addedItem: addedItem,
        contentIds: [addedLineItem.style_code],
        contentType: "product",
        currencyCode: sl.config.currency_code,
        event: "added-to-wishlist",
        ga4Items: [
          {
            currency: sl.config.currency_code,
            item_id: addedLineItem.item_variant,
            item_name: addedLineItem.style_code,
            item_variant: addedLineItem.sku,
            price: addedLineItem.price,
            quantity: 1,
          },
        ],
        productPrice: addedLineItem.price,
        styleCode: addedLineItem.style_code,
        wishListToken: window.Cookies.get(
          sl.config.cookies_config.wishlist_token
        ),
      })
    }
    // Update wishlist dropdown in header
    if (result) {
      yield put(setWishList(result.data))
    }
  } catch (e) {
    console.error(e)
  }
  yield put(setWishlistLoading(false))
}
