import { replace } from "connected-react-router"
import { put, select } from "redux-saga/effects"

import { loadWizard } from "../actions"

export default function* fetchStylecode(action) {
  const state = yield select()
  const skip_add_choices = action.skip_add_choices
  // Set the params for all choices in the URL without reloading the page
  const params = new URLSearchParams(location.search)

  if (!skip_add_choices) {
    const choices = Object.values(state.choices)
    choices.forEach((choice) => {
      if (
        choice &&
        choice.pref &&
        choice.pref != "style_code" &&
        choice.pref !== "gift" &&
        !choice.is_default
      ) {
        const paramValue = params.get(choice.pref)
        const pv = paramValue !== null ? paramValue : choice.value || ""
        if (pv != "") {
          params.set(choice.pref, pv)
        }
      }
    })
  }

  if (
    state.wizard.description.style_code &&
    state.wizard.description.style_code != action.style_code
  ) {
    if (state.previewImage.data.seo_permalink) {
      yield put(
        replace(
          `/products/${action.style_code}/${state.previewImage.data.seo_permalink}?${params}${location.hash}`
        )
      )
    } else {
      yield put(
        replace(`/products/${action.style_code}?${params}${location.hash}`)
      )
    }
  }

  yield put(
    loadWizard({
      adminMode: state.wizard.adminMode,
      olid: state.wizard.olid,
      style_code: action.style_code,
      switchMode: true,
    })
  )
}
