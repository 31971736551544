import Cookies from "js-cookie"
import { call, put, select } from "redux-saga/effects"

import api from "apis"
import { setCart } from "areas/cart/store/actions"
import { isTestMode, perfMark } from "helpers/application"

export default function* initCart() {
  perfMark("cart:init_cart:start")
  try {
    let state = yield select()

    let cartLineItems = state.cart.line_items_gtm
    const cart_token = Cookies.get(sl.config.cookies_config.cart_token)

    if (!state.cart.cartLoaded) {
      let url = `/api/v10/cart/${cart_token}`
      const result = yield call(api.get, url)
      if (result) {
        const { data } = result
        yield put(setCart(data))
        cartLineItems = data.line_items_gtm
      }
    }

    if (!isTestMode) {
      dataLayer.push({ cartLineItems })
    }

    perfMark("cart:init_cart:finish")
  } catch (error) {
    console.log(error)
  }
}
