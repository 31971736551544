// Reference: https://stackoverflow.com/a/56678483/65387

// Constants
// sRGB luminance(Y) values
const rY = 0.2126
const gY = 0.7152
const bY = 0.0722

// Customizable parameters

// Accurate value is `2.4` base on the formula
// Adjust to `3.1` to match the handwriting image generated from imgsmbly
const power = 3.1

/**
 *
 * @param {*} colorValue [0.0-1.0]
 * @returns a linearized value
 */
function sRGBtoLin(colorValue) {
  if (colorValue <= 0.04045) {
    return colorValue / 12.92
  }

  return Math.pow((colorValue + 0.055) / 1.055, power)
}

/**
 * @param r Red, [0-1]
 * @param g Green, [0-1]
 * @param b Blue, [0-1]
 * @returns Luminance, [0-1]
 */
function rgbToY(r, g, b) {
  return rY * sRGBtoLin(r) + gY * sRGBtoLin(g) + bY * sRGBtoLin(b)
}

/**
 * Luminance to perceived lightness.
 *
 * @param Y Luminance, [0-1]
 * @returns L* which is "perceptual lightness"
 */
function YtoLstar(Y) {
  if (Y <= 216 / 24389) {
    return Y * (24389 / 27)
  }
  return Math.pow(Y, 1 / 3) * 116 - 16
}

/**
 * Calculate perceived lightness from RGB
 *
 * @returns Lightness value, [0-100].
 */
function rgbHexToLightness([r, g, b]) {
  return YtoLstar(rgbToY(r / 255, g / 255, b / 255))
}

/**
 * Transform color pixel into monochrome one based on provided threshold
 * @param {*} param0
 * @param {*} threshold 0-255
 * @param {*} light [255, 255, 255]
 * @param {*} dark [0, 0, 0]
 * @returns light | dark
 */
function sRGB2BW([r, g, b], threshold, light, dark) {
  const lightness = rgbHexToLightness([r, g, b]) * (255 / 100)
  return lightness >= threshold ? light : dark
}

export { sRGB2BW }
