import { all, takeLatest, throttle } from "redux-saga/effects"

import {
  ADD_SAVED_ITEM,
  CHECK_APPLE_GOOGLE_PAY,
  EMPTY_CART,
  GENERATE_GIFT_VIDEO,
  REMOVE_COUPON,
  INIT_CART,
  MOVE_SAVED_ITEM_TO_CART,
  REMOVE_LINE_ITEM,
  REMOVE_SAVED_ITEM,
  SET_GIFT_WRAPPING,
  UPDATE_GIFT_PROPERTIES,
  ADD_ADDON,
  REMOVE_ADDON,
  SET_WARRANTY,
  APPLY_COUPON,
  SET_CART,
  SET_GREETING_CARD,
} from "./actions"
import addSavedItem from "./sagas/add-saved-item"
import applyCartChanges from "./sagas/apply-cart-changes"
import applyCouponChanges from "./sagas/apply-coupon-changes"
import cartUpdated from "./sagas/cart-updated"
import checkAppleGooglePay from "./sagas/check-apple-google-pay"
import emptyCart from "./sagas/empty-cart"
import generateGiftVideo from "./sagas/generate-gift-video"
import initCart from "./sagas/init-cart"
import moveSavedItemToCart from "./sagas/move-saved-item-to-cart"
import removeItem from "./sagas/remove-cart-list"
import removeGiftWrap from "./sagas/remove-gift-wrap"
import removeSavedItem from "./sagas/remove-saved-item"
import updateGiftProperties from "./sagas/update-gift-properties"

function* cartSagas() {
  yield all([
    takeLatest(INIT_CART, initCart),
    takeLatest(REMOVE_LINE_ITEM, removeItem),
    takeLatest(EMPTY_CART, emptyCart),
    takeLatest(SET_GIFT_WRAPPING, applyCartChanges),
    takeLatest(SET_GREETING_CARD, applyCartChanges),
    throttle(1000, ADD_ADDON, applyCartChanges),
    takeLatest(SET_WARRANTY, applyCartChanges),
    takeLatest("REMOVE_GIFT_WRAP", removeGiftWrap),
    takeLatest(REMOVE_ADDON, applyCartChanges),
    takeLatest(APPLY_COUPON, applyCouponChanges),
    takeLatest(REMOVE_COUPON, applyCartChanges),
    takeLatest(CHECK_APPLE_GOOGLE_PAY, checkAppleGooglePay),
    takeLatest(GENERATE_GIFT_VIDEO, generateGiftVideo),
    takeLatest(UPDATE_GIFT_PROPERTIES, updateGiftProperties),
    takeLatest(ADD_SAVED_ITEM, addSavedItem),
    takeLatest(MOVE_SAVED_ITEM_TO_CART, moveSavedItemToCart),
    takeLatest(REMOVE_SAVED_ITEM, removeSavedItem),
    takeLatest(SET_CART, cartUpdated),
  ])
}

export default cartSagas
