import Cookies from "js-cookie"
import { put } from "redux-saga/effects"

import api from "apis"
import { setCart, setGiftProperties } from "areas/cart/store/actions"
import { storePref } from "helpers/user-prefs"

export default function* updateGiftProperties(action) {
  try {
    const cartToken = Cookies.get(sl.config.cookies_config.cart_token)

    // Update gift properties to line item and set it in the store
    const giftData = {
      ...action.values,
      line_item_id: action.lineItemId,
    }
    const response = yield api.patch(
      `/api/v10/cart/${cartToken}/gift`,
      giftData
    )
    if (response) {
      yield put(setCart(response.data))
      if (action.values.previewUpdated) {
        yield put(
          setGiftProperties(action.lineItemId, { previewUpdated: true })
        )
      }
      // Update userPrefs so it can be set in PDP
      const lineItem = response.data.line_items.find(
        (li) => li.id == action.lineItemId
      )
      storePref("gift", lineItem.gift_properties)
    }
  } catch (error) {
    console.log(error)
  }
}
