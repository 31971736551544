import { isTestMode } from "helpers/application"

export default function cartUpdated(action) {
  let cartLineItems = action.cart.line_items
  if (!isTestMode) {
    try {
      if (cartLineItems) {
        dataLayer.push({
          cartLineItems: cartLineItems,
          event: "cart-updated",
        })
        dataLayer.push({
          event: "google-remarketing",
          googleRemarketing: {
            ecomm_pagetype: "cart",
            ecomm_prodid: cartLineItems.map((li) => li.style_code),
            ecomm_totalvalue: cartLineItems.reduce(
              (total, li) => parseFloat(li.price) + total,
              0
            ),
          },
        })
      }
    } catch (e) {
      console.log("Cart Updated dataLayer error", e)
    }
  }
}
