import Cookies from "js-cookie"
import { call, put, select } from "redux-saga/effects"

import api from "apis"
import { toggleCheckoutErrorModal, setCart } from "areas/cart/store/actions"

export default function* fetchCartList(action) {
  const state = yield select()

  // Prevent cart fetching on every hover - fetches only if cart object is empty or on cart page
  if (!state.cart.cartLoaded || action.forceFetch) {
    const cart_token = Cookies.get(sl.config.cookies_config.cart_token)
    if (!cart_token) {
      return
    }
    let url = `/api/v10/cart/${cart_token}`
    const result = yield call(api.get, url)
    if (result) {
      if (action.type == "FETCH_CART_LIST_CHECKOUT" && result.data.err_msg) {
        yield put(toggleCheckoutErrorModal(true, result.data.err_msg))
      } else {
        yield put(setCart(result.data))
      }
    }
  }
}
