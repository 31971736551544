import { call, put } from "redux-saga/effects"
import scroll from "scroll"
import scrollDoc from "scroll-doc"

import api from "apis"

import { setCart } from "../actions"

export default function* moveSavedItemToCart(action) {
  try {
    action.toggleLoading(true)
    const result = yield call(api.post, "/api/v5/carts/restore_saved_item", {
      line_item_id: action.lineItemId,
    })
    if (result.data.cart) {
      yield put(setCart(result.data.cart))
      const page = scrollDoc()
      scroll.top(page, 0)
      action.toggleLoading(false)
    }
  } catch (error) {
    console.error(error)
  }
}
