import Cookies from "js-cookie"
import { put, select } from "redux-saga/effects"

import { closeModal } from "components/modal"
import { validateLocalStorage } from "helpers/application"

import {
  doLogout,
  facebookConnect,
  facebookLogin,
  setUserData,
} from "../actions"

export default function* handleFacebookStatus(action) {
  const state = yield select()
  const response = action.response

  let user = {}
  let user_obj_string = window.localStorage.getItem("user_obj")
  let parsed_user_obj = user_obj_string ? JSON.parse(user_obj_string) : null

  if (response.status === "connected") {
    if (!Cookies.get("jwt")) {
      yield put(facebookLogin())
    } else {
      // Check if user_obj
      if (validateLocalStorage("user_obj", parsed_user_obj) === undefined) {
        yield put(facebookConnect(response))
      } else {
        yield put(setUserData(parsed_user_obj))
        // Close the login modal, if open
        if (state.modal.opened == "login") {
          yield put(closeModal())
        }
      }
    }
  } else if (!Cookies.get("facebook_declined")) {
    if (Cookies.get("jwt") && Cookies.get("provider") == "facebook") {
      yield put(doLogout(true))
    } else if (Cookies.get("jwt")) {
      user = JSON.parse(window.localStorage.getItem("user_obj"))
      yield put(setUserData(user))
    } else if (state.router.location.pathname == "/cart") {
      // Open facebook login modal
    }
  } else if (Cookies.get("jwt") && Cookies.get("provider") == "facebook") {
    yield put(doLogout(true))
  } else if (Cookies.get("jwt")) {
    user = JSON.parse(window.localStorage.getItem("user_obj"))
    yield put(setUserData(user))
  }
}

// window.showLoginButton = function() {
//   // Remove the Facebook Sign Out button and replace it with a Sign In one
//   $(".js-log-in-link").show()
//   $(".js-log-out-link").hide()
//   $(".js-account-block").hide()
//   $(".js-alternative-account-block").show()
//   $(".js-profile-name").empty()
//   $(".js-profile-subname").empty()
//   profile_pic = $(".js-account-profile-pic")
//   profile_pic.prop("src", profile_pic.first().data("orig-src"))
//   //internalFacebookButton();
// }

// window.internalFacebookButton = function() {
//   $(".fb-login-button").each(function() {
//     $(this).after(
//       '<div class="fb-login-button" data-max-rows="1" data-size="large" data-show-faces="false" data-auto-logout-link="true" onlogin="facebookLogin();">'
//     )
//     $(this).remove()
//   })
//   FB.XFBML.parse()
// }
