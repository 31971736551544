import loadjs from "loadjs"

export default function initializeFacebook(action) {
  if (!loadjs.isDefined("facebook_sdk")) {
    loadjs(["https://connect.facebook.net/en_US/sdk.js"], "facebook_sdk", {
      success: () => {
        FB.init({
          appId: sl.api.facebook.app_id,
          autoLogAppEvents: true,
          version: "v18.0",
          xfbml: true,
        })

        action()
      },
      error: (failedToLoadPaths) => {
        resolve("Failed to load Facebook SDK")
      },
    })
  } else {
    action()
  }
}
