import { MediaContextProvider } from "@jewlr/storybook/core"
import loadable from "@loadable/component"
import { SSRProvider } from "@react-aria/ssr"
import { ConnectedRouter } from "connected-react-router"
import React, { useEffect, useRef } from "react"
import { ThemeProvider } from "styled-components"

import { history } from "app-store-v2"
import SimpleDeferAfterLoadWrapper from "components/common/simple-defer-load-wrapper"
import ResetFocus from "components/reset-focus"
import { retry } from "helpers/application"
import { initialLazySizes } from "initializers/utils"
import orgTheme from "styles/org-theme"

import AppReduxProvider from "./AppReduxProvider"

const FullLoading = loadable(() =>
  retry(() => import("components/full-loading"))
)
const ScrollTracker = loadable(() =>
  retry(() => import("components/scroll-tracker"))
)
const AuthenticateModal = loadable(() =>
  retry(() => import("areas/authenticate"))
)
const ForgotPasswordModal = loadable(() =>
  retry(() => import("areas/authenticate/forgot-password"))
)
const LogoutModal = loadable(() =>
  retry(() => import("areas/authenticate/logout-modal"))
)
const ShareProductModal = loadable(() =>
  retry(() => import("areas/header/modals/share-products"))
)
const GeoModal = loadable(() => retry(() => import("app/locale-dialog")))

const ReferralRegisterModal = loadable(() =>
  retry(() => import("areas/authenticate/referral-register"))
)

const AppModals = () => {
  return (
    <SimpleDeferAfterLoadWrapper>
      <ScrollTracker />
      <FullLoading />
      <AuthenticateModal />
      <ForgotPasswordModal />
      <ShareProductModal />
      <LogoutModal />
      <ReferralRegisterModal />
      <GeoModal />
    </SimpleDeferAfterLoadWrapper>
  )
}

export const withClientProviders = (Component) => {
  const InternalWithClientProviders = (props) => {
    const navTopRef = useRef()
    const skippedRef = useRef()

    useEffect(() => {
      initialLazySizes()

      let timer = setTimeout(() => {
        window.location.reload()
      }, 3600000) // 1 hr

      return history.listen(() => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          window.location.reload()
        }, 3600000) // 1 hr
      })
    }, [])

    const handleSkipToMainContent = (e) => {
      skippedRef.current?.focus()
      e.preventDefault()
      e.stopPropagation()
    }

    return (
      <SSRProvider>
        <ThemeProvider theme={orgTheme()}>
          <AppReduxProvider {...props}>
            <MediaContextProvider>
              <ConnectedRouter history={history}>
                <ResetFocus navTopRef={navTopRef} />
                <Component
                  {...props}
                  handleSkipToMainContent={handleSkipToMainContent}
                  navTopRef={navTopRef}
                  skippedRef={skippedRef}
                />
                <AppModals />
              </ConnectedRouter>
            </MediaContextProvider>
          </AppReduxProvider>
        </ThemeProvider>
      </SSRProvider>
    )
  }

  return InternalWithClientProviders
}
