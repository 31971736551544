import { put, select } from "redux-saga/effects"

import filterMatch from "helpers/product/filter-match"
import { filterProductOptionsV2 } from "helpers/product/product-filtering"
import mapStoneCodeToSimilarStoneCode from "helpers/similar-stones"
import { getPref } from "helpers/user-prefs"

import { updateChoice } from "../actions"

export default function* recaptureDataIntoChoice(action) {
  const state = yield select()
  const pref = action.data.pref
  const oldData = state.choices[pref]
  let imgPref

  const filters = action.data.filters
  const uiPreRenderChoices = action.data.uiPreRenderChoices

  if (typeof filters !== "undefined" && Object.keys(filters).length > 0) {
    const validateChoices = JSON.parse(JSON.stringify(state.choices))
    if (uiPreRenderChoices) {
      for (const [key, value] of Object.entries(uiPreRenderChoices)) {
        if (validateChoices[key]) validateChoices[key].value = value
      }
    }
    const subFilterKeys = Object.keys(filters)
    // Check all other sub filters for an AND match on the rest of the chosen options
    const validKeys = subFilterKeys.filter(
      (sfkey) =>
        !!filters[sfkey].find(
          (r) =>
            state.choices[sfkey] && filterMatch(r, validateChoices[sfkey].value)
        )
    )

    // Do not update pref if pref has filters and current choices do not match
    if (subFilterKeys.length !== validKeys.length) {
      return
    }
  }

  // We can rely on initWizard to do things when prefStore isn't active
  // if (!state.wizardUI.prefStore) {
  //   return
  // }
  if (action.data.disabled) {
    yield put(updateChoice(pref, { disabled: true }))
    return
  }

  if (
    typeof oldData !== "undefined" &&
    typeof action.data.options !== "undefined"
  ) {
    const data = action.data.options.find((opt) => {
      return opt.value == oldData.value
    })
    if (data) {
      yield put(updateChoice(pref, data))
    } else {
      let oldVal = null
      if (oldData.default_value) {
        oldVal = oldData.default_value
      } else {
        oldVal = oldData.value
      }
      let defaultData = null
      let stoneCodeArray = mapStoneCodeToSimilarStoneCode(oldVal)
      if (typeof stoneCodeArray !== "undefined" && stoneCodeArray != "") {
        stoneCodeArray.forEach((stone_code) => {
          defaultData =
            action.data.options.find((opt) => {
              return opt.value == stone_code
            }) || defaultData
        })
      } else {
        defaultData = action.data.options.find((opt) => {
          return opt.value == oldVal
        })
      }
      if (defaultData) {
        yield put(updateChoice(pref, defaultData))
      } else if (action.data.options.length == 1) {
        defaultData = action.data.options[0]
        yield put(updateChoice(pref, defaultData))
      } else if (pref[0] === "s" && action.data.options.length > 1) {
        const freeOptions = action.data.options.filter(
          (option) => option.price === 0
        )
        if (freeOptions.length > 0) {
          defaultData = freeOptions[0]
        } else {
          defaultData = action.data.options[0]
        }
        yield put(updateChoice(pref, defaultData))
      }
    }
  } else if (action.data.default_engraving && action.data.is_default) {
    yield put(
      updateChoice(pref, {
        is_default: action.data.is_default || true,
        value: action.data.default_engraving,
      })
    )
  } else if (state.wizardUI.prefStore) {
    const userPref = getPref(pref)
    let prefOpt = null
    if (userPref) {
      prefOpt = action.data.options?.find((opt) => opt.value == userPref)
      if (prefOpt) {
        yield put(updateChoice(pref, prefOpt))
      }
    }
    if (!prefOpt) {
      if (action.data.options) {
        prefOpt = action.data.options.find((opt) => opt.price == 0)
        if (prefOpt) {
          yield put(updateChoice(pref, prefOpt))
        } else {
          yield put(updateChoice(pref, action.data.options[0]))
        }
      } else {
        if (action.data.filters) {
          // fetch the cached image based on sizes
          if (action.data.cropper) {
            imgPref = pref
            if (state.wizard.adminMode) {
              imgPref = "admin_" + pref
            }
            const imageCropper = action.data.cropper
            const currentUrl = oldData?.value
            const uploadedUrl =
              currentUrl ||
              getPref(
                imgPref +
                  "_" +
                  imageCropper.aspect_ratio_width +
                  "_" +
                  imageCropper.aspect_ratio_height
              ) ||
              ""
            yield put(updateChoice(pref, { value: uploadedUrl }))
          }

          // e.g., JADD0004-BLACK
          // when the o3 triggers e2,3,4 updates -> handle the ef2 update
          if (
            action.data.engraving_font &&
            state.wizard.data[action.data.engraving_font].filters &&
            Object.keys(state.wizard.data[action.data.engraving_font].filters)
              .length
          ) {
            const wizardData = state.wizard.data[action.data.engraving_font]
            const options = wizardData.options || []
            const engravingFontOptions = filterProductOptionsV2(
              options,
              state.choices,
              true
            )
            const defaultEngravingFontOpt = engravingFontOptions[0]
            const isMatch = engravingFontOptions.filter(
              (opt) =>
                opt.value == state.choices[action.data.engraving_font]?.value
            )
            if (defaultEngravingFontOpt && !isMatch.length) {
              yield put(
                updateChoice(
                  action.data.engraving_font,
                  {
                    pref: action.data.engraving_font,
                    ...defaultEngravingFontOpt,
                  },
                  true
                )
              )
            }
          }

          // enable options controlled by others
          yield put(updateChoice(pref, { disabled: false }))
        } else {
          yield put(updateChoice(pref, { value: "" }))
        }
      }
    }
  } else {
    yield put(
      updateChoice(pref, { missing_msg: action.data.missing_msg, value: "" })
    )
  }
}
