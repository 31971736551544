import { replace, push } from "connected-react-router"

import api from "apis"
import { history } from "app-store-v2"
import {
  errorModalOpen,
  loadOrderData,
  setPaymentMethod,
} from "areas/checkout/store/actions"
import { jewlr_link_decorator } from "helpers/analytics-helpers"
import { isTestMode } from "helpers/application"
import { getExpressOptions } from "helpers/checkout/checkout_options"

export const submitInitiateCheckoutEvent = (checkoutInitiatedEventId) => {
  if (typeof window === "undefined") {
    return
  }
  if (!isTestMode) {
    try {
      var cartData = null
      cartData = appStoreV2.getState().cart
      dataLayer.push({
        cartUniqueEventId: cartData?.summary?.unique_event_id,
        checkoutInitiatedEventId: checkoutInitiatedEventId,
        /* GA Enhanced Ecommerce */
        currencyCode: sl.config.currency_code,
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
            },
            products: cartData?.line_items?.map(function (line_item) {
              return {
                category: line_item.collection_url,
                id: line_item.item_shorthand,
                name: line_item.style_code,
                price: line_item.price,
                quantity: 1,
                variant: "",
              }
            }),
          },
        },
        event: "checkout-initiated",
        /* GA Event Params */
        facebookContentIds: cartData?.line_items?.map(function (line_item) {
          return line_item.style_code
        }),
        // GA4 Variable
        ga4Items: cartData?.line_items?.map((line_item) => ({
          currency: sl.config.currency_code,
          item_category: line_item.product_category,
          item_id: line_item.item_variant,
          item_list_name: line_item.collection_url,
          item_name: line_item.style_code_gtm,
          price: line_item.price,
          quantity: 1,
          variant: line_item.sku,
        })),
        // END GA4 Variable
        gaEventAction: "Initiated",
        gaEventCategory: "Checkout",
        gaEventLabel: "",
        gaEventNonInteraction: true,

        orderCurrencyCode: cartData?.summary?.currency_code,
        orderLineItemStyleCodes: cartData?.line_items?.map(function (
          line_item
        ) {
          return line_item.style_code
        }),
        orderLineItemTotal: cartData?.summary?.line_items_total,
        orderQuantity: cartData?.line_items?.length,
        /* Deprecated: facebookContentIds will be replaced by orderLineItemStyleCodes */

        orderShippingTotal: 0,
        orderTaxesTotal: 0,
        orderTotal: cartData?.summary?.cart_total,
        orderTotalBeforeShippingTaxes: cartData?.summary?.cart_total,
      })
    } catch (e) {
      console.log("Error sending checkout-initiated event", e)
    }
  }
}

export const submitPaymentMethodSelectedEvent = (checkout_type) => {
  if (!isTestMode) {
    try {
      dataLayer.push({
        checkoutType: checkout_type,
        event: "payment-method-selected",
        gaEventValue: checkout_type,
      })
    } catch (e) {
      console.log("Error sending payment-method-selected event", e)
    }
  }
}

export const submitCheckoutContactInfoSubmittedEvent = () => {
  if (!isTestMode) {
    try {
      dataLayer.push({
        event: "checkout-contact-info-submitted",
      })
    } catch (e) {
      console.log("Error sending checkout-contact-info-submitted event", e)
    }
  }
}

export const submitCheckoutSignedinEvent = () => {
  if (!isTestMode) {
    try {
      dataLayer.push({
        event: "checkout-signed-in",
      })
    } catch (e) {
      console.log("Error sending checkout-signed-in event", e)
    }
  }
}

export const submitCheckoutShippingAddressSubmittedEvent = () => {
  if (!isTestMode) {
    try {
      dataLayer.push({
        event: "checkout-shipping-address-submitted",
      })
    } catch (e) {
      console.log("Error sending checkout-shipping-address-submitted event", e)
    }
  }
}

export const submitCheckoutShippingMethodSelectedEvent = () => {
  if (!isTestMode) {
    try {
      dataLayer.push({
        event: "checkout-shipping-method-selected",
      })
    } catch (e) {
      console.log("Error sending checkout-shipping-method-selected event", e)
    }
  }
}

export const submitCheckoutCreditCardInfoSubmittedEvent = () => {
  if (!isTestMode) {
    try {
      dataLayer.push({
        event: "checkout-credit-card-info-submitted",
      })
    } catch (e) {
      console.log("Error sending checkout-credit-card-info-submitted event", e)
    }
  }
}

export const submitCheckoutAppliedGiftCardEvent = () => {
  if (!isTestMode) {
    try {
      dataLayer.push({
        event: "checkout-applied-gift-card",
      })
    } catch (e) {
      console.log("Error sending checkout-applied-gift-card event", e)
    }
  }
}

export const submitCheckoutAppliedPointsEvent = () => {
  if (!isTestMode) {
    try {
      dataLayer.push({
        event: "checkout-applied-points",
      })
    } catch (e) {
      console.log("Error sending checkout-applied-points event", e)
    }
  }
}

export const submitSecureCheckout = async (
  checkoutType,
  dispatch,
  params = {
    callback: null,
    order_info: null,
    switchMethod: false,
  }
) => {
  let { callback, order_info, switchMethod } = params
  const { requestShipping } = getExpressOptions()
  order_info = {
    ...order_info,
    express_checkout: requestShipping,
  }
  const checkoutUrl = "/api/v5/carts/checkout"
  await api
    .post(checkoutUrl, {
      checkout_type: checkoutType,
      link_params: jewlr_link_decorator(""),
      order_info_params: order_info,
      // eslint-disable-next-line no-undef
      token: Cookies.get(sl.config.cookies_config.cart_token),
    })
    .then((response) => {
      if (response.unique_event_id) {
        submitInitiateCheckoutEvent(response.unique_event_id)
      }

      if (typeof callback === "function" && checkoutType !== "paypal") {
        callback()
      }

      let redirectUrl = response.redirect_url
      if (checkoutType?.includes("paypal")) {
        redirectUrl = sl.basename + redirectUrl
        if (!redirectUrl.includes("cart_checkout")) {
          redirectUrl += "&cart_checkout=true"
        }
        if (!redirectUrl.includes("order_token")) {
          redirectUrl += `&order_token=${response.data.order.token}`
        }
        window.location.href = redirectUrl
        return
      }

      if (response.data?.order && response.data?.order.token) {
        dispatch(loadOrderData(response.data, checkoutType))

        if (!redirectUrl.includes("order_token")) {
          redirectUrl += `&order_token=${response.data.order.token}`
        }
        if (checkoutType === "affirm" || checkoutType === "financing") {
          redirectUrl += "&payment_method=financing"
          if (switchMethod) {
            redirectUrl += "#payment_methods"
          }
          dispatch(replace(redirectUrl))
          return
        } else if (checkoutType === "klarna") {
          redirectUrl += "&payment_method=klarna"
          if (switchMethod) {
            redirectUrl += "#payment_methods"
          }
          dispatch(replace(redirectUrl))
          return
        }
      }

      if (redirectUrl) {
        if (switchMethod) {
          redirectUrl += "#payment_methods"
        }
        dispatch(push(redirectUrl))
      } else {
        console.error("Unknown error response", response)
      }
    })
    .catch((err) => {
      const error_msg = err?.error || (err?.errors?.length && err.errors[0])
      console.error(err)
      if (typeof callback === "function") {
        callback()
      }
      dispatch(
        replace(`${history.location.pathname}${history.location.search}`)
      )
      dispatch(errorModalOpen(error_msg))
      return
    })
}

export const updatePaymentMethod = async (
  checkoutType,
  dispatch,
  orderToken,
  params = {
    callback: null,
    switchMethod: false,
  }
) => {
  let { callback, switchMethod } = params
  await api
    .put("/api/v10/checkout/payment_method", {
      checkout_type: checkoutType,
      link_params: jewlr_link_decorator(""),
      order_token: orderToken,
    })
    .then((response) => {
      if (typeof callback === "function" && checkoutType !== "paypal") {
        callback()
      }

      const { data } = response

      let redirectUrl = data.redirect_url
      if (checkoutType?.includes("paypal")) {
        redirectUrl = sl.basename + redirectUrl
        if (!redirectUrl.includes("cart_checkout")) {
          redirectUrl += "&cart_checkout=true"
        }
        if (!redirectUrl.includes("order_token")) {
          redirectUrl += `&order_token=${orderToken}`
        }
        window.location.href = redirectUrl
        return
      } else {
        dispatch(setPaymentMethod(data.checkout_type))

        if (!redirectUrl.includes("order_token")) {
          redirectUrl += `&order_token=${orderToken}`
        }
        if (checkoutType === "affirm" || checkoutType === "financing") {
          redirectUrl += "&payment_method=financing"
          if (switchMethod) {
            redirectUrl += "#payment_methods"
          }
          dispatch(replace(redirectUrl))
          return
        } else if (checkoutType === "klarna") {
          if (!redirectUrl.includes("panel")) {
            redirectUrl += "&panel=ship_methods"
          }
          redirectUrl += "&payment_method=klarna"
          if (switchMethod) {
            redirectUrl += "#payment_methods"
          }
          dispatch(replace(redirectUrl))
          return
        }
      }

      if (redirectUrl) {
        if (switchMethod) {
          redirectUrl += "#payment_methods"
        }
        dispatch(push(redirectUrl))
      } else {
        console.error("Unknown error response", response)
      }
    })
    .catch((err) => {
      const error_msg = err?.error || (err?.errors?.length && err.errors[0])
      console.error(err)
      if (typeof callback === "function") {
        callback()
      }
      dispatch(
        replace(`${history.location.pathname}${history.location.search}`)
      )
      dispatch(errorModalOpen(error_msg))
      return
    })
}
