import { call, put, select } from "redux-saga/effects"

import api from "apis"
import { setLocalStorage } from "helpers/application"

import { setUserAddresses } from "../actions"

export default function* addUserAddress({
  actions: { onError, onSuccess },
  address,
}) {
  const state = yield select()
  const user_token = state.user?.jewlrorders?.guid_token
  const url = "/secure/api/v10/user/address"

  const body = {
    country: sl.config.site_country.toLowerCase(),
    guid_token: user_token,
    user_address: address,
  }

  try {
    const response = yield call(api.authPost, url, body)

    if (response?.success && response.data) {
      yield put(setUserAddresses(response.data.address_list))
      setLocalStorage(state.user.jewlrorders, "user_obj")
      onSuccess(response.data.address_list)
    }
  } catch (err) {
    onError(err?.errors?.[0])
    console.log(err)
  }
}
