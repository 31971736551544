import { put } from "redux-saga/effects"

import { mayUpdateSearchSuggestions } from "app-store-v2/app/actions"

const SEARCH_STORAGE = "jewlr4-suggestions"
const SAVED_SEARCH = "savedSearch"

export default function* displayUserSearchHistory() {
  const savedQueries = localStorage.getItem(SEARCH_STORAGE)
  let savedSuggestions = savedQueries ? JSON.parse(savedQueries) : []

  yield put(mayUpdateSearchSuggestions(savedSuggestions, SAVED_SEARCH))
}
