export const SET_LANDING = "SET_LANDING"
export const SET_LANDING_LOADING = "SET_LANDING_LOADING"
export const FETCH_LANDING_ELEMENTS = "FETCH_LANDING_ELEMENTS"
export const SET_FETCHING_ELEMENTS_LOADING = "SET_FETCHING_ELEMENTS_LOADING"

/**
 * Populates `state.landing`
 * @param {object} landing - all landing data needed to render page
 */
export function setLanding(landing) {
  return { landing, type: SET_LANDING }
}

/**
 * Set `state.landing.loading`
 * @param {bool} toggle - floag to indicate load state
 */
export function setLandingLoading(toggle) {
  return { toggle, type: SET_LANDING_LOADING }
}

export function fetchLandingElements() {
  return { type: FETCH_LANDING_ELEMENTS }
}

export function setFetchingElementsLoading(toggle) {
  return { toggle, type: SET_FETCHING_ELEMENTS_LOADING }
}
