import Cookies from "js-cookie"
import { call, put, select } from "redux-saga/effects"

import api from "apis"
import { setWishList, setWishlistLoading } from "app-store-v2/wishlist/actions"

export default function* fetchWishList(action) {
  const state = yield select()

  // Prevent wishlist fetching on every hover - fetches only if forced to, wishlist object is empty or on product page
  if (
    action.forceFetch ||
    !state.wishlist.show ||
    state.router.location.pathname.includes("products")
  ) {
    const wishlistToken = Cookies.get(sl.config.cookies_config.wishlist_token)

    if (!wishlistToken) {
      return
    }

    let url = `/api/v10/wishlist/${wishlistToken}`
    try {
      yield put(setWishlistLoading(true))

      const result = yield call(api.get, url)
      yield put(setWishList(result.data))
    } catch (error) {
      console.log(`Error in fetchWishlist: ${error}`)
    }
    yield put(setWishlistLoading(false))
  }
}
