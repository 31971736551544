import { combineReducers } from "redux"

function landing(
  state = {
    all_elements_fetched: false,
    elements: [],
    elements_list: [],
    experiments: {},
    fetching_elements_loading: false,
    loading: false,
    model: "",
    products: [],
    seo_description: "",
    seo_title: "",
    title: "",
    url_path: "",
  },
  action
) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case "SET_LANDING":
      newState.elements = action.landing.elements
      newState.elements_list = action.landing.elements_list
      newState.experiments = action.landing.experiments
      newState.loading = false
      newState.fetching_elements_loading = false
      newState.model = action.landing.model
      newState.products = action.landing.products || []
      newState.seo_description = action.landing.seo_description
      newState.seo_title = action.landing.seo_title
      newState.title = action.landing.title
      newState.url_path = action.landing.url_path
      newState.all_elements_fetched = action.landing.all_elements_fetched
      return newState
    case "SET_LANDING_LOADING":
      newState.loading = action.toggle
      return newState
    case "SET_FETCHING_ELEMENTS_LOADING":
      newState.fetching_elements_loading = action.toggle
      return newState
    default:
      return state
  }
}

const landingReducer = combineReducers({
  landing,
})

export { landingReducer }
export default landing
