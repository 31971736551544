import {
  all,
  cancel,
  debounce,
  delay,
  take,
  fork,
  join,
  race,
  // select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects"

import addBuilderItem from "./sagas/add-builder-item"
import addFinalDiamond from "./sagas/add-final-diamond"
import addToCart from "./sagas/add-to-cart"
import analyticsEvents from "./sagas/analytics-events"
import checkAvailability from "./sagas/check-availability"
import cropUploadUserImage from "./sagas/crop-upload-user-image"
import diamondStartOver from "./sagas/diamond-start-over"
import editUserImage from "./sagas/edit-user-image"
import fetchInventory from "./sagas/fetch-inventory"
import fetchStylecode from "./sagas/fetch-stylecode"
import fetchWarrantyOffers from "./sagas/fetch-warranty-offers"
import filterChoices from "./sagas/filter-choices"
import getPreviewImage from "./sagas/get-preview-image"
import getShipEstimate from "./sagas/get-ship-estimate"
import initWizard from "./sagas/init-wizard"
import loadWizard from "./sagas/load-wizard"
import moveBuilderItem from "./sagas/move-builder-item"
import recaptureDataIntoChoice from "./sagas/recapture-data-into-choice"
import recordPersonalizationEvent from "./sagas/record-personalization-event"
import rejectInvalidChoices from "./sagas/reject-invalid-choices"
import removeBuilderItem from "./sagas/remove-builder-item"
import setBuilderItems from "./sagas/set-builder-items"
import subscribeNotification from "./sagas/subscribe-notification"
import updateDiamond from "./sagas/update-diamond"
import updateImages from "./sagas/update-images"
import updatePriceAndShippingTime from "./sagas/update-price-shipping-time"
import uploadUserImage from "./sagas/upload-user-image"

const processInOrder = (lastTask, action, patternOrChannel, saga, ...args) =>
  fork(function* () {
    yield join(lastTask)
    yield fork(saga, ...args.concat(action))
  })

const takeInOrder = (patternOrChannel, saga, ...args) =>
  fork(function* () {
    let lastTask
    while (true) {
      const action = yield take(patternOrChannel)
      if (lastTask) {
        // We need to use a fork function otherwise takeInOrder will start blocking.
        // The fork function contains the join effect so if it blocks, it won't stop the main effect
        yield processInOrder(lastTask, action, patternOrChannel, saga, ...args)
      } else {
        lastTask = yield fork(saga, ...args.concat(action))
      }
    }
  })

let previewImageTask = null
// let checkAvailabilityTask = null

function* cancelGetPreviewImage() {
  if (previewImageTask) {
    yield cancel(previewImageTask)
  }
}

function* doGetPreviewImage(action) {
  previewImageTask = yield fork(getPreviewImage, action)
}

// function* cancelCheckAvailablity() {
//   if (checkAvailabilityTask) {
//     yield cancel(checkAvailabilityTask)
//   }
// }

// function* doCheckAvailability(action) {
//   checkAvailabilityTask = yield fork(checkAvailability, action)
// }

const debounceChoice = (ms, firstAction, task, ...args) =>
  fork(function* () {
    try {
      const patternMatch = (action) =>
        action.type === firstAction.type &&
        action.pref == firstAction.pref &&
        action.fromUser

      // We already have a captured action unlike the native redux-saga debounce,
      // so use that and trigger a single loop instead of a nested loop
      let action = firstAction

      while (true) {
        const { debounced, latestAction } = yield race({
          debounced: delay(ms),
          latestAction: take(patternMatch),
        })

        if (debounced) {
          // Call the actual saga
          yield fork(task, ...args, action)

          // This initiates the next debounce after the last successful debounce
          action = yield take(patternMatch)
        } else {
          // Set latestAction to action to race against delay again
          action = latestAction
        }
      }
    } catch (e) {
      console.error("DEBOUNCE CHOICE ERROR", e)
    }
  })

// Special debouncer that only takes user-selected inputs every 5 seconds.
// Will debounce per pref instead of every choice, to ensure that all intentional
// choices are eventually captured.
const debounceChoices = (ms, pattern, task, ...args) =>
  fork(function* () {
    try {
      let prefsRunning = []
      let prefDebouncers = {}
      while (true) {
        let action = yield take(
          (action) =>
            pattern.includes(action.type) &&
            action.fromUser &&
            prefsRunning.indexOf(action.pref) === -1
        )

        // Debounce this particular pref in the background moving forward
        // Fork is handled in the debounceChoice function itself
        prefDebouncers[action.pref] = yield debounceChoice(
          ms,
          action,
          task,
          ...args
        )

        // By pushing the the choiceDebounces array, subsequent actions aren't
        // triggered from this function anymore since they are now handled in a
        // child debounceChoice function
        prefsRunning.push(action.pref)
      }
    } catch (e) {
      console.error("DEBOUNCE CHOICES ERROR", e)
    }
  })

function* productSagas() {
  yield all([
    // takeLatest("LOAD_DEFAULTS", getPreviewImage),
    takeLatest("LOAD_DEFAULTS", initWizard),
    // takeLatest("ACTIVATE_PREF_STORE", doGetPreviewImage),
    takeLatest("ACTIVATE_PREF_STORE", updatePriceAndShippingTime),
    takeLatest("ACTIVATE_PREF_STORE", rejectInvalidChoices),
    // debounce(2000, "CHECK_AVAILABILITY", doCheckAvailability),
    debounce(500, "CHECK_AVAILABILITY", checkAvailability),
    debounce(
      1000,
      ["CLEAR_DEFAULT_ENGRAVINGS", "UPDATE_TMP_DIAMOND"],
      doGetPreviewImage
    ),
    // Using debounceChoices to make sure doGetPreviewImage will take latest action that fired from user (fromUser == true)
    debounceChoices(1000, "UPDATE_CHOICE", doGetPreviewImage),
    takeInOrder("UPDATE_CHOICE", filterChoices),
    takeLatest("UPDATE_CHOICE", updatePriceAndShippingTime),
    takeLatest("LOAD_WIZARD", loadWizard),
    takeEvery("LOAD_WIZARD", cancelGetPreviewImage),
    // takeEvery("LOAD_WIZARD", cancelCheckAvailablity),
    takeEvery("ADD_WIZARD_DATA", recaptureDataIntoChoice),
    takeEvery("SET_WIZARD_DATA", recaptureDataIntoChoice),
    takeEvery("ADD_FINAL_DIAMOND", addFinalDiamond),
    takeEvery("SET_SIGNATURE_BASKET", updateDiamond),
    takeEvery("UPDATE_DIAMOND", updateDiamond),
    takeEvery("UPLOAD_USER_IMAGE", uploadUserImage),
    takeEvery("CROP_UPLOAD_USER_IMAGE", cropUploadUserImage),
    takeEvery("EDIT_USER_IMAGE", editUserImage),
    takeLatest("TOGGLE_PANEL_INDEX", analyticsEvents),
    takeLatest("TRACK_SLIDE", analyticsEvents),
    takeLatest("ADD_TO_CART", addToCart),
    takeLatest("GET_SHIP_ESTIMATE", getShipEstimate),
    takeLatest("ADD_BUILDER_ITEM", addBuilderItem),
    takeLatest("SET_BUILDER_ITEMS", setBuilderItems),
    takeLatest("REMOVE_BUILDER_ITEM", removeBuilderItem),
    takeLatest("MOVE_BUILDER_ITEM", moveBuilderItem),
    takeLatest("FETCH_INVENTORY", fetchInventory),
    takeLatest("FETCH_STYLECODE", fetchStylecode),
    takeLatest("FETCH_WARRANTY_OFFERS", fetchWarrantyOffers),
    takeEvery("FETCH_STYLECODE", cancelGetPreviewImage),
    // takeEvery("FETCH_STYLECODE", cancelCheckAvailablity),
    debounceChoices(5000, "UPDATE_CHOICE", recordPersonalizationEvent),
    takeLatest("TOGGLE_PANEL_INDEX", recordPersonalizationEvent),
    takeLatest("DIAMOND_START_OVER", diamondStartOver),
    debounce(500, "UPDATE_IMAGES", updateImages),
    takeLatest("SUBSCRIBE_NOTIFICATION", subscribeNotification),
  ])
}

export default productSagas
