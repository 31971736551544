import { call, put } from "redux-saga/effects"

import api from "apis"
import { history } from "app-store-v2/index"

import { setUserPreferences } from "../actions"

function* unsubscribeUser({ guidToken }) {
  try {
    const response = yield call(
      api.delete,
      `/secure/api/v10/preferences/${guidToken}`
    )

    if (response && response.data) {
      yield put(setUserPreferences(response.data))
    }
  } catch (err) {
    console.log("Error while fetching preferences", err)
  }

  history.push("/email-preferences/unsubscribe-complete")
}
export default unsubscribeUser
