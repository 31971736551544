import PropTypes from "prop-types"
import { useState, useEffect } from "react"

const SimpleDeferAfterLoadWrapper = ({ children }) => {
  const [showModals, setShowModals] = useState(false)

  useEffect(() => {
    // Prevent blocking main thread
    const timer = setTimeout(() => setShowModals(true), 0)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  if (showModals) {
    return children
  }

  return null
}

SimpleDeferAfterLoadWrapper.propTypes = {
  children: PropTypes.node,
}

export default SimpleDeferAfterLoadWrapper
