import { replace } from "connected-react-router"
import Cookies from "js-cookie"
import { put, select } from "redux-saga/effects"
import URI from "urijs"

import api from "apis"
import { setFacebookLoginStatus } from "app-store-v2/app/actions"
import { initCart, deleteSavedCart } from "areas/cart/store/actions"
import { setUserOnCheckoutLogin } from "areas/checkout/store/actions"
import initializeFacebook from "helpers/facebook-initialize"

const facebookLogout = () =>
  new Promise(() => {
    initializeFacebook(() => {
      FB.getLoginStatus(function (response) {
        if (response && response.status === "connected") {
          FB.logout(function (response) {
            console.log(response)
          })
        }
      })
    })
  })

export default function* doLogout(action) {
  try {
    const state = yield select()
    const query = URI.parseQuery(state.router.location.search)

    var provider = Cookies.get("provider")
    var params_hash = {}
    params_hash.order_token = query.order_token
    window.localStorage.clear("user_obj")
    Cookies.remove("jwt")
    Cookies.remove("jwt_options")
    Cookies.remove("provider")
    Cookies.remove("access_token")
    Cookies.remove("refresh_token")
    Cookies.remove("token_expiry")
    Cookies.remove(sl.config.cookies_config.wishlist_token)
    Cookies.remove("saved_cart_token")
    if (state.router.location.pathname !== "/checkout") {
      Cookies.remove(sl.config.cookies_config.cart_token)
    }
    const response = yield api.post("/api/v10/logout", params_hash)
    if (response?.data) {
      if (provider == "facebook" && !action.forceInternal) {
        const response = yield facebookLogout()
        // This sends the response back to handle-facebook-status, which will call this saga again with forceInternal
        yield put(setFacebookLoginStatus(response))
      }
      if (query.order_token && query.order_token !== "") {
        yield put(
          setUserOnCheckoutLogin(null, "", {
            order: response.data.order,
          })
        )
      } else {
        yield put(initCart())
      }
    } else {
      if (query.order_token && query.order_token !== "") {
        yield put(setUserOnCheckoutLogin(null, "", {}))
      }
    }

    if (typeof URLSearchParams !== "undefined") {
      const params = new URLSearchParams(state.router.location.search)
      params.delete("user_token")
      yield put(replace({ search: params.toString() }))
    }

    yield put(initCart())
    yield put(deleteSavedCart())

    if (state.router.location.pathname.includes("/account")) {
      yield put(replace("/"))
    }
  } catch (e) {
    console.error("LOG OUT ERROR", e)
  }

  action.callback && action.callback()
}
