import { all, takeLatest } from "redux-saga/effects"

import {
  LOAD_USER_PREFERENCES,
  UPDATE_USER_PREFERENCES,
  UPDATE_PREFERENCE_FREQUENCY,
  UNSUBSCRIBE_USER,
  RESUBSCRIBE_USER,
} from "./actions"
import loadUserPreferences from "./sagas/load-user-preferences"
import resubscribeUser from "./sagas/resubscribe-user"
import unsubscribeUser from "./sagas/unsubscribe-user"
import updatePreferenceFrequency from "./sagas/update-preference-frequency"
import updateUserPreferences from "./sagas/update-user-preferences"

function* accountSagas() {
  yield all([
    takeLatest(LOAD_USER_PREFERENCES, loadUserPreferences),
    takeLatest(UPDATE_USER_PREFERENCES, updateUserPreferences),
    takeLatest(UPDATE_PREFERENCE_FREQUENCY, updatePreferenceFrequency),
    takeLatest(UNSUBSCRIBE_USER, unsubscribeUser),
    takeLatest(RESUBSCRIBE_USER, resubscribeUser),
  ])
}

export default accountSagas
