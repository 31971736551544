import { replace } from "connected-react-router"
import loadjs from "loadjs"
import { call, put, select } from "redux-saga/effects"

import { history } from "app-store-v2/index"
import {
  getImgPixels,
  copyImg,
  updateImgThreshold,
  thresholdSettings,
  downScaleImage,
} from "helpers/image"
import StorageHelper from "helpers/local-storage-cache"

import { updateChoice } from "../actions"

function storeBase64Image(base64, pref, ratioWidth, ratioHeight, retry = 3) {
  try {
    StorageHelper.store(`image-${pref}-${ratioWidth}-${ratioHeight}`, base64)
    var cacheKey = `cropper-${pref}-${ratioWidth}-${ratioHeight}`
    StorageHelper.remove(cacheKey)
  } catch (error) {
    if (retry < 0) {
      clearBase64CachedImage(pref, ratioWidth, ratioHeight)
      throw new Error("unable to cache the upload image")
    }
    // size sorted image list
    const list = StorageHelper.list("-img")
    if (retry > 0 && list.length) {
      // remove the largest item and retry
      StorageHelper.remove(list[0])
      storeBase64Image(base64, pref, ratioWidth, ratioHeight, --retry)
    } else {
      // remove the whole image list and retry
      list.forEach((item) => StorageHelper.remove(item))
      storeBase64Image(base64, pref, ratioWidth, ratioHeight, --retry)
    }
  }
}

function clearBase64CachedImage(pref, ratioWidth, ratioHeight) {
  var cacheKey = `image-${pref}-${ratioWidth}-${ratioHeight}`
  StorageHelper.remove(cacheKey)
  var cropperCacheKey = `cropper-${pref}-${ratioWidth}-${ratioHeight}`
  StorageHelper.remove(cropperCacheKey)
}

export default function* uploadUserImage(action) {
  const state = yield select()
  if (!state.wizardUI.prefStore) {
    return
  }

  let file_input = action.fileSource
  let pref = action.pref
  let isHandwriting = action.isHandwriting

  var file = null
  if (
    !file &&
    file_input.dataTransfer &&
    file_input.dataTransfer.files &&
    file_input.dataTransfer.files[0]
  ) {
    file = file_input.dataTransfer.files[0]
  }
  if (!file && file_input.files && file_input.files[0]) {
    file = file_input.files[0]
  }

  if (!file) {
    return false
  }

  if (!(file.type.indexOf("image") !== -1)) {
    alert("You can only upload an image. Please try again.")
    return false
  }
  if (!/(jpe?g|png|heif)$/i.test(file.type)) {
    alert("File format not supported. Please use JPEG or PNG or HEIC.")
    return false
  }
  // Convert heic file to png file
  if (file.type?.includes("heif")) {
    file = yield call(heicConvert, file)
  }
  if (file.size > 15000000) {
    alert(
      "The image you have uploaded is too large. Please upload an image smaller than 15 MB"
    )
    return false
  }

  let base64
  try {
    /**
     * 1. Base64 uses exactly 6 bits of data, so 8-bits bytes (3x8=24bits)
     *    will convert to (4x6=24bits), thus base64 is 133% of the size of source
     * 2. Local storage is 5MB per app per browser
     *
     * e.g., 5MB / 1.33 ~= 3.5MB
     *       10MB / 1.33 ~= 7.5MB
     *
     * case 1: downsize to half
     * case 2: downsize to 1/10
     */
    let scaleRatio, fileSize
    fileSize = file.size

    const { dataUrl, isModified } = yield call(
      readToDataUrl,
      file,
      isHandwriting
    )
    base64 = dataUrl
    if (isModified) fileSize = base64.length * (3 / 4) - 2

    if (fileSize > 3500000 && fileSize <= 7500000) scaleRatio = 0.2
    else if (fileSize > 7500000) scaleRatio = 0.1

    if (scaleRatio) {
      const tempImage = yield call(loadBase64ToImage, base64)
      const downSizedImage = downScaleImage(tempImage, scaleRatio)
      const downSIzedBase64 = downSizedImage.toDataURL()
      base64 = downSIzedBase64
    }

    storeBase64Image(
      base64,
      pref,
      state.wizard.data[pref].cropper.aspect_ratio_width,
      state.wizard.data[pref].cropper.aspect_ratio_height
    )
  } catch (error) {
    alert(error)
    return false
  }

  try {
    const updatedChoice = {}
    // Handwriting sku: we applied the grey scale on the original upload img
    if (isHandwriting) {
      updatedChoice.upload_image = base64
      updatedChoice.threshold_value = thresholdSettings.MEDIUM
      base64 = yield call(applyDefaultsToHandwritingImg, base64)
    }
    updatedChoice.cropper_image = base64
    yield put(updateChoice(pref, updatedChoice))

    if (!state.browser.is.mobile && (pref === "img1" || pref === "img2")) {
      yield put(
        replace(
          `${history.location.pathname}${history.location.search}${history.location.hash}`
        )
      )
    }

    if (action.uploadCallback) {
      action.uploadCallback()
    }
  } catch (e) {
    console.error(e)
    alert(
      "There was a problem uploading your image. Please try again or use a different image."
    )
  }
}

function loadBase64ToImage(base64) {
  return new Promise((resolve) => {
    let image = new Image()
    image.addEventListener("load", function () {
      resolve(image)
    })
    image.src = base64
  })
}

function readToDataUrl(file, isHandwriting) {
  return new Promise(function (resolve, reject) {
    var reader = new FileReader()
    reader.onload = function (e) {
      let dataUrl = e.target.result
      if (isHandwriting) {
        const img = new Image()
        img.src = dataUrl
        img.onload = () => {
          const canvasSize = Math.max(img.naturalWidth, img.naturalHeight)
          const scaleFactor = getScaleFactor(canvasSize)
          if (scaleFactor > 1) {
            const canvas = document.createElement("canvas")
            const ctx = canvas.getContext("2d")
            const canvasW = img.naturalWidth / scaleFactor
            const canvasH = img.naturalHeight / scaleFactor
            canvas.width = canvasW
            canvas.height = canvasH
            ctx.drawImage(img, 0, 0, canvasW, canvasH)
            dataUrl = canvas.toDataURL()
            resolve({ dataUrl, isModified: true })
          } else {
            resolve({ dataUrl })
          }
        }
        img.onerror = (err) => reject(err)
      } else {
        resolve({ dataUrl })
      }
    }
    reader.onerror = function () {
      return reject()
    }
    reader.readAsDataURL(file)
  })
}

function getScaleFactor(size) {
  if (!size || size < 0) return 0
  if (size < 2000) return 1
  return size / 2000
}

function applyDefaultsToHandwritingImg(data) {
  return new Promise(function (resolve, reject) {
    const img = new Image()
    img.src = data
    img.onload = () => {
      const pixels = getImgPixels(img)
      updateImgThreshold(pixels, thresholdSettings.MEDIUM)
      const thresholdImage = copyImg(pixels)
      resolve(thresholdImage.toDataURL())
    }
    img.onerror = (err) => reject(err)
  })
}

function heicConvert(file) {
  return new Promise((resolve, reject) => {
    if (!window.isLoadHeic2Any) {
      loadjs(
        [
          "https://cdnjs.cloudflare.com/ajax/libs/heic2any/0.0.3/heic2any.min.js",
        ],
        "heic2any"
      )
    }
    loadjs.ready("heic2any", function () {
      window.isLoadHeic2Any = true
      // eslint-disable-next-line no-undef
      heic2any({
        blob: file,
        quality: 1,
        toType: "image/png",
      })
        .then((result) => {
          return resolve(result)
        })
        .catch((e) => {
          console.error(e)
          return reject()
        })
    })
  })
}
