import { css } from "styled-components"

export const placeholder = (...args) => {
  return css`
    &::-webkit-input-placeholder {
      ${css(...args)};
    }
    &::-moz-placeholder {
      ${css(...args)};
    }
    &:-ms-input-placeholder {
      ${css(...args)};
    }
    &:-moz-placeholder {
      ${css(...args)};
    }
  `
}

export const hideScrollbar = `
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
  };
`
