import { SET_HEADER_DROPDOWN } from "./actions"

const INITIAL_STATE = {
  dropdown: "",
}

export default function header(state = INITIAL_STATE, action) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case SET_HEADER_DROPDOWN:
      newState.dropdown = action.dropdownName
      return newState
    default:
      return state
  }
}
