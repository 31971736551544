import { all, call, put, select } from "redux-saga/effects"
import api from "apis"
import {
  addWizardData,
  updateChoice,
  setExtraWizardPrefOrder,
} from "../actions"

export default function* setBuilderItems(action) {
  const state = yield select()
  let params = action.params
  let countTo = action.cc
  for (var i = 1; i <= countTo; i++) {
    const builderKey = "c" + i
    const styleCode = params[builderKey] ? params[builderKey].value : null
    let success = false
    if (styleCode && styleCode != "") {
      let url = `/products/${styleCode}/product_defaults?prefPrefix=${builderKey}`
      // Object.keys(qs).forEach(key => url.searchParams.append(key, qs[key]))
      let result = null
      try {
        result = yield call(api.get, url)
      } catch (e) {
        console.error("FAILED TO LOAD", e)
      }

      if (result && result.react) {
        yield put(updateChoice(builderKey, { title: result.product_seo_name }))
        for (let key in result.react.wizard) {
          const datas = result.react.wizard[key]
          for (var j = 0; j < datas.length; j++) {
            const data = datas[j]
            yield put(addWizardData(data))
            if (data.engravings) {
              for (var k = 0; k < data.engravings.length; k++) {
                const engraving = data.engravings[k]
                if (state.wizard.adminMode) {
                  engraving.max_chars = engraving.admin_max_chars
                }
                yield put(addWizardData(engraving))
              }
            }
            if (data.stones) {
              for (var k = 0; k < data.stones.length; k++) {
                const stone = data.stones[k]
                yield put(addWizardData(stone))
              }
            }
            if (data.default_sku) {
              yield put(addWizardData(data.default_sku))
            }

            //Disable stone inputs with only one option
            if (
              /s[0-9]+/.test(key) &&
              data.options.length <= 1 &&
              !data.override_text
            ) {
              yield put(
                setWizardData(Object.assign({}, data, { disabled: true }))
              )
            }
          }
        }

        if (result.react.wizard_pref_order) {
          yield put(
            setExtraWizardPrefOrder(builderKey, result.react.wizard_pref_order)
          )
        }
        success = true
      }
    }
    if (!success) {
      // Re-assign all values from next value to lesser one
      yield put(setExtraWizardPrefOrder(builderKey, false))
      for (var j = i; j <= countTo; j++) {
        for (var pkey in params) {
          if (
            pkey != "cc" &&
            pkey[0] == "c" &&
            parseInt(pkey.split("_")[0].replace("c", "")) == j + 1
          ) {
            const suffix = pkey.split("_")[1]
            const newKey =
              "c" + j + (suffix && suffix != "" ? "_" + suffix : "")
            params[newKey] = params[pkey]
            yield put(updateChoice(newKey, params[pkey]))
            params[pkey] = null
          }
        }
      }
      countTo--
      i--
    }
  }
  yield put(updateChoice("cc", { value: countTo }))
}
