export const UPDATE_SEARCH_VALUE = "UPDATE_SEARCH_VALUE"
export const MAY_UPDATE_SEARCH_SUGGESTIONS = "MAY_UPDATE_SEARCH_SUGGESTIONS"
export const CLEAR_SEARCH_SUGGESTIONS = "CLEAR_SEARCH_SUGGESTIONS"
export const CLEAR_SEARCH_USER_INPUT = "CLEAR_SEARCH_USER_INPUT"
export const FETCH_SEARCH_SUGGESTIONS = "FETCH_SEARCH_SUGGESTIONS"
export const TOGGLE_SEARCHING = "TOGGLE_SEARCHING"

export function updateSearchValue(value) {
  return { type: UPDATE_SEARCH_VALUE, value }
}
export function fetchSearchSuggestions(query) {
  return { query, type: FETCH_SEARCH_SUGGESTIONS }
}
export function mayUpdateSearchSuggestions(suggestions, value) {
  return { suggestions, type: MAY_UPDATE_SEARCH_SUGGESTIONS, value }
}
export function clearSearchSuggestions() {
  return { type: CLEAR_SEARCH_SUGGESTIONS }
}
export function clearSearchUserInput() {
  return { type: CLEAR_SEARCH_USER_INPUT }
}
export function toggleSearching(searching) {
  return { searching, type: TOGGLE_SEARCHING }
}
