import { routerMiddleware } from "connected-react-router"
import { createBrowserHistory, createMemoryHistory } from "history"
import { createStore, applyMiddleware, compose } from "redux"
import { responsiveStoreEnhancer } from "redux-responsive"
import "regenerator-runtime/runtime"
import createSagaMiddleware from "redux-saga"

import app from "./reducers"

// import rootSaga from "./sagas"

// since the server has no HTML5 push states,
// history must be temporarily created in memory
// on the client, we can go ahead and make a standard
// `history` state
export const history =
  typeof window !== "undefined"
    ? createBrowserHistory({ basename: sl.basename })
    : createMemoryHistory()

// redux logger
// if (sl.config.env === "development" && !sl.server) {
//   const { logger } = require("redux-logger")
//   middlewares.push(logger)
// }

let storeOut = null

export const configureStore = (preloadData) => {
  const sagaMiddleware = createSagaMiddleware({
    onError: (e) => {
      console.error(e.stack)
    },
  })

  const middlewares = [routerMiddleware(history), sagaMiddleware]
  const newStore = createStore(
    app(history),
    preloadData,
    compose(responsiveStoreEnhancer, applyMiddleware(...middlewares))
  )
  storeOut = newStore

  if (typeof window !== "undefined") {
    const rootSaga = require("./sagas")
    sagaMiddleware.run(rootSaga.default)
  }
  return newStore
}

export const store = storeOut
