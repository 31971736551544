import { call, put, select } from "redux-saga/effects"

import api from "apis"
import { submitCheckoutAppliedPointsEvent } from "helpers/cart"

import {
  setLoadingOrderSummary,
  setPaymentMethod,
  updateOrderData,
} from "../actions"

export default function* redeemPoints({
  actions: { onError, onSuccess },
  redeemAmount,
}) {
  const state = yield select()
  const orderToken = state.checkout.order.order.token

  const url = "/secure/api/v10/checkout/reward_points"
  const payload = {
    order_token: orderToken,
    reward_amount: redeemAmount,
  }

  yield put(setLoadingOrderSummary({ redemptions: true }))

  try {
    const response = yield call(api.put, url, payload)

    if (response?.data) {
      yield put(updateOrderData({ react: response.data.order }))

      if (response.data.order.reward_adjustment) {
        submitCheckoutAppliedPointsEvent()
      }

      const order_balance = Number(
        response.data.order_balance || response.data.order.balance
      )
      if (order_balance == 0) {
        yield put(setPaymentMethod("jewlr"))
      } else if (state.checkout.order.selectedPaymentMethod === "jewlr") {
        yield put(setPaymentMethod(null))
      }

      const redeem_value =
        response.data.order.points.redeem_value &&
        Math.round(response.data.order.points.redeem_value) > 0
          ? Math.round(response.data.order.points.redeem_value)
          : ""
      onSuccess(redeem_value)
    }
  } catch (err) {
    console.log("Error redeeming points", err)
    onError(err?.errors?.[0] || err?.message || err)
  }
}
