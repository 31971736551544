import { clearEngravingPos, updateChoice } from "areas/product/store/actions"

const limitChars = (
  engraving,
  event,
  dispatch,
  setFastEngravingValue = null,
  updateEngravingCount = false
) => {
  let selectedFont, styleCode
  try {
    if (event) {
      event.persist()
    }

    let state = null

    if (!styleCode) {
      state = appStoreV2.getState()
      if (state.choices.style_code) {
        styleCode = state.choices.style_code?.value
      }
    }

    let limit = engraving.max_chars
    let caps = engraving.caps
    let text = event ? event.target.value : engraving.value
    let textlength = [...text].length

    //regex = (regex + regexend);

    let regex = engraving.pattern
    if (engraving.allow_emojis) {
      if (engraving.allow_emoji_values) {
        regex =
          regex.substr(0, regex.length - 1) + engraving.allow_emoji_values + "]"
      } else {
        regex = regex.substr(0, regex.length - 1) + sl.emoji_regex + "]"
      }
    }

    // unescape the regex in ruby format
    regex = regex.replace(/\\#/g, "#")
    let re = new RegExp(regex, "uig")

    // Adjust case
    let newText = text.replace(re, "")
    if (!engraving.any_case) {
      if (caps == "All Caps") newText = newText.toUpperCase()
      else if (caps == "Title Caps") newText = newText.titleCase()
      else if (caps == "No Caps") newText = newText.toLowerCase()
      // Apply Title Case for Snell font
      // TODO: Don't use window.appStoreV2
    }
    try {
      if (!selectedFont) {
        if (!state) {
          state = appStoreV2.getState()
        }
        selectedFont = state.choices[engraving.engraving_font || "ef"]
          ? state.choices[engraving.engraving_font || "ef"].value
          : ""
      }
    } catch (e) {
      // Silently proceed
    }
    if (selectedFont != "") {
      if (
        selectedFont != undefined &&
        selectedFont.indexOf("Cursive") != -1 &&
        caps != "All Caps" &&
        caps != "No Caps" &&
        !engraving.any_case
      ) {
        // Set to lowercase or titlecase
        newText = newText
          .split(" ")
          .map((l) => l.charAt(0) + l.slice(1).toLowerCase())
          .join(" ")
      }

      // Arabic/Farsi hack, increase the textLength if the value doesn't match
      // these particular pairs
      if (
        selectedFont == "Arabic" &&
        !["أو", "او", "اُ", "اَ", "اِ"].includes(newText)
      ) {
        textlength++
        limit--
      }
    }

    // Remove Emojis
    // newText = newText.replace(/[\uD800-\uDBFF\uDC00-\uDFFF]/g, "")

    if (textlength > limit) {
      newText = [...newText].slice(0, limit).join("")
      if (event) {
        event.target.classList.add("js-shake")
        event.target.addEventListener("animationend", function () {
          event.target.classList.remove("js-shake")
        })
      }
    }

    dispatch(clearEngravingPos(engraving.pref))
    if (setFastEngravingValue) {
      setFastEngravingValue(newText)
    } else if (newText.length == 0) {
      dispatch(
        updateChoice(engraving.pref, {
          disabled: engraving.disabled || false,
          is_default: engraving.is_default || false,
          price: 0,
          value: newText,
        })
      )
    } else {
      let prefData = {
        value: newText,
      }
      if (updateEngravingCount) {
        prefData.updateCount = (engraving.updateCount || 0) + 1
      }
      dispatch(
        updateChoice(engraving.pref, Object.assign({}, engraving, prefData))
      )
    }
    // engravingInputElement.val(newText);
    // if (typeof event !== 'undefined') restoreCursorPos(event, pos);

    // Update characters remaining
    // let charsLeft = limit - newText.length;
    // $(event.target).parents('.engraving-entry').find(".engraving-chars-text").text(charsLeft + " left");
  } catch (e) {
    console.log("error with limitChars", e)
  }
} // end limitChars

export default limitChars
