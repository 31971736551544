export const firstName = fullName => {
  if (!fullName) {
    return ""
  }
  fullName = fullName.replace(/\s{2,}/, " ")
  if (fullName.trim().split(" ").length > 1) {
    return fullName
      .trim()
      .split(" ")
      .slice(0, -1)
      .join(" ")
  } else {
    return fullName
  }
}

export const joinNames = (firstName, lastName) =>
  `${firstName || ""}${lastName ? ` ${lastName}` : ""}`

export const lastName = fullName => {
  if (!fullName) {
    return ""
  }
  fullName = fullName.replace(/\s{2,}/, " ")
  if (fullName.trim().split(" ").length > 1) {
    return fullName
      .trim()
      .split(" ")
      .slice(-1)
      .join(" ")
  } else {
    return ""
  }
}

export const splitName = fullName => {
  return {
    first: firstName(fullName),
    last: lastName(fullName)
  }
}
