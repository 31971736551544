export const DO_JAVASCRIPT_TRACKING = "DO_JAVASCRIPT_TRACKING"
export const ERROR_MODAL_OPEN = "ERROR_MODAL_OPEN"
export const ERROR_MODAL_CLOSE = "ERROR_MODAL_CLOSE"
export const LOAD_DELIVERY_METHODS = "LOAD_DELIVERY_METHODS"
export const LOAD_ORDER_DATA = "LOAD_ORDER_DATA"
export const ORDER_COMPLETED = "ORDER_COMPLETED"
export const PANEL_COMPLETE = "PANEL_COMPLETE"
export const PANEL_OPEN = "PANEL_OPEN"
export const REDEEM_REWARDS = "REDEEM_REWARDS"
export const SAVE_KLARNA_CLIENT_TOKEN = "SAVE_KLARNA_CLIENT_TOKEN"
export const SET_DELIVERY_METHODS = "SET_DELIVERY_METHODS"
export const SET_LOADING_ORDER_SUMMARY = "SET_LOADING_ORDER_SUMMARY"
export const SET_LOADING_SHIPPING_METHODS = "SET_LOADING_SHIPPING_METHODS"
export const SET_LOGIN_CHECKOUT = "SET_LOGIN_CHECKOUT"
export const SET_ORDER_SUBMIT_RESULTS = "SET_ORDER_SUBMIT_RESULTS"
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD"
export const SET_USER_ON_CHECKOUT_LOGIN = "SET_USER_ON_CHECKOUT_LOGIN"
export const SUBMIT_ORDER = "SUBMIT_ORDER"
export const TOGGLE_PROCESSING_MODAL = "TOGGLE_PROCESSING_MODAL"
export const UPDATE_ORDER_DATA = "UPDATE_ORDER_DATA"
export const UPDATE_USER_DATA = "UPDATE_USER_DATA"

/**
 * Simply sends the Client Token from the generated Klarna Session
 * to the Checkout state, where it will be used to authorize the payment
 * when submitting the order
 */
export function saveKlarnaWidgetInfo(klarnaWidgetInfo) {
  return {
    klarnaWidgetInfo,
    type: SAVE_KLARNA_CLIENT_TOKEN,
  }
}

export function setLoginFromCheckout() {
  return { type: SET_LOGIN_CHECKOUT }
}

export function setUserOnCheckoutLogin(user, email, data) {
  return {
    data,
    email,
    type: SET_USER_ON_CHECKOUT_LOGIN,
    user,
  }
}

export function doJavascriptTracking(order) {
  return { order, type: DO_JAVASCRIPT_TRACKING }
}

/**
 * Sets `state.order.error_modal` to false
 */
export function errorModalClose() {
  return { type: ERROR_MODAL_CLOSE }
}
/**
 * Sets `state.order.error_modal` to true
 */
export function errorModalOpen(error_msg) {
  return { error_msg, type: ERROR_MODAL_OPEN }
}

/**
 * Loads order values into redux
 *
 * @param {Object} data Order data
 * @param {String} selectedPaymentMethod selected payment method
 */
export function loadOrderData(data, selectedPaymentMethod) {
  return { data, selectedPaymentMethod, type: LOAD_ORDER_DATA }
}

export function orderCompleted() {
  return { type: ORDER_COMPLETED }
}

/**
 * Marks panel as complete
 * @param  {Int} step  The panel step that's completed
 * @param  {String} actionType - determines whether to close/open pane.
 *
 * sets the following state:
 * state.panels[step].complete = true
 * state.panels[step].opened = determined by actionType, see below
 *
 * actionType options:
 * "open": state.panel[step].opened = true
 * "close": state.panel[step].opened = false
 * "toggle": state.panel[step].opened = < toggles >
 */
export function panelComplete(step, forceOpenStep) {
  return { forceOpenStep, step, type: PANEL_COMPLETE }
}

/**
 * Opens panel at step, `state.panels[step].opened = true`
 * @param  {Int} step  The panel step that's completed
 */
export function panelOpen(step) {
  return { step, type: PANEL_OPEN }
}

export function redeemRewards(redeemAmount, actions) {
  return { actions, redeemAmount, type: REDEEM_REWARDS }
}

/**
 * This sets the delivery methods in state.order.delivery from the fetch_delivery_methods
 * AJAX request
 *
 * @param {Object} data Data to populate
 */
export function setDeliveryMethods(data) {
  return { data, type: SET_DELIVERY_METHODS }
}

/**
 * This sets the errors/messages returned by the complete order AJAX call
 *
 * @param {Object} results Resulting messages (usually cc_error or error in the object)
 */
export function setOrderSubmitResults(results) {
  return { results, type: SET_ORDER_SUBMIT_RESULTS }
}

/**
 * This sets the payment method to a different parameter, useful for escaping transactions like Affirm
 * NOTE: You should ALSO setFieldValue('payment_method', paymentMethod) so Yup validates correctly
 *
 * @param {String} paymentMethod The payment method to switch to (normally "creditcard")
 */
export function setPaymentMethod(paymentMethod) {
  return { paymentMethod, type: SET_PAYMENT_METHOD }
}

/**
 * This submits the order to /checkout using PATCH method
 *
 * @param {Object} values Order values
 * @param {Object} actions Object of callback functions
 */
export function submitOrder(values, actions) {
  return { actions, type: SUBMIT_ORDER, values }
}

/**
 * Updates the order data returned from AJAX calls and initial page load
 * @param  {Object} data
 */
export function updateOrderData(data) {
  return { data, type: UPDATE_ORDER_DATA }
}

export function updateUserData() {
  return { type: UPDATE_USER_DATA }
}

export function setLoadingShippingMethods(loading) {
  return { loading, type: SET_LOADING_SHIPPING_METHODS }
}

export function loadDeliveryMethods(values, setValue) {
  return { setValue, type: LOAD_DELIVERY_METHODS, values }
}

export function toggleProcessingModal(show) {
  return { show, type: TOGGLE_PROCESSING_MODAL }
}

export function setLoadingOrderSummary(data) {
  return { data, type: SET_LOADING_ORDER_SUMMARY }
}
