import Cookies from "js-cookie"

import api from "apis"
import { isTestMode } from "helpers/application"

const logExperimentImpression = async (experiment_id) => {
  try {
    const cartToken = Cookies.get(sl.config.cookies_config.cart_token)
    await api.post(`/api/v10/cart/${cartToken}/experiment_impression`, {
      experiment_id,
    })
  } catch (error) {
    console.error(error)
  }
}

export default function setExperiment(action) {
  const experimentData = action.experiment
  if (experimentData && !isTestMode) {
    Object.keys(experimentData).forEach((key) => {
      try {
        if (
          experimentData[key].experiment_id &&
          experimentData[key].convert_variant_id
        ) {
          dataLayer.push({
            convertExperimentId: experimentData[key].experiment_id,
            convertVariantId: experimentData[key].convert_variant_id,
            event: "event-impression-convert",
          })
          logExperimentImpression(experimentData[key].experiment_id)
        }
      } catch (e) {
        console.log("Event Impression dataLayer error", e)
      }
    })
  }
}
