import { useFocusManager } from "@react-aria/focus"
import { useKeyboard } from "@react-aria/interactions"

export const useNavigation = (props) => {
  const { direction, onEscape, onTab } = props || { direction: "vertical" }
  const focusManager = useFocusManager()
  if (!focusManager) return null

  const { keyboardProps } = useKeyboard({
    onKeyDown: (e) => {
      if (
        (direction === "both" && ["ArrowLeft", "ArrowUp"].includes(e.key)) ||
        e.key === (direction === "horizontal" ? "ArrowLeft" : "ArrowUp")
      ) {
        focusManager.focusPrevious({ wrap: true })
      } else if (
        (direction === "both" && ["ArrowRight", "ArrowDown"].includes(e.key)) ||
        e.key === (direction === "horizontal" ? "ArrowRight" : "ArrowDown")
      ) {
        focusManager.focusNext({ wrap: true })
      } else if (e.key === "Home") {
        focusManager.focusFirst()
      } else if (e.key === "End") {
        focusManager.focusLast()
      } else if (e.key === "Escape" && typeof onEscape === "function") {
        if (e.defaultPrevented) return

        onEscape()
      } else if (e.key === "Tab" && onTab) {
        onTab(e, focusManager)
        return
      } else {
        return
      }

      e.preventDefault()
    },
  })

  return keyboardProps
}

export default useNavigation
