// In future, useStripeCheckout will also be conditional on other variables,

// e.g. modulus of cart token

import Cookies from "js-cookie"

export const useNewPayPalCheckout = () =>
  sl.config.enable_paypal &&
  Cookies.get("enable_new_paypal") != "false" &&
  (sl.config.new_paypal || Cookies.get("enable_new_paypal") == "true")

export const creditCardCheckoutMethod = () => {
  let creditCardMethod = "stripeCheckout"

  let checkNumber = null

  if (typeof Cookies.get(sl.config.cookies_config.cart_token) !== "undefined") {
    const cartCharacter = Cookies.get(
      sl.config.cookies_config.cart_token
    ).slice(-1)
    // Randomized hex number to determine which credit card checkout method to use
    checkNumber = Number("0x" + cartCharacter)
  } else {
    console.error("Cart token missing")
  }

  let braintreeCheckoutAvailable =
    (Cookies.get("enable_braintree_checkout") == "true" ||
      sl.config.enable_braintree_checkout) &&
    Cookies.get("enable_braintree_checkout") != "false"
  let stripeCheckoutAvailable =
    (Cookies.get("enable_stripe_checkout") == "true" ||
      sl.config.enable_stripe_checkout) &&
    Cookies.get("enable_stripe_checkout") != "false"

  if (!braintreeCheckoutAvailable && !stripeCheckoutAvailable) {
    creditCardMethod = "default"
  } else if (stripeCheckoutAvailable && !braintreeCheckoutAvailable) {
    creditCardMethod = "stripeCheckout"
  } else if (braintreeCheckoutAvailable && !stripeCheckoutAvailable) {
    creditCardMethod = "braintreeCheckout"
  } else {
    // Jewlr (
    //   US: 0 % Stripe, 100 % Braintree (10% will still go to Apple Pay)
    //   CA: 10 % Stripe, 90 % Braintree (5% will still go to Apple Pay)
    //   UK: 10 % Stripe, 90 % Braintree
    //   AU: 10 % Stripe, 90 % Braintree
    // )
    if (sl.config.site_country == "us") {
      if (checkNumber && checkNumber % 10 < 0) {
        creditCardMethod = "stripeCheckout"
      } else {
        creditCardMethod = "braintreeCheckout"
      }
    } else if (sl.config.site_country == "uk") {
      // TEMP - Barclay's issue
      creditCardMethod = "stripeCheckout"
    } else {
      if (checkNumber && checkNumber % 10 < 1) {
        creditCardMethod = "stripeCheckout"
      } else {
        creditCardMethod = "braintreeCheckout"
      }
    }
  }
  return creditCardMethod
}

export const enableKlarnaCheckout = () => {
  return (
    (sl.config.enable_klarna && Cookies.get("enable_klarna") != "false") ||
    Cookies.get("enable_klarna") == "true"
  )
}

/**
 * This function will return the options to ask user's information
 * Variant 0: Always asking all
 * Variant 1 (B):
 * requestPayerEmail: Don't ask
 * requestPayerName: Ask if user not logged in
 * requestPayerPhone: Ask if user not logged in
 * requestShipping: Ask
 * Variant 2 (C):
 * requestPayerEmail: Don't ask
 * requestPayerName: Ask if user not logged in
 * requestPayerPhone: Ask if user not logged in
 * requestShipping: Don't ask
 */
export const getExpressOptions = () => {
  // Always ask Email Address
  // It help us to always have customer's email in Stripe portal
  let requestPayerEmail = true
  let requestPayerName = true
  let requestPayerPhone = true
  let requestShipping = false

  return {
    requestPayerEmail,
    requestPayerName,
    requestPayerPhone,
    requestShipping,
  }
}
