import { call, put } from "redux-saga/effects"

import api from "apis"
import { setLocalStorage } from "helpers/application"

import { setMessage, setUserData } from "../actions"

export default function* sendUserInfo({
  actions: { onError, onSuccess },
  values,
}) {
  try {
    yield put(setMessage("provideUserInfo", "", ""))
    let url = "/api/v10/user/update_info"
    const result = yield call(api.authPost, url, values)

    if (result?.error) {
      throw new Error(result.error)
    }

    if (result?.data) {
      yield put(setUserData(result.data.jewlrorders.user))

      setLocalStorage(result.data.jewlrorders.user, "user_obj")

      yield put(
        setMessage(
          "provideUserInfo",
          "success",
          "Your changes have been saved!"
        )
      )
    }
    onSuccess()
  } catch (err) {
    console.log(err)
    onError(err)
  }
}
