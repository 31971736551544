import Cookies from "js-cookie"
import { put } from "redux-saga/effects"

import { showShareEmailModal } from "../actions"

export default function* fetchCartShare({ cart_type = "cart" }) {
  const data = {
    email_a_friend_products_path: `/api/v10/cart/${Cookies.get(
      sl.config.cookies_config.cart_token
    )}/share_cart_email`,
    share_type: cart_type,
    share_type_titleized:
      cart_type === "wishlist"
        ? "Wish List"
        : `${cart_type[0].toLocaleUpperCase()}${cart_type.substring(1)}`,
    timestamp: Date.now() / 1000,
  }
  yield put(showShareEmailModal(data))
}
