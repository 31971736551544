import { put, select } from "redux-saga/effects"

import { isTestMode } from "helpers/application"

import { setExtraWizardParams } from "../actions"

export default function* analyticsEvents(action) {
  const state = yield select()
  if (action.type == "TOGGLE_PANEL_INDEX") {
    if (state.wizardUI.prefStore && !state.wizardUI.startedPersonalizing) {
      yield put(setExtraWizardParams({ startedPersonalizing: true }))
      // $("#styleCode").data("started-personalizing", true)

      if (!isTestMode) {
        dataLayer.push({
          event: "start-personalization",
          productTitle: state.wizard.title,
          styleCode: state.wizard.description.style_code,
        })

        if (action.prefOf == "diamond") {
          dataLayer.push({
            event: "used-diamond-selector",
            productTitle: state.wizard.title,
            styleCode: state.wizard.description.style_code,
          })
        }
      }

      // sendUserData({ started_personalizing: $("#styleCode").val() })
    }
  } else if (action.type == "TRACK_SLIDE") {
    try {
      // Check if the slide is the hand view
      const allViews = state.previewImage.data.all_views || []
      const flipped = state.previewImageUI.flipped
      const images = state.previewImage.data.images || {}
      const actualViews = allViews.filter((view) => {
        if (
          flipped &&
          typeof images["face2_" + view + "_view"] !== "undefined"
        ) {
          return true
        } else if (typeof images[view + "_view"] !== "undefined") {
          return true
        } else if (typeof images[view] !== "undefined") {
          return true
        } else {
          return false
        }
      })
      const current_view = actualViews[action.index]
      if (current_view == "static_1") {
        const style_code = state.choices.style_code
          ? state.choices.style_code.value
          : null
        if (style_code) {
          submit_ga_event_click("Product", "Hand Image Viewed", style_code)
        }
      }
    } catch (err) {
      console.error("SHOW SLIDE ERROR ANALYTICS", err)
    }
  }
}
