import { mediaStyle } from "@jewlr/storybook/core"
import { createGlobalStyle } from "styled-components"

import normalize from "styles/normalize"

const fontFace = (fontFamily, src, fontWeight, fontStyle) => {
  return `
    @font-face {
      font-family: '${fontFamily}';
      src: url('https://assets.jewlr.com/fonts/${src}.woff2?v=2') format('woff2'),
           url('https://assets.jewlr.com/fonts/${src}.woff?v=2') format('woff'),
           url('https://assets.jewlr.com/fonts/${src}.ttf?v=2') format('truetype'),
           local("Helvetica"), local("Arial");
      font-weight: ${fontWeight};
      font-style: ${fontStyle};
      font-display: swap;
    }
  `
}

export const GlobalStyle = createGlobalStyle`
  ${fontFace("Mier B", "mierb/mierb-regular", "normal", "normal")};
  ${fontFace("Mier B", "mierb/mierb-book", "500", "normal")};
  ${fontFace("Mier B", "mierb/mierb-bookitalic", "500", "italic")};
  ${fontFace("Mier B", "mierb/mierb-demibold", "600", "normal")};
  ${fontFace("Mier B", "mierb/mierb-bold", "bold", "normal")};
  ${fontFace("Mier B", "mierb/mierb-demibolditalic", "600", "italic")};
  ${fontFace("Mier B", "mierb/mierb-italic", "normal", "italic")};
  ${fontFace("Mier B", "mierb/mierb-bolditalic", "bold", "italic")};
  @font-face {
    font-family: 'Mier B Fallback';
    src: local("Arial");
    ascent-override: 77.00%;
    descent-override: 25.67%;
    line-gap-override: 34.79%;
    size-adjust: 97.40%;
  }
  ${(props) => normalize(props)};
  ${mediaStyle}
`
