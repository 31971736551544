import { replace } from "connected-react-router"
import { all, put, select } from "redux-saga/effects"
import URI from "urijs"

import { updateDisplayState, setExtraWizardParams } from "../actions"

export default function* updateImages(action) {
  const state = yield select()
  const product_image = action?.data?.data
  if (product_image.all_views?.length > 0) {
    let updates = []
    const views = product_image.images

    Object.keys(views)
      .filter((k) => k.indexOf("img") > -1)
      .forEach((k) => {
        updates.push(
          put(
            updateDisplayState(k, {
              image: views[k].src,
            })
          )
        )
      })

    const { pathname, search, hash } = state.router.location
    let uri = URI(`${pathname}${search}${hash}`)

    if (uri.pathname().includes("products")) {
      // Update the SEO path, query string, and SEO product title
      if (uri.segment(-2) == "products") {
        // Fix the old URLs to use/show the new format
        uri.segment(-1, state.wizard.description.style_code)
        uri.segment(product_image.seo_permalink)
      } else {
        uri.segment(-1, product_image.seo_permalink)
        uri.segment(-2, state.wizard.description.style_code)
      }
      document.title = `${product_image.seo_title} | ${sl.config.site_title}`

      updates.push(
        put(
          setExtraWizardParams({
            seo_title: product_image.seo_title,
            title: product_image.title,
          })
        )
      )
      updates.push(put(replace(uri.toString())))
    }

    // ADD THUMBNAIL SRC
    Object.keys(views).forEach((view_key) => {
      const image_urls = views[view_key]

      if (view_key == "design_box" || view_key.indexOf("photo_box") !== -1) {
        let selected = view_key == "design_box" ? "design" : "photo"

        //Only load the image if we don't already have one loaded
        if (!state.choices.box[`${selected}_image`]) {
          var update = {}
          update[`${selected}_image`] = image_urls.src
          updates.push(put(updateDisplayState("box", update)))
        }

        // Show preview spinner when loading image
        return true // skip over box images since they're not part of the product image index
      }

      if (view_key == "box") {
        let selected = state.choices.box.selected_type
        if (selected == "design") {
          updates.push(
            put(
              updateDisplayState("box", {
                design_image: image_urls.src,
                image: image_urls.src,
              })
            )
          )
        }
        if (selected == "photo") {
          updates.push(
            put(
              updateDisplayState("box", {
                image: image_urls.src,
                photo_image: image_urls.src,
              })
            )
          )
        }

        return true // skip over box images since they're not part of the product image index
      }
    })

    yield all(updates)
  }
}
