import { call, put } from "redux-saga/effects"

import api from "apis"

import { setCart } from "../actions"

export default function* applyCartChanges(action) {
  const { api_url, method, payload = {} } = action

  try {
    if (action.toggleDisableActions) {
      action.toggleDisableActions(action.variable)
    }

    const result = yield call(api[method], api_url, {
      ...payload,
    })
    if (result) {
      // begin this is for investigate random cart empty issue after add free gift
      // console.log(
      //   `cart_token: ${Cookies.get(
      //     sl.config.cookies_config.cart_token
      //   )} ${method.toUpperCase()}: ${api_url} result`,
      //   result
      // )
      // end this is for investigate random cart empty issue after add free gift

      yield put(setCart(result.data))

      if (action.toggleDisableActions) {
        action.toggleDisableActions({ action: "", id: "" })
      }

      if (action.callbacks?.onSuccess) {
        action.callbacks.onSuccess()
      }
    }
  } catch (error) {
    // noop
    console.log(error)
    if (action.callbacks?.onError) {
      action.callbacks?.onError(error)
    }
  }
}
