import { call, put } from "redux-saga/effects"

import api from "apis"

import { setUserPreferences } from "../actions"

function* updatePreferenceFrequency({
  actions: { onError, onSuccess },
  frequency,
  guidToken,
}) {
  try {
    const response = yield call(
      api.put,
      `/secure/api/v10/preferences/${guidToken}/frequency`,
      frequency
    )

    if (response.data) {
      yield put(setUserPreferences(response.data))
    }
    onSuccess()
  } catch (err) {
    console.log("Error while updating preference frequency", err)
    onError(err)
  }
}
export default updatePreferenceFrequency
