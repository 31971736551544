import { Box, Flashbar } from "@jewlr/storybook/core"
import React, { useEffect, useState } from "react"

const JsCookiesWarning = () => {
  const [cookiesEnabled, toggleCookiesEnabled] = useState(true)

  useEffect(() => {
    if (!navigator.cookieEnabled) {
      toggleCookiesEnabled(false)
    }
  }, [])

  useEffect(() => {
    if (sl.config.enable_klarna_express_button && sl.api.klarna?.merchant_id) {
      const script = document.createElement("script")
      script.src = "https://x.klarnacdn.net/express-button/v1/lib.js"
      script.async = true
      script.setAttribute("data-id", sl.api.klarna.merchant_id)
      script.setAttribute("data-environment", "playground")
      document.getElementsByTagName("head")[0].appendChild(script)
      window.klarnaExpressButtonAsyncCallback = () => {
        window.Klarna.ExpressButton.on("user-authenticated", (callbackData) => {
          window.handleKlarnaExpressButtonCallback(callbackData)
        })
      }
    }
  }, [])

  return (
    <>
      <noscript>
        <Box contain>
          <Flashbar my={3} type="error">
            To ensure our customers have the best virtual shopping experience
            possible, Jewlr.com uses Javascript. Please enable Javascript on
            your browser to continue shopping. For further information or
            assistance, please call {sl.config.customer_service_toll_free} or
            email {sl.config.customer_service_email}
          </Flashbar>
        </Box>
      </noscript>
      {!cookiesEnabled && (
        <Box contain>
          <Flashbar my={3} type="error">
            To ensure our customers have the best virtual shopping experience
            possible, Jewlr.com uses cookies. Please enable cookies on your
            browser to continue shopping. For further information or assistance,
            please call {sl.config.customer_service_toll_free} or email{" "}
            {sl.config.customer_service_email}
          </Flashbar>
        </Box>
      )}
    </>
  )
}

export default JsCookiesWarning
