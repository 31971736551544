import {
  UPDATE_SEARCH_VALUE,
  MAY_UPDATE_SEARCH_SUGGESTIONS,
  CLEAR_SEARCH_SUGGESTIONS,
  CLEAR_SEARCH_USER_INPUT,
  TOGGLE_SEARCHING,
} from "./actions"

const SAVED_SEARCH = "savedSearch"

const INITIAL_STATE = {
  currentQuery: "",
  searching: false,
  suggestions: [],
  value: "",
}

export default function header(state = INITIAL_STATE, action) {
  let newState = Object.assign({}, state)

  switch (action.type) {
    case UPDATE_SEARCH_VALUE:
      newState.value = action.value
      return newState
    case MAY_UPDATE_SEARCH_SUGGESTIONS:
      newState.suggestions = action.suggestions
      newState.searching = false
      if (action.value !== SAVED_SEARCH) {
        newState.currentQuery = action.value
      }
      return newState
    case CLEAR_SEARCH_SUGGESTIONS:
      newState.currentQuery = ""
      newState.suggestions = []
      return newState
    case CLEAR_SEARCH_USER_INPUT:
      newState.currentQuery = ""
      newState.value = ""
      return newState
    case TOGGLE_SEARCHING:
      newState.searching = action.searching
      return newState
    default:
      return state
  }
}
