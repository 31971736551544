import { Box } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

const Sticky = styled(Box).attrs({
  as: "header",
})`
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${(props) => props.theme.zIndices.header};
  ${(props) =>
    props.inCart &&
    props.theme.mediaQueries.tablet`
      position: static;
    `}
`
/**
 * !!!!! Only mount this component ONCE in application!!!
 * Sticky header
 */
export default function stickyHeader(WrappedComponent) {
  const InnerStickyHeader = ({ location, ...props }) => {
    const [sticky, setSticky] = useState(false)
    const headerRef = useRef()

    const checkScrollPosition = () => {
      const contentContainer = document.getElementById("content_container")
      const hasOpenedModal =
        contentContainer && contentContainer.style["position"] === "fixed"

      if (
        headerRef &&
        headerRef.current &&
        !location.pathname.includes("checkout") &&
        !hasOpenedModal
      ) {
        if (location.pathname.includes("cart")) {
          setSticky(false)
        } else {
          setSticky(
            window.pageYOffset >
              headerRef.current.getBoundingClientRect().height + 50
          )
        }
      }
    }

    const scrollTick = () => {
      window.requestAnimationFrame(checkScrollPosition)
    }

    useEffect(() => {
      if (typeof window !== "undefined") {
        window.addEventListener("scroll", scrollTick)
      }

      return () => {
        document.removeEventListener("scroll", scrollTick)
      }
    }, [])

    return (
      <Sticky inCart={location.pathname.includes("cart")} ref={headerRef}>
        <WrappedComponent {...props} location={location} sticky={sticky} />
      </Sticky>
    )
  }

  InnerStickyHeader.propTypes = {
    location: PropTypes.object,
  }

  return InnerStickyHeader
}
