import { call, put } from "redux-saga/effects"

import api from "apis"
import { history } from "app-store-v2/index"

import { setUserPreferences } from "../actions"

function* resubscribeUser({ actions: { onError, onSuccess }, guidToken }) {
  try {
    const response = yield call(
      api.post,
      `/secure/api/v10/preferences/${guidToken}`
    )

    if (response && response.data) {
      yield put(setUserPreferences(response.data))
    }
    onSuccess()
  } catch (err) {
    console.log("Error while fetching preferences", err)
    onError()
  }

  history.push("/email-preferences/subscribe-complete")
}
export default resubscribeUser
