import { call, cancelled, select } from "redux-saga/effects"

import api from "apis"
import { mapOrderDataToFormValues } from "helpers/checkout"

export default function* updateUserData() {
  const state = yield select()

  try {
    const orderData = state.checkout.order
    const formValues = mapOrderDataToFormValues(orderData)

    // Only update iterable contact  email is present
    if (!state.cart.summary?.email && formValues?.order?.email) {
      // This is called to ensure the iterable contact gets created
      yield call(
        api.get,
        `/api/v10/user_data?email=${encodeURIComponent(
          formValues.order.email
        )}&opt_in=${formValues.order.opt_in}`
      )
    }
  } catch (e) {
    console.error("Error Updating Checkout", e)
  } finally {
    if (yield cancelled()) {
      console.log("Update Checkout Cancelled")
    }
  }
}
