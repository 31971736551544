export default (filter, s) => {
  // console.log("CHECKING", s, "AGAINST", filter)
  if (filter.type == "regex") {
    let regexp = new RegExp(filter.value, "i")
    if (Array.isArray(filter.value)) {
      regexp = new RegExp(filter.value.join("|"), "i")
    }
    return regexp.test(s)
  } else if (filter.type == "exact") {
    // console.log(filter.value == s )
    return filter.value == s
  } else {
    return false
  }
}
