import Cookies from "js-cookie"
import { call, put, select } from "redux-saga/effects"

import api from "apis"
import { history } from "app-store-v2/index"
import { setCart, toggleCartPreview } from "areas/cart/store/actions"
import { closeModal, openModal } from "components/modal"
import { isTestMode } from "helpers/application"
import { setClickedOnHistoryCookie } from "helpers/collection"
import { removePref } from "helpers/user-prefs"

import {
  showError,
  fetchInventory,
  forceHideMiniview,
  forcePanelIndex,
  cancelAddToCart,
  scrollPanelIndex,
  setModalPanelIndex,
  updateChoiceDirectly,
} from "../actions"
import { getRequiredMessages, toFormObject } from "../helpers/choices"

export default function* addToCart() {
  let state = yield select()
  const cartToken = Cookies.get(sl.config.cookies_config.cart_token)

  // Check min_chars of all engravings
  let minError = false
  let minErrorEngravingFont = null
  for (var key in state.wizard.data) {
    if (key[0] == "e" && state.wizard.data[key].min_chars) {
      if (state.wizard.data[key].min_chars > 0) {
        // These are the ERROR conditions
        if (
          !state.choices[key] ||
          !state.choices[key].value ||
          state.choices[key].value.length < state.wizard.data[key].min_chars ||
          (state.choices[key].value.length >=
            state.wizard.data[key].min_chars &&
            state.choices[key].is_default)
        ) {
          if (state.choices[key] && state.choices[key].disabled) {
            continue
          }
          if (state.wizard.hasDiamondSelector) {
            if (!state.choices[key]) {
              continue
            }
            yield put(
              showError(
                "m2",
                "Please select your initials for the Signature Setting."
              )
            )
            if (!isTestMode) {
              dataLayer.push({
                event: "missed-product-option",
                optionName: "m2",
                styleCode: state.choices.style_code?.value,
              })
            }
          } else {
            const engravingFont = state.wizard.data[key].engraving_font || "ef"
            if (state.browser.is.mobile) {
              openModal(
                `#engravings${engravingFont !== "ef" ? engravingFont : ""}`
              )
            }
            yield put(
              showError(
                key,
                "Please enter " +
                  (state.wizard.data[key].min_chars <
                  state.wizard.data[key].max_chars
                    ? "at least "
                    : "") +
                  state.wizard.data[key].min_chars +
                  " characters!"
              )
            )
            if (!isTestMode) {
              dataLayer.push({
                event: "missed-product-option",
                optionName: key,
                styleCode: state.choices.style_code?.value,
              })
            }
            minErrorEngravingFont = engravingFont
          }
          minError = true
        }
      }
    }
  }

  if (minError) {
    yield put(forceHideMiniview("scroll", true))
    if (state.wizard.hasDiamondSelector) {
      yield put(
        showError(
          "m2",
          "Please select your initials for the Signature Setting."
        )
      )
      yield put(forcePanelIndex("m2"))
      yield put(scrollPanelIndex("m2"))

      if (!isTestMode) {
        dataLayer.push({
          event: "missed-product-option",
          optionName: "m2",
          styleCode: state.choices.style_code?.value,
        })
      }
    } else {
      if (!state.browser.is.mobile) {
        yield put(forcePanelIndex(minErrorEngravingFont || "ef"))
        if (!isTestMode) {
          dataLayer.push({
            event: "missed-product-option",
            optionName: minErrorEngravingFont || "ef",
            styleCode: state.choices.style_code?.value,
          })
        }
      }
    }
    yield put(cancelAddToCart())
    return
  } else if (history.action !== "PUSH") {
    closeModal()
  }
  // Diamond selector must show 'final_diamond' before user can add to cart
  if (state.wizard.hasDiamondSelector) {
    if (
      state.diamondUI.steps[state.diamondUI.currentStep] !== "final_diamond"
    ) {
      yield put(forcePanelIndex("diamond"))
      yield put(showError("s1", state.wizard.data.s1?.missing_msg))
      yield put(scrollPanelIndex("diamond"))
      yield put(cancelAddToCart())
      return
    }
  }

  // Do validation
  let errors = false
  let pref_name = ""
  let error_text = ""
  const requiredMessages = getRequiredMessages(state)
  if (Object.keys(requiredMessages).length > 0) {
    errors = true
    pref_name = Object.keys(requiredMessages)[0]
    error_text = requiredMessages[pref_name]
  }
  if (errors) {
    yield put(forceHideMiniview("scroll", true))
    yield put(showError(pref_name, error_text || true))

    if (state.wizard.hasDiamondSelector) {
      if (
        pref_name == "s1" ||
        ["d_carat", "d_cut", "d_clarity", "d_shape", "d_color"].indexOf(
          pref_name
        ) !== -1
      ) {
        pref_name = "diamond"
      } else if (pref_name == "e1" || pref_name == "e2") {
        pref_name = "m2"
      }
    }
    // if (["sz", "ch"].indexOf(pref_name) < 0 || !state.browser.is.mobile) {
    //   yield put(forcePanelIndex(pref_name))
    // }

    if (pref_name[0] == "e") {
      pref_name = minErrorEngravingFont || "ef"
    }
    if (pref_name === "diamond") {
      yield put(forcePanelIndex(pref_name))
    } else {
      yield put(
        setModalPanelIndex(pref_name, function () {
          return false
        })
      )

      switch (pref_name) {
        case "sz":
          if (
            sl.config.show_guaranteed &&
            state.wizard.shipping.main.available_holiday_delivery &&
            state.wizard.hasInventory
          ) {
            yield put(fetchInventory({ get_all_sizes: true }))
          }
          openModal("#ring-size")
          if (!isTestMode) {
            dataLayer.push({
              event: "missed-product-option",
              optionName: "sz",
              styleCode: state.choices.style_code?.value,
            })
          }
          break
        case "ch":
          openModal("#chain-size")
          if (!isTestMode) {
            dataLayer.push({
              event: "missed-product-option",
              optionName: pref_name,
              styleCode: state.choices.style_code?.value,
            })
          }
          break
        case "ef":
        case "ef2":
          openModal("#engravings")
          if (!isTestMode) {
            dataLayer.push({
              event: "missed-product-option",
              optionName: pref_name,
              styleCode: state.choices.style_code?.value,
            })
          }
          break
        default:
          if (/o[0-9]+/.test(pref_name)) {
            openModal("#switch-options")
          } else if (/ch[0-9]+/.test(pref_name)) {
            openModal(`#chain-size${pref_name.match(/\d+/)[0]}`)
            if (!isTestMode) {
              dataLayer.push({
                event: "missed-product-option",
                optionName: pref_name,
                styleCode: state.choices.style_code?.value,
              })
            }
            break
          } else if (/img[0-9]+/.test(pref_name)) {
            openModal(`#pendant-upload-${pref_name}`)
            if (!isTestMode) {
              dataLayer.push({
                event: "missed-product-option",
                optionName: pref_name,
                styleCode: state.choices.style_code?.value,
              })
            }
            break
          }
      }
      // $("#addOptionStoneModal .js-modal-title").html(modal_title)
    }
    yield put(cancelAddToCart())

    //insert_alert_container('danger', error_text);
    // goToPane( error_pane, error_focus, true, error_text);
    // if ($("."+error_pane).is('*')) {
    //   $("."+error_pane).parents('.panel-collapse').collapse('show').trigger('shown.bs.collapse');
    //   $('body,html').animate({
    //     scrollTop: $("."+error_pane).offset().top - 180
    //   }, 400);
    // }
  } else {
    // $.ajax({
    //   url: '/cart/add',
    //   type: 'POST',
    //   data: $('#item-form').serialize(),
    //   success: function(result) {
    //     window.location.href = '/cart';
    //   },
    //   error: function(result) {
    //   }
    // });
    // Remove debug flag

    var has_default_images = false
    // if ($(".image_input").is("*")) {
    //   $(".image_input").each(function() {
    //     //Is it a user uploaded photo
    //     if (
    //       $(this)
    //         .val()
    //         .indexOf("user_uploads/") === -1
    //     ) {
    //       has_default_images = $(this).attr("name")
    //     }
    //   })
    // }

    if (has_default_images) {
      if (
        !confirm(
          "Are you sure you would like to use the stock photography for this pendant?\nPress OK to Add to Cart, or Cancel to modify."
        )
      ) {
        yield put(forceHideMiniview("scroll", true))
        yield put(cancelAddToCart())
        return
      }
    }

    window.onbeforeunload = null

    if (state.wizard.formUrl) {
      try {
        const editForm = document.querySelector("#edit-form")
        editForm.submit()
      } catch (error) {
        window.alert("Something is wrong")
      }
      return
    }

    let formObj = toFormObject(state)
    formObj = { ...formObj }

    // Save clicked on history in the collectionStats cookie
    setClickedOnHistoryCookie(formObj.style_code)

    var result = yield call(api.post, `/api/v10/cart/${cartToken}`, formObj)
    const { data } = result
    if (data.success) {
      // Update cart
      yield put(setCart(data.cart))

      if (!isTestMode) {
        try {
          // Most recently added item is first in the list
          const addedLineItem = data.cart.line_items[0]
          var sale_price = addedLineItem.sale_price
          let boxAddon = addedLineItem.addons.find(
            (addon) => addon.addon_type === "BOX_PRINT"
          )

          dataLayer.push({
            addedItem: {
              itemCurrencyCode: addedLineItem.currency_code,
              itemDetails: addedLineItem.view_item_details.slice(1),
              itemImageUrl: addedLineItem.image_url,
              itemMetal: addedLineItem.sku,
              itemPrice: addedLineItem.price,
              itemRegularPrice: addedLineItem.regular_price,
              itemSalePrice: sale_price,
              itemShorthand: addedLineItem.item_shorthand,
              itemStyleCode: addedLineItem.style_code,
              itemTitle: addedLineItem.title,
              itemUrl: addedLineItem.full_product_url,
              itemVariant: addedLineItem.item_variant,
              pinterestEventId:
                data.cart.summary.token + "-" + addedLineItem.id,
              uniqueEventId: data.cart.summary.token + "-" + addedLineItem.id,
            },
            cartLineItems: data.cart.line_items_gtm,

            currencyCode: addedLineItem.currency_code,
            // GA Enhanced Ecommerce
            ecommerce: {
              add: {
                products: [
                  {
                    category: addedLineItem.collection_url,
                    id: addedLineItem.item_shorthand,
                    name: addedLineItem.style_code,
                    price: addedLineItem.price,
                    quantity: 1,
                    variant: "",
                  },
                ],
              },
              currencyCode: addedLineItem.currency_code,
            },
            event: "added-to-cart",
            // GA4 Variable
            ga4Items: [
              {
                currency: sl.config.currency_code,
                item_category: addedLineItem.product_category,
                item_id: addedLineItem.item_variant,
                item_list_name: addedLineItem.collection_url,
                item_name: addedLineItem.style_code_gtm,
                price: addedLineItem.price,
                quantity: 1,
                variant: addedLineItem.sku,
              },
            ],
            // END GA4 Variable
            gaEventAction: "add_to_cart",
            gaEventCategory: "Product",
            gaEventLabel: addedLineItem.item_shorthand,
            gaEventNonInteraction: true,
            gaEventValue: addedLineItem.price,
            orderToken: data.cart.summary.token,
            photoBoxAdded: boxAddon ? true : false,
            productPrice: addedLineItem.price,
            productSku: addedLineItem.sku,
            productTitle: addedLineItem.title,
            productType: addedLineItem.product_type,
            styleCode: addedLineItem.style_code,
            supplier: addedLineItem.supplier,
          })

          if (boxAddon) {
            let userPhoto = addedLineItem.full_product_url.includes(
              "user_uploads"
            )
            dataLayer.push({
              event: "photobox-added-to-cart",
              photoBoxPrice: boxAddon.price,
              photoBoxType: userPhoto ? "user-photo" : "regular",
            })
          }
        } catch (e) {
          console.log("Added to Cart dataLayer error", e)
        }
      }
      // Remove 'box' from user prefs and choices so it doesn't get added to other products
      removePref("box")
      yield put(updateChoiceDirectly("box", { value: "" }))

      if (window.innerWidth >= 768) {
        openModal("#show-cart-item")
      } else {
        yield put(toggleCartPreview(true))
      }
      // We're not actually cancelling the add to cart here,
      // it just resets the "Adding to Cart" button so
      // subsequent PDP pages aren't frozen
      yield put(cancelAddToCart())
    } else {
      // TODO: Better error message for Add to Cart
      alert(data.message)
      yield put(cancelAddToCart())
    }

    // try {
    // var hc = hitFallback(function() {
    //   try {
    //     $("#defaultCatch").remove()
    //     $("#item-form").submit()
    //   } catch (e) {
    //     $("#item-form").submit()
    //   }
    // })

    // } catch (e) {
    //   $("#defaultCatch").remove()
    //   $("#item-form").submit()
    // }
  }
}
