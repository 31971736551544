import { SET_GOOGLE_APPLE_PAY } from "./actions"

const INITIAL_STATE = {
  canProcessApplePay: null,
  canProcessGooglePay: null,
}

export default function cartUIReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_GOOGLE_APPLE_PAY:
      return {
        ...state,
        canProcessApplePay: action.applePay,
        canProcessGooglePay: action.googlePay,
      }
    default:
      return state
  }
}
