export const SET_WISH_LIST = "SET_WISH_LIST"
export const FETCH_WISH_LIST = "FETCH_WISH_LIST"
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST"
export const SET_WISHLIST_COUNT = "SET_WISHLIST_COUNT"
export const SET_WISHLIST_LOADING = "SET_WISHLIST_LOADING"

export function fetchWishList(forceFetch = false) {
  return { forceFetch, type: FETCH_WISH_LIST }
}

export function removeFromWishlist(id) {
  return { id, type: REMOVE_FROM_WISHLIST }
}

export function setWishList(wishlist) {
  return { type: SET_WISH_LIST, wishlist }
}

export function setWishlistCount(count) {
  return { count, type: SET_WISHLIST_COUNT }
}

export function setWishlistLoading(loading) {
  return { loading, type: SET_WISHLIST_LOADING }
}
