import { replace } from "connected-react-router"
import Cookies from "js-cookie"
import { put, select } from "redux-saga/effects"
import URI from "urijs"

import api from "apis"
import {
  setFacebookLoginStatus,
  setFacebookLoginState,
  doLogin,
} from "app-store-v2/app/actions"

export default function* facebookConnect(action) {
  try {
    const data = action.data
    const state = yield select()
    const query = URI.parseQuery(state.router.location.search)

    var params_hash = {}
    params_hash["fb_uid"] = data.authResponse.userID
    params_hash["fb_access_token"] = data.authResponse.accessToken
    params_hash["cart_token"] = Cookies.get(sl.config.cookies_config.cart_token)
    params_hash["wishlist_token"] = Cookies.get(
      sl.config.cookies_config.wishlist_token
    )

    yield put(setFacebookLoginState("connecting"))
    const result = yield api.post("/api/v10/facebook_connect", params_hash)

    if (result?.data) {
      if (query.order_token && query.order_token !== "") {
        // yield put(toggleAccountModal(false))
        yield put(
          replace(
            `${state.router.location.pathname}${state.router.location.search}`
          )
        )
      }
      yield put(setFacebookLoginStatus(result.data))
      yield put(doLogin(result.data, "facebook"))
    }
    // TODO: Handle just_created by opening the tell us more modal
  } catch (e) {
    console.log("Facebook Connect Error", e)
  }
}
