import { push } from "connected-react-router"
import Cookies from "js-cookie"
import { put, select } from "redux-saga/effects"
import URI from "urijs"

import api from "apis"

import { doLogin, setExplicitLogin, setLoginNotification } from "../actions"

export default function* submitRegistration({
  actions: { onError, onSuccess },
  values,
}) {
  try {
    const state = yield select()
    const query = URI.parseQuery(state.router.location.search)

    let params = {
      cart_token: Cookies.get(sl.config.cookies_config.cart_token),
      email: values.email,
      password: values.password,
      subscribe_vip: values.subscribe_vip,
      wishlist_token: Cookies.get(sl.config.cookies_config.wishlist_token),
    }
    yield put(setExplicitLogin())

    params.order_token = query.order_token
    const result = yield api.post("/api/v10/signup", params)

    if (result?.data) {
      yield put(setLoginNotification(null))
      if (result.data.redirect) {
        yield put(push(result.data.redirect))
      } else {
        yield put(doLogin(result.data, "jewlrorders"))
      }

      if (
        state.router.location.pathname.includes("/signup") ||
        state.router.location.pathname.includes("/register")
      ) {
        yield put(push("/account"))
      }

      if (window.location.href.includes("/signup")) {
        yield put(push("/account"))
      }
    }
    onSuccess()
  } catch (err) {
    console.log(err)
    onError(err?.errors?.[0])
  }
}
