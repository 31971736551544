export const interpolateProductsWithPages = (result) => {
  if (result?.product_result?.[0]) {
    result.product_result[0].pageHref = result.pagination.current_page
    result.product_result[0].pageNum = result.pagination.current_page_number
  }

  const products = result?.product_result || []
  products.forEach((item) => {
    item.pageIndex = result.pagination.current_page_number
  })
  return result
}
