import { Button } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const SkipButton = styled(Button).attrs({
  variant: "text",
})`
  background-color: white;
  border: 2px solid black;
  color: black;
  left: 50%;
  opacity: 0;
  padding: 1em;
  position: absolute;
  top: 48px;
  transform: translateX(-50%);
  z-index: -999;
  &:focus {
    opacity: 1;
    z-index: 999;
  }
`

const SkipToMainContent = ({ handleSkipToMainContent }) => (
  <SkipButton onClick={handleSkipToMainContent} tabIndex="0">
    Skip to main content
  </SkipButton>
)

SkipToMainContent.propTypes = {
  handleSkipToMainContent: PropTypes.func,
}

export default SkipToMainContent
