import { call, put } from "redux-saga/effects"

import api from "apis"

import { setCart } from "../actions"

export default function* removeGiftWrap(action) {
  try {
    let url = "/cart"
    const result = yield call(api.put, url, {
      gift_update: "true",
      line_item_id: action.id,
      update_type: "delete",
    })
    if (result) {
      yield put(setCart(result))
    }
  } catch (error) {}
}
