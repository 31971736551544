import { put, call, select } from "redux-saga/effects"

import api from "apis"

import { showShareEmailModal } from "../actions"

export default function* emailShare() {
  try {
    const state = yield select()

    var qs = ""
    var choice_keys = Object.keys(state.choices)
    for (var i = 0; i < choice_keys.length; i++) {
      qs +=
        "&" +
        choice_keys[i] +
        "=" +
        encodeURIComponent(state.choices[choice_keys[i]].value || "")
    }
    const first_view = state.previewImage.data.all_views[0]
    let images = {
      ...state.wizard.images,
      ...state.previewImage.data.images,
    }
    let imageUrl = images[`${first_view}_view`]?.src
    var share_properties = {
      style_code: state.choices.style_code?.value,
      title: state.wizard.title,
      description: "", // NOT USED ANYMORE?
      price: state.wizardUI.currentPricing.price_to_display,
      currency: sl.config.default_currency,
      item_url: `${state.wizard.shareUrl}?${qs}`,
      image_url: imageUrl,
      product_options: null, // NOT USED ANYMORE?
      regular_pricing: state.wizardUI.currentPricing.regular_pricing,
      retail_price: state.wizardUI.currentPricing.retail_price,
    }
    const response = yield call(
      api.post,
      "/products/email_a_friend.json",
      share_properties
    )
    yield put(showShareEmailModal(response))
  } catch (error) {
    console.error("Failed to fetch share properties", error)
  }
}
