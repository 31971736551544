import { delay, put } from "redux-saga/effects"

import api from "apis"
import { updateGiftProperties } from "areas/cart/store/actions"

export default function* generateGiftVideo({
  actions: { onError, onSuccess },
  lineItemId,
  values,
}) {
  try {
    let videoUrl = ""
    const response = yield api.post(
      "/api/v4/line_items/generate_personalized_video",
      values
    )
    if (response.errors) {
      onError(
        response.errors?.length > 0
          ? response.errors[0]?.message || response.errors[0]
          : response.errors?.message
      )
    } else if (response.data) {
      // poll video status
      const checkStatusUrl = `/api/v4/line_items/video_status?id=${response.data.video_id}`
      let checkStatus = yield api.get(checkStatusUrl)
      while (
        checkStatus.data &&
        !["VIDEO_AVAILABLE", "ERROR", "FUNCTION_ERROR", "NOT_EXIST"].includes(
          checkStatus.data.status
        )
      ) {
        yield delay(1000)
        checkStatus = yield api.get(checkStatusUrl)
      }
      // save video data after poll
      if (checkStatus.data.status === "VIDEO_AVAILABLE") {
        videoUrl = response.data.url
      } else {
        onError("Something went wrong.")
      }
    }

    // Update gift properties to line item and set it in the store
    const giftProperties = values.data
    yield put(
      updateGiftProperties(lineItemId, {
        previewUpdated: true,
        video_url: videoUrl,
        ...giftProperties,
      })
    )

    onSuccess()
  } catch (error) {
    console.log("Generate Gift Video Error", error)
    onError(error?.errors?.[0])
  }
}
