import { useEffect, useState } from "react"

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    height: undefined,
    isDesktop: false,
    isMobile: false,
    isTablet: false,
    isWidescreen: false,
    width: undefined,
  })

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth
      setWindowDimensions({
        height: window.innerHeight,
        isDesktop: windowWidth >= 1024,
        isMobile: windowWidth <= 767,
        isTablet: windowWidth <= 1023,
        isWidescreen: windowWidth >= 1440,
        width: windowWidth,
      })
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}

export default useWindowDimensions
