import { ORDER_COMPLETED } from "areas/checkout/store/actions"

import {
  DELETE_SAVED_CART,
  SET_CART,
  SHOW_LINE_ITEM_DETAILS,
  TOGGLE_GIFT,
  ENTER_COUPON,
  UPDATE_GIFT_PROPERTIES,
  SET_GIFT_PROPERTIES,
  TOGGLE_SAVED_ITEM_ALERT,
  TOGGLE_CART_PREVIEW,
} from "./actions"

const INITIAL_STATE = {
  addons: [],
  cartLoaded: false,
  count: 0,
  err_msg: null,
  free_gift_offers: [],
  line_items: [],
  premium_gift_offers: [],
  summary: {},
}

export default function cartReducer(state = INITIAL_STATE, action) {
  let newState = Object.assign({}, state)

  switch (action.type) {
    case SET_CART:
      Object.keys(action.cart).map((key) => {
        newState[key] = action.cart[key]
      })
      return newState

    case SHOW_LINE_ITEM_DETAILS:
      let new_line_item_details = newState.show_line_item_details.slice()

      if (!action.id) {
        new_line_item_details = []
      } else {
        let index = new_line_item_details.indexOf(action.id)
        if (index !== -1) {
          new_line_item_details.splice(index, 1)
        } else {
          new_line_item_details.push(action.id)
        }
      }
      newState.show_line_item_details = new_line_item_details
      return newState

    case TOGGLE_GIFT: {
      let line_items = newState.line_items.map((item) => {
        if (item.id === action.id) {
          let details = Object.assign({}, item.is_gift_details, {
            toggled: !item.is_gift_details.toggled,
          })
          return {
            ...item,
            is_gift_details: details,
          }
        }
        return item
      })

      return {
        ...newState,
        line_items: line_items,
      }
    }
    case ENTER_COUPON:
      newState.summary.promo_code_entered = action.coupon_code
      return newState
    case SET_GIFT_PROPERTIES:
    case UPDATE_GIFT_PROPERTIES: {
      let line_items = newState.line_items.map((item) => {
        if (item.id === action.lineItemId) {
          return {
            ...item,
            gift_properties: {
              ...item.gift_properties,
              ...action.values,
            },
          }
        }
        return item
      })
      return {
        ...newState,
        line_items: line_items,
      }
    }
    case TOGGLE_CART_PREVIEW:
      newState.showSidebar = action.toggle
      return newState
    case TOGGLE_SAVED_ITEM_ALERT:
      newState.showSavedItemAlert = action.toggle
      return newState
    case ORDER_COMPLETED:
    case DELETE_SAVED_CART:
      newState = INITIAL_STATE
      return newState
    default:
      return state
  }
}
