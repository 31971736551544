const initialState = {
  confirmAddToWishlist: true,
  loading: null,
  loadingMoreProducts: false,
  newPageStartIndex: -1,
  no_result_message: "",
  // TODO: Move to local state
  open_filter_menu: false,
  open_sort_menu: false,
  pageNotFound: false,
}

function collectionUI(state = initialState, action) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case "CONFIRM_ADD_TO_WISHLIST":
      newState.confirmAddToWishlist = action.toggle
      return newState

    case "SET_COLLECTION":
      newState.loadingMoreProducts = false
      newState.loading = null
      newState.no_result_message = action.collection.no_result_message
      return newState

    case "SET_LOADING":
      newState.loading = action.loading
      return newState

    case "SET_LOADING_MORE_PRODUCTS":
      newState.loadingMoreProducts = action.loading
      return newState

    case "SET_PAGE_NOT_FOUND":
      newState.pageNotFound = action.active
      return newState

    case "TOGGLE_FILTER_MENU":
      newState.open_filter_menu = action.open
      return newState

    case "TOGGLE_SORT_MENU":
      newState.open_sort_menu = action.open
      return newState

    case "UPDATE_NEW_PAGE_START_INDEX":
      newState.newPageStartIndex = action.index
      return newState

    default:
      return state
  }
}

export default collectionUI
