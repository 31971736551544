import { jewlr_t } from "helpers/application"

export const getInitialPanels = () => {
  const panels = [
    {
      actionTitle: "Contact Information",
      complete: false,
      name: "contact",
      opened: true,
      title: "Contact Information",
    },
    {
      actionTitle: `${jewlr_t("terms.Shipping")} Address`,
      complete: false,
      name: "ship_address",
      opened: false,
      title: `${jewlr_t("terms.Shipping")} Address`,
    },
    {
      actionTitle: `${jewlr_t("terms.Shipping")} Options`,
      complete: false,
      name: "ship_methods",
      opened: false,
      title: `${jewlr_t("terms.Shipping")} Options`,
    },
    {
      actionTitle: "Payment",
      complete: false,
      name: "payment_methods",
      opened: false,
      title: "Payment",
    },
  ]

  return panels
}
