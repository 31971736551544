import {
  SET_WISH_LIST,
  SET_WISHLIST_COUNT,
  SET_WISHLIST_LOADING,
} from "./actions"

const INITIAL_STATE = {
  count: 0,
  loading: false,
  show: false,
}

export default function header(state = INITIAL_STATE, action) {
  let newState = Object.assign({}, state)

  switch (action.type) {
    case SET_WISH_LIST:
      newState = action.wishlist
      newState.show = true
      newState.count = action.wishlist.line_items.length
      newState.loading = false
      return newState
    case SET_WISHLIST_COUNT:
      newState.count = action.count
      return newState
    case SET_WISHLIST_LOADING:
      newState.loading = action.loading
      return newState
    default:
      return state
  }
}
