import Cookies from "js-cookie"
import { call, put } from "redux-saga/effects"
import scroll from "scroll"
import scrollDoc from "scroll-doc"

import api from "apis"
import { setCart } from "areas/cart/store/actions"

export default function* emptyCart() {
  const cart_token = Cookies.get(sl.config.cookies_config.cart_token)
  try {
    let url = `/api/v10/cart/${cart_token}`
    const result = yield call(api.delete, url)
    if (result) {
      yield put(setCart(result.data))
      const page = scrollDoc()
      scroll.top(page, 0)
    }
  } catch (error) {
    location.reload()
  }
}
