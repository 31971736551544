import { all, takeLatest } from "redux-saga/effects"

import fetchCartList from "app-store-v2/app/sagas/fetch-cart-list"

import { FETCH_CART_LIST_CHECKOUT } from "./actions"

function* shareSagas() {
  yield all([takeLatest(FETCH_CART_LIST_CHECKOUT, fetchCartList)])
}

export default shareSagas
