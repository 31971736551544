import { put } from "redux-saga/effects"

import { mayUpdateSearchSuggestions } from "app-store-v2/app/actions"

function* processSuggestions(result, query, auto_suggest_results) {
  if (result) {
    result.suggested_products.forEach((search_result) => {
      auto_suggest_results.push(search_result)
    })
  }

  yield put(mayUpdateSearchSuggestions(auto_suggest_results, query))
}

export default processSuggestions
