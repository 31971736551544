import { all, call, put, select } from "redux-saga/effects"
import api from "apis"
import {
  forcePanelIndex,
  setBuilderItems,
  updateChoice,
  setWizardData,
  setExtraWizardPrefOrder
} from "../actions"

export default function* moveBuilderItem(action) {
  try {
    const state = yield select()
    const pref = action.pref
    const prefIndex = parseInt(pref.replace("c", ""))
    const moveBy = action.moveDirection

    let remaps = {}
    remaps[pref] = "c" + (prefIndex + moveBy)
    remaps["c" + (prefIndex + moveBy)] = pref

    const remapKeys = Object.keys(remaps)
    const wizardKeys = Object.keys(state.wizard.data)
    const choiceKeys = Object.keys(state.choices)

    let choices = []
    let datas = []
    let prefOrders = []
    for (var i = 0; i < remapKeys.length; i++) {
      const remapKey = remapKeys[i]
      const remapTo = remaps[remapKey]
      prefOrders.push({
        pref: remapTo,
        order: state.wizard.extraPrefOrder[remapKey].map(el =>
          el.replace(remapKey, remapTo)
        )
      })
      yield put(setExtraWizardPrefOrder(remapKey, false))
      for (var j = 0; j < choiceKeys.length; j++) {
        const choiceKey = choiceKeys[j]
        if (choiceKey.indexOf(remapKey) !== -1) {
          let choice = state.choices[choiceKey]
          if (choice.pref) {
            choice.pref = choice.pref.replace(remapKey, remapTo)
          }
          if (choice.title && choiceKey == remapKey) {
            choice.title =
              state.wizard.data.cc.item_heading.title +
              " " +
              remapTo.replace("c", "")
          }
          choices.push({
            pref: choiceKey.replace(remapKey, remapTo),
            choice: choice
          })
        }
      }
      for (var j = 0; j < wizardKeys.length; j++) {
        const wizardKey = wizardKeys[j]
        if (wizardKey.indexOf(remapKey) !== -1) {
          let data = state.wizard.data[wizardKey]
          data.pref = data.pref.replace(remapKey, remapTo)
          if (data.heading.pref) {
            data.heading.pref = data.heading.pref.replace(remapKey, remapTo)
          }
          if (data.heading.imgPref) {
            data.heading.imgPref = data.heading.imgPref.replace(
              remapKey,
              remapTo
            )
          }
          datas.push(data)
        }
      }
    }

    for (var i = 0; i < choices.length; i++) {
      yield put(updateChoice(choices[i].pref, choices[i].choice))
    }
    for (var i = 0; i < datas.length; i++) {
      yield put(setWizardData(datas[i]))
    }
    for (var i = 0; i < prefOrders.length; i++) {
      yield put(
        setExtraWizardPrefOrder(prefOrders[i].pref, prefOrders[i].order)
      )
    }
    yield put(forcePanelIndex(remaps[pref]))
    const subPanelOpened = state.panel.subActivePanelIndex[pref]
    if (subPanelOpened && subPanelOpened != "") {
      yield put(
        forcePanelIndex(
          subPanelOpened.replace(pref, remaps[pref]),
          remaps[pref]
        )
      )
    }
  } catch (e) {
    console.error("REMOVE BUILDER ITEM FAIL", e)
  }
}
