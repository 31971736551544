import { all, debounce, takeLatest } from "redux-saga/effects"

import addWishlist from "./sagas/add-wishlist"
import loadReactCollection from "./sagas/load-react-collection"
import loadReactCollectionSsr from "./sagas/load-react-collection-ssr"
import removeWishlist from "./sagas/remove-wishlist"
import updateFilter from "./sagas/update-filter"

function* rootSaga() {
  yield all([
    takeLatest("ADD_TO_WISHLIST", addWishlist),
    takeLatest("CLEAR_CATEGORY_FILTERS", updateFilter),
    takeLatest("LOAD_REACT_COLLECTION", loadReactCollection),
    takeLatest("LOAD_REACT_COLLECTION_SSR", loadReactCollectionSsr),
    takeLatest("UNSELECT_FROM_WISHLIST", removeWishlist),
    takeLatest("UPDATE_CURRENT_PAGE", updateFilter),
    takeLatest("UPDATE_CURRENT_SORT", updateFilter),
    debounce(500, ["UPDATE_FILTER", "UPDATE_UNIQUE_FILTER"], updateFilter),
    takeLatest("RELOAD_COLLECTION", updateFilter),
    debounce(1000, "UPDATE_PRICE_RANGE", updateFilter),
  ])
}

export default rootSaga
