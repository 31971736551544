import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { withRouter } from "react-router-dom"

import { getHreflangs } from "helpers/application"

const Hreflang = ({ location }) => {
  const [hreflangs, setHreflangs] = useState([])

  useEffect(() => {
    setHreflangs(getHreflangs(location.pathname))
  }, [location.pathname])

  return (
    <Helmet>
      {hreflangs.map(({ href, hreflang }) => (
        <link href={href} hrefLang={hreflang} key={hreflang} rel="alternate" />
      ))}
    </Helmet>
  )
}

Hreflang.propTypes = {
  location: PropTypes.object,
}

export default withRouter(Hreflang)
