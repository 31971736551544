let storage = {}
try {
  storage = window.localStorage
} catch (e) {
  console.log(e)
}

const defaultDays = 1 //day
const keyPrefix = "_jwl_"

/**
 * Set prefix to item key
 */
function pkey(key) {
  return `${keyPrefix}${key}`
}
/**
 * Entry for localStorage with ttl property to track if storage session expired
 */
const storeEntry = (data, days = defaultDays) => {
  return JSON.stringify({
    ttl: new Date(Date.now() + days * 24 * 3600 * 1000),
    value: data,
  })
}

/**
 * Store item in localStorage
 */
const store = (key, data) => {
  storage.setItem(pkey(key), storeEntry(data))
}

/**
 * Check if item exists
 * if there is no item under priveded key or TTL expired will return false
 * otherwise true
 */
const exist = (key) => {
  var entry = JSON.parse(storage.getItem(pkey(key)))
  if (entry && new Date(entry.ttl) > Date.now()) {
    return true
  }
  storage.removeItem(pkey(key))
  return false
}

/**
 * Remove item from localstorage inspite of ttl
 */
const remove = (key) => {
  storage.removeItem(pkey(key))
}

/**
 * Get item from localStorage if exists
 */
const get = (key) => {
  if (!exist(key)) {
    remove(key)
  }
  return JSON.parse(storage.getItem(pkey(key))).value
}

const list = (filterName = "") => {
  let items = Object.entries(storage).sort((a, b) => b[1].length - a[1].length)
  if (filterName) {
    items = items
      .filter((item) => item[0].includes(filterName))
      .map((item) => item[0].replace(keyPrefix, ""))
  }
  return items
}

const StorageHelper = {
  exist,
  get,
  list,
  remove,
  store,
}

export default StorageHelper
