import { call, put } from "redux-saga/effects"

import api from "apis"

import { setUserPreferences } from "../actions"

function* loadUserPreferences(action) {
  try {
    const response = yield call(
      api.get,
      `/secure/api/v10/preferences/${action.guidToken}`
    )
    if (response && response.data) {
      yield put(setUserPreferences(response.data))
    }
  } catch (err) {
    yield put(setUserPreferences(null))
    console.log("Error while fetching preferences", err)
  }
}
export default loadUserPreferences
