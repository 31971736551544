export const SHOW_SHARE_EMAIL_MODAL = 'SHOW_SHARE_EMAIL_MODAL'
export const FETCH_SHARE_EMAIL = 'FETCH_SHARE_EMAIL'
export const FETCH_CART_SHARE = 'FETCH_CART_SHARE'
export const TOGGLE_EMAIL_SHARE_MODAL = "TOGGLE_EMAIL_SHARE_MODAL"
export const EMPTY_SHARE_DATA = 'EMPTY_SHARE_DATA'
export const SET_MODAL_TYPE = 'SET_MODAL_TYPE'
export const TOGGLE_SHARE_MODAL = 'TOGGLE_SHARE_MODAL'



export function showShareEmailModal(data) {
  return {
    type: SHOW_SHARE_EMAIL_MODAL,
    data
  }
}

export function fetchShareEmail() {
  return {
    type: FETCH_SHARE_EMAIL
  }
}

export function fetchCartShare(cart_type = 'cart') {
  return {
    type: FETCH_CART_SHARE,
    cart_type
  }
}

export function toggleEmailShareModal(toggle) {
  return {
    type: TOGGLE_EMAIL_SHARE_MODAL,
    toggle
  }
}

export function clearShareData() {
  return {
    type: EMPTY_SHARE_DATA
  }
}

export function toggleShareModal(modal) {
  return {
    type: TOGGLE_SHARE_MODAL,
    modal
  }
}

export function setModalType(modal) {
  return {
    type: SET_MODAL_TYPE,
    modal
  }
}