import { all, call, put, select } from "redux-saga/effects"

import { perfMark, isTestMode } from "helpers/application"
import filterMatch from "helpers/product/filter-match"
import limitChars from "helpers/product/limit-chars"
import {
  checkMetalExists,
  filterProductOptionsV2,
} from "helpers/product/product-filtering"
import mapStoneCodeToSimilarStoneCode from "helpers/similar-stones"
import { getPref, getProductPrefs, storePref } from "helpers/user-prefs"

import { thresholdSettings } from "../../../../helpers/image"
import {
  activatePrefStore,
  updateChoice,
  updateDiamond,
  setBuilderItems,
  setWizardData,
} from "../actions"
import { mapShapeToStylecode } from "../helpers/diamond"

function* updateChoiceOption(pref, opt) {
  yield put(updateChoice(pref, opt))
}

export default function* initWizard() {
  perfMark("product:init_wizard:start")
  let state = yield select()
  const product_defaults = state.choices
  const productPrefs = getProductPrefs(state.choices.style_code?.value)
  let wizardKeys = Object.keys(state.wizard.data)
  let jluxStartOver = false
  const ssr = state.router.action == "POP"

  wizardKeys.push(
    ...["d_stone_type", "d_shape", "d_carat", "d_cut", "d_color", "d_clarity"]
  )
  let imageCropper,
    uploadedUrl,
    checkAdminPrefCached,
    handwriting_engraving,
    threshold_value

  //Handle sub-options
  // if (wizardKeys.indexOf("o1") > -1) {
  //   wizardKeys.push(
  //     ...[...new Set(state.wizard.data["o1"].options.map(cv => cv.pref))]
  //   )
  // }

  for (var i = 0; i < wizardKeys.length; i++) {
    const key = wizardKeys[i]
    // Get the default option first, to use as fallback in case valid choice doesn't exist
    // or a product default isn't available
    let defOpt = null
    state = yield select()
    let options = state.wizard.data[key]?.options || null

    if (typeof state.wizard.data[key] !== "undefined" && options) {
      if (
        options &&
        state.wizard.filters[key]?.length > 0 &&
        !/^o[1-9]$/.test(key)
      ) {
        options = filterProductOptionsV2(options, product_defaults)
      }
      const freeOptions = options.filter((opt) => opt.price === 0)
      defOpt =
        freeOptions.length > 0
          ? freeOptions[0]
          : state.wizard.data[key].options[0]
      if (state.wizard.data[key].disabled) {
        defOpt = Object.assign({}, defOpt, { disabled: true })
      }
    }
    const key_value = product_defaults[key] ? product_defaults[key].value : null
    const alt_value = productPrefs[key]

    const resetChainOpt = {
      chain_type: "",
      display_price: null,
      price: 0,
      title: null,
      value: "",
    }

    if ((!key_value || key_value == "") && !key.match(/o[0-9]+/)) {
      if (defOpt && key != "sz" && key != "ch" && key != "ch2") {
        yield put(updateChoice(key, defOpt))
      }
      // For engravings, the choice needs to be populated still, even if default is null
      if (key[0] != "e") {
        continue
      }
    }

    switch (key) {
      case "lid":
        break
      case "cc": {
        let itemCount = parseInt(key_value)
        yield put(setBuilderItems(itemCount, product_defaults))
        break
      }
      case "a1":
      case "a2":
      case "as":
      case "b1":
      case "b2":
      case "b3":
      case "face1":
      case "face2":
      case "head":
      case "m2":
      case "m3":
      case "rw":
      case "shank":
      case "sku":
      case "sz":
        if (
          typeof state.wizard.data[key] !== "undefined" &&
          !state.wizard.data[key].disabled
        ) {
          let opt = options.find((i) => i.value == key_value)
          if (!opt && alt_value) {
            opt = options.find((i) => i.value == alt_value)
          }
          if (opt) {
            yield put(updateChoice(key, opt))
          } else if (defOpt && !state.wizard.data[key].empty_default) {
            yield put(updateChoice(key, defOpt))
          }
        }
        break
      case "o1":
      case "o2":
      case "o3":
      case "o4":
      case "o5":
      case "o6":
      case "o7":
      case "o8":
      case "o9": {
        let wizardData = state.wizard.data[key]

        if (!wizardData) return false

        if (
          wizardData.options_display_labels &&
          Object.keys(wizardData.options_display_labels).length > 1
        ) {
          // When options are grouped together under same panel
          // need to add each of them to set default choice
          yield all(
            [...Object.keys(wizardData.options_display_labels)].map((pref) => {
              const safePref = product_defaults[pref]
                ? product_defaults[pref].value
                : ""
              let opt = options.find(
                (i) => i.value == safePref && i.pref == pref
              )
              if (!opt && alt_value) {
                opt = options.find(
                  (i) => i.value == alt_value && i.pref == pref
                )
              } else if (!opt) {
                opt = options.find((i) => i.pref == pref)
              }
              if (wizardData.ui_type === "stylecode") {
                opt = options.find(
                  (o) =>
                    o.style_code === state.choices.style_code?.value &&
                    o.pref === pref
                )
              }
              return call(updateChoiceOption, pref, opt)
            })
          )
        } else if (["stylecode", "dropdown"].includes(wizardData.ui_type)) {
          /** Choice option style code switcher
           * Get option with product style code and update choice
           */
          let opt = options.find(
            (o) => o.style_code === state.choices.style_code?.value
          )
          if (opt) {
            yield put(updateChoice(key, opt))
          } else if (wizardData.is_looks) {
            if (defOpt) {
              yield put(updateChoice(key, defOpt))
            } else {
              yield put(
                updateChoice(key, { value: state.choices.style_code?.value })
              )
            }
          } else if (wizardData.ui_type === "dropdown" && options.length > 0) {
            let opt = options?.find((i) => i.value == key_value)
            if (opt) {
              yield put(updateChoice(key, opt))
            } else {
              opt = options[0]
              yield put(updateChoice(key, opt))
            }
          }
        } else {
          if (options && state.wizard.filters[key]?.length > 0) {
            options = filterProductOptionsV2(options, product_defaults)
          }
          let opt = options?.find((i) => i.value == key_value)
          if (opt) {
            yield put(updateChoice(key, opt))
          } else if (defOpt && !wizardData.empty_default) {
            yield put(updateChoice(key, defOpt))
          }
        }

        break
      }
      case "ch":
      case "ch2":
        if (typeof state.wizard.data[key] !== "undefined") {
          // Handle the rope chain removal
          const adminMode = state.wizard.adminMode
          const header = state.wizard.header
          const historical = state.wizard.orderDate
            ? new Date(state.wizard.orderDate) <= new Date("2024-07-22")
            : false
          const external = state.wizard.external
          const line_item_id = state.router.location.query.line_item_id
          options = filterProductOptionsV2(
            options,
            {
              ...state.choices,
              admin: {
                pref: "admin",
                value:
                  ((adminMode || header.params_olid_present) && historical) ||
                  external
                    ? "1"
                    : "0",
              },
            },
            true
          )
          let currCh = options.find((chain) => chain.value == key_value)
          if (!currCh && alt_value) {
            currCh = options.find((chain) => chain.value == alt_value)
          }
          if (currCh) {
            const newCh = options.find((chain) => {
              return (
                checkMetalExists(chain.filters.sku, state.choices.sku) &&
                chain.chain_type == (currCh ? currCh.chain_type : "") &&
                chain.chain_length == (currCh ? currCh.chain_length : "") &&
                chain.value === key_value
              )
            })
            if (newCh) {
              if (
                (ssr &&
                  state.router.location.query[key] &&
                  state.router.location.query[key] == newCh?.value) ||
                options.length == 1
              ) {
                yield put(updateChoice(key, newCh))
              } else {
                yield put(
                  updateChoice(key, {
                    ...resetChainOpt,
                    chain_type: newCh.chain_type,
                    ...(line_item_id
                      ? newCh
                      : { chain_type: newCh.chain_type }),
                  })
                )
              }
            } else if (alt_value) {
              const altCh = options.find((chain) => chain.value == alt_value)
              const altNewCh = options.find((chain) => {
                return (
                  checkMetalExists(chain.filters.sku, state.choices.sku) &&
                  chain.chain_type == (altCh ? altCh.chain_type : "") &&
                  chain.chain_length == (altCh ? altCh.chain_length : "") &&
                  chain.value === alt_value
                )
              })
              if (altNewCh) {
                yield put(
                  updateChoice(key, {
                    ...resetChainOpt,
                    ...(line_item_id
                      ? altNewCh
                      : { chain_type: altNewCh.chain_type }),
                  })
                )
              } else {
                yield put(updateChoice(key, resetChainOpt))
              }
            } else {
              // Special case - chains often don't match so we clear them out
              yield put(updateChoice(key, resetChainOpt))
            }
          } else {
            // Clear chain if no currCh
            yield put(updateChoice(key, resetChainOpt))
          }
        }
        break
      case "d_shape":
      case "d_carat":
        if (typeof state.wizard.data?.diamond?.[key] !== "undefined") {
          let opt = state.wizard.data.diamond[key].options.find(
            (i) => i.value == key_value
          )
          // if (!opt && alt_value) {
          //   opt = state.wizard.data?.diamond[key].options.find(
          //     (i) => i.value == alt_value
          //   )
          // }

          if (!opt && key === "d_shape") {
            // Get shape from diamond options that matches stylecode
            const style_code = state.choices.style_code?.value
            const diamond_shape = style_code.split("-")[1][0]
            opt = state.wizard.data?.diamond[key].options.find(
              (i) => mapShapeToStylecode[i.value] === diamond_shape
            )
            jluxStartOver = true
          }
          if (opt) {
            yield put(updateDiamond(key, opt))
            yield put(updateChoice(key, opt))
          } else {
            yield put(updateDiamond(key, { value: "" }))
          }
        }
        break
      case "d_stone_type":
      case "d_cut":
      case "d_color":
      case "d_clarity":
        if (state.wizard.data.diamond) {
          if ((!key_value || key_value == "") && alt_value) {
            yield put(updateDiamond(key, { value: alt_value }))
          } else {
            yield put(updateDiamond(key, { value: key_value }))
          }
        }

        break
      case "sc":
        // Don't trigger change handler until end of this function
        // $("."+key+"_pane .wizard-option").val(key_value);
        if (typeof state.wizard.data.sc !== "undefined") {
          let opt = options.find((sc) => sc.value == key_value)
          if (!opt && alt_value) {
            opt = options.find((sc) => sc.value == alt_value)
          }
          if (opt) {
            yield put(updateChoice("sc", opt))

            if (
              state.wizard.data.ef &&
              state.wizard.data.ef.implicit_engraving_count
            ) {
              yield put(updateChoice("ec", { value: parseInt(opt.value) }))

              let num_engravings = opt.value
              let max_engravings = state.wizard.data.ef.max_engraving_count
              for (let j = 1; j <= num_engravings; j++) {
                yield put(updateChoice("e" + j, { disabled: false }))
              }
              for (
                let j = parseInt(num_engravings) + 1;
                j <= max_engravings;
                j++
              ) {
                yield put(updateChoice("e" + j, { disabled: true }))
              }
            }
          } else if (defOpt) {
            yield put(updateChoice("sc", defOpt))
          }
        }
        break
      case "chead":
      case "cshank":
        if (typeof state.wizard.data[key] !== "undefined") {
          let opt = options.find((co) => co.value == key_value)
          if (!opt && alt_value) {
            opt = options.find((co) => co.value == alt_value)
          }
          if (opt) {
            yield put(updateChoice(key, opt))
          } else if (defOpt) {
            yield put(updateChoice(key, defOpt))
          }
          // Update engraving disable in wizard & choices
          // e.g., JINT0002 with infinite cshank
          if (
            (typeof opt?.num_outside_engravings !== "undefined" ||
              typeof defOpt?.num_outside_engravings !== "undefined") &&
            typeof state.wizard.data.ef !== "undefined"
          ) {
            let updates = []
            let num_engravings =
              opt?.num_outside_engravings || defOpt?.num_outside_engravings
            for (let i = 0; i < state.wizard.data.ef.engravings.length; i++) {
              const engraving = state.wizard.data.ef.engravings[i]
              if (engraving.engraving_num != 0) {
                let engravingData = engraving
                if (engraving.engraving_num <= num_engravings) {
                  engravingData.disabled = false
                } else {
                  engravingData.disabled = true
                }
                updates.push(put(setWizardData(engravingData)))
              }
            }
            yield all(updates)
          }
        }
        break
      // case 'sku':
      //   $("."+key+"_pane li[data-value='"+key_value+"']").trigger("click");
      //   break;
      // case 'm2':
      // case 'm3':
      //   var valid_options = $("."+key+"_pane li[data-value='"+key_value+"']").filter(function() { return $(this).css("display") !== "none" });
      //   var all_options = $("."+key+"_pane li").filter(function() { return $(this).css("display") !== "none" });
      //   if (valid_options.size > 0) {
      //     valid_options.first().trigger('click');
      //   } else if (all_options.size > 0) {
      //     all_options.first().click();
      //   } else {
      //     $("."+key+"_pane li[data-value='"+key_value+"']").first().trigger('click');
      //   }
      //   break;
      case "s1":
      case "s2":
      case "s3":
      case "s4":
      case "s5":
      case "s6":
      case "s7":
      case "s8":
      case "s9":
      case "s10":
      case "s11":
      case "s12":
      case "s13":
      case "s14":
      case "s15":
      case "s16":
      case "face1_s1":
      case "face1_s2":
      case "face2_s1":
      case "face2_s2": {
        var stoneCodeArray = mapStoneCodeToSimilarStoneCode(key_value)
        const checkSkip =
          state.choices && state.choices[key] && state.choices[key].skipInit
        let validChoice = null
        if (stoneCodeArray === undefined && stoneCodeArray != "") {
          if (typeof state.wizard.data[key] !== "undefined") {
            if (!checkSkip) {
              validChoice = options.find((st) => st.value == key_value)
              if (validChoice) {
                yield put(updateChoice(key, validChoice))
              } else if (defOpt) {
                yield put(updateChoice(key, defOpt))
              }
            }
          }
        } else {
          let sChoice = null
          for (var j = 0, len = stoneCodeArray.length; j < len; j++) {
            if (typeof state.wizard.data[key] !== "undefined" && options) {
              sChoice =
                options.find((st) => st.value == stoneCodeArray[j]) || sChoice
            }
          }
          if (sChoice && !checkSkip) {
            yield put(updateChoice(key, sChoice))
          } else if (alt_value && !checkSkip) {
            stoneCodeArray = mapStoneCodeToSimilarStoneCode(alt_value)
            if (stoneCodeArray === undefined && stoneCodeArray == "") {
              sChoice = options.find((st) => st.value == alt_value)
              if (sChoice) {
                yield put(updateChoice(key, sChoice))
              } else if (defOpt) {
                yield put(updateChoice(key, defOpt))
              }
            } else {
              let len = stoneCodeArray.length
              for (var sc = 0; sc < len; sc++) {
                if (typeof state.wizard.data[key] !== "undefined" && options) {
                  sChoice =
                    options.find((st) => st.value == stoneCodeArray[sc]) ||
                    sChoice
                }
              }
              if (sChoice) {
                yield put(updateChoice(key, sChoice))
              } else if (defOpt) {
                yield put(updateChoice(key, defOpt))
              }
            }
          } else if (defOpt && !checkSkip) {
            yield put(updateChoice(key, defOpt))
          }
        }
        break
      }
      case "ef":
      case "ef2":
        // $("."+key+"_pane li[data-value='"+key_value+"']").trigger("click");
        if (state.wizard.data[key] && options) {
          let efChoice = options.find((el) => {
            return el.value == key_value
          })
          if (!efChoice && alt_value) {
            efChoice = options.find((el) => el.value == alt_value)
          }
          if (!efChoice && options.length > 0) {
            efChoice = options[0]
          }
          yield put(updateChoice(key, efChoice))
        }

        // initial load: ef/ef2, add wizard data (e2,3,4) based on choices
        if (
          !state.wizardUI.prefStore &&
          state.wizard.data[key] &&
          state.wizard.data[key].engravings
        ) {
          let updates = []
          const validateChoices = JSON.parse(JSON.stringify(state.choices))
          const engravings = state.wizard.data[key].engravings
          engravings.forEach((engraving) => {
            if (Object.keys(engraving.filters).length > 0) {
              const subFilterKeys = Object.keys(engraving.filters)
              const validKeys = subFilterKeys.filter(
                (sfkey) =>
                  !!engraving.filters[sfkey].find(
                    (r) =>
                      state.choices[sfkey] &&
                      filterMatch(r, validateChoices[sfkey].value)
                  )
              )
              if (subFilterKeys.length == validKeys.length) {
                updates.push(put(setWizardData(engraving)))
              }
            }
          })
          yield all(updates)
        }

        break
      case "e0":
      case "e1":
      case "e2":
      case "e3":
      case "e4":
      case "e5":
      case "e6":
      case "e7":
      case "e8":
      case "e9":
      case "e10":
      case "e11":
      case "e12": {
        // $('input.wizard_input_'+key).val(key_value);
        // limitChars($('input.wizard_input_'+key));
        // $('input.wizard_input_'+key).trigger("change").trigger('blur');
        let engraving = Object.assign({}, state.wizard.data[key], {
          value: key_value || "",
        })
        limitChars(engraving, null, appStoreV2.dispatch)
        // productStore.dispatch(updateChoice(key, Object.assign({}, state.wizard.data[key], {value: key_value})));
        break
      }
      case "box": {
        if (state.wizardUI.nobox && state.wizard.data.box) {
          let opt = appStore
            .getState()
            .wizard.data.box.options.find(
              (opt) => opt.selected_type == "default"
            )
          yield put(updateChoice("box", opt))
        } else if (state.wizard.data.box) {
          if (key_value.startsWith("user_uploads/")) {
            let opt = appStore
              .getState()
              .wizard.data.box.options.find(
                (opt) => opt.selected_type == "photo"
              )
            if (opt) {
              opt.value = opt.photo_value = key_value
              yield put(updateChoice("box", opt))
            }
          } else if (key_value.length < 1) {
            let opt = appStore
              .getState()
              .wizard.data.box.options.find(
                (opt) => opt.selected_type == "default"
              )
            yield put(updateChoice("box", opt))
          } else {
            let opt = appStore
              .getState()
              .wizard.data.box.options.find(
                (opt) => opt.selected_type == "design"
              )
            if (opt) {
              opt.value = opt.design_value = key_value
              yield put(updateChoice("box", opt))
            }
          }
        }
        break
      }
      case "img1":
      case "img2":
        // We pull the parameter directly from the cookie because we only want
        // user params to show on product pages

        if (state.wizard.adminMode) {
          // admin mode: cache the uploaded image to current cropper aspect
          // prevent the sku switcher, use the same image in different sku
          imageCropper = state.wizard.data[key].cropper
          if (imageCropper && key_value) {
            checkAdminPrefCached = getPref("admin_pref_" + key)
            if (typeof checkAdminPrefCached === "undefined") {
              storePref("admin_pref_" + key, "1")
              storePref(
                "admin_" +
                  key +
                  "_" +
                  imageCropper.aspect_ratio_width +
                  "_" +
                  imageCropper.aspect_ratio_height,
                key_value
              )
            }
          }
        } else {
          imageCropper = state.wizard.data[key].cropper
          handwriting_engraving = state.wizard.data[key].handwriting_engraving
          if (imageCropper) {
            uploadedUrl = key_value || alt_value || ""
            threshold_value = handwriting_engraving
              ? getPref(
                  key +
                    "_" +
                    "th" +
                    "_" +
                    imageCropper.aspect_ratio_width +
                    "_" +
                    imageCropper.aspect_ratio_height
                ) || thresholdSettings.MEDIUM
              : undefined
            if (threshold_value) threshold_value = Number(threshold_value)
            yield put(
              updateChoice(key, { threshold_value, value: uploadedUrl })
            )
          } else {
            // image option is disabled while initialized
            yield put(updateChoice(key, { value: "" }))
          }
        }
        // var uParams = Cookies.getJSON("userPrefs")
        // var desiredParam =
        //   "img1_" +
        //   $("#img1_upload").data("crop-width") +
        //   "_" +
        //   $("#img1_upload").data("crop-height")
        // $("#img1_description_input").val(key_value)
        // if (uParams && uParams[desiredParam] && uParams[desiredParam] != "") {
        //   $("#img1_upload_modal_btn").hide()
        //   $("#img1_start_over_btn").removeAttr("hidden")
        //   $(".img1-upload-pane").addClass("hidden")
        //   $(".img1-preview-pane").removeClass("hidden")
        // } else {
        //   // Upload btn animation for after page is loaded and no image upload exists
        //   $(".js-pendant-upload-btn").removeClass("hide")
        //   setTimeout(function() {
        //     $(".js-pendant-upload-btn").addClass("expand")
        //     setTimeout(function() {
        //       $(".js-pendant-upload-btn").text("Upload")
        //     }, 1800)
        //   }, 2000)
        // }
        break
      default:
        //alert(key + ":" + key_value);
        break
    }
  }
  // Init diamond selector to show final diamond if all 4Cs were previously selected
  yield put(updateDiamond("init_diamond", { startOver: jluxStartOver }))
  // Needs to be called last so hidden stone values are initialized properly.
  // $(".sc_pane .wizard-option").trigger("change")

  // Remove disabled properties from add to cart buttons to ensure proper values
  // $(".js-add-to-cart-btn").prop("disabled", false)
  // $("#wizard_accordion").removeClass("fs-ignore-rage-clicks")
  // Call the AJAX for loading preview images before doc.ready
  // global_store_pref_flag = true
  yield put(activatePrefStore())
  // product_defaults.init = true;
  // if ($('#nameplate_preview').is('*') && typeof Cookies.get('nameplate_poc') === 'undefined') {
  //   setTimeout(function() {
  //     Cookies.set('nameplate_poc', true);
  //     var name = window.prompt("Enter your name!", "");
  //     $('.wizard_input_e1').val(name).trigger('change');
  //   }, 2000);
  // }

  if (!isTestMode) {
    dataLayer.push({
      event: "product-wizard-initialized",
    })
  }

  // GTM Event to add Tangiblee tag
  if (!window.tangiblee) {
    dataLayer.push({
      event: "product-wizard-initial-images-loaded",
    })
  }

  perfMark("product:init_wizard:finish")
}
