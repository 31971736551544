const countries = [
  {
    country_name: "USA",
    currency_code: "USD",
    full_country_name: "United States",
    geo: "US",
    human_country: "US",
    locale: "en-US",
    site_country: "us",
    url: "jewlr.com",
  },
  {
    country_name: "Canada",
    currency_code: "CAD",
    full_country_name: "Canada",
    geo: "CA",
    human_country: "CA",
    locale: "en-CA",
    site_country: "ca",
    url: "jewlr.com/ca",
  },
  {
    country_name: "Australia",
    currency_code: "AUD",
    full_country_name: "Australia",
    geo: "AU",
    human_country: "AU",
    locale: "en-AU",
    site_country: "au",
    url: "jewlr.com/au",
  },
  {
    country_name: "United Kingdom",
    currency_code: "GBP",
    full_country_name: "United Kingdom",
    geo: "GB",
    human_country: "UK",
    locale: "en-GB",
    site_country: "uk",
    url: "jewlr.com/uk",
  },
]

export default countries
