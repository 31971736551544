import { call, select } from "redux-saga/effects"

import api from "apis"

export default function* subscribeNotification({
  actions: { onError, onSuccess },
  email,
}) {
  try {
    const state = yield select()
    const styleCode = state.choices?.style_code?.value

    if (!styleCode) return

    const choices = Object.entries(state.choices || {}).reduce(
      (data, [name, choice]) => {
        if (choice.disabled) {
          return data
        } else {
          return {
            ...data,
            [name]: choice.is_default ? "" : choice.value || "",
          }
        }
      },
      {}
    )

    const result = yield call(
      api.post,
      `/api/v10/products/${styleCode}/subscribe`,
      {
        choices,
        email,
      }
    )

    if (result) {
      onSuccess()
    }
  } catch (err) {
    onError(err)
    console.log(err)
  }
}
