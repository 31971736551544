import PropTypes from "prop-types"
import { useLayoutEffect, useRef } from "react"
import { useHistory, useLocation } from "react-router-dom"

const ResetFocus = ({ navTopRef }) => {
  const history = useHistory()
  const location = useLocation()
  const prevPathname = useRef()

  if (typeof window !== "undefined") {
    useLayoutEffect(() => {
      // On path change, reset focus to the top
      if (
        navTopRef.current &&
        history.action !== "REPLACE" &&
        prevPathname.current !== location.pathname
      ) {
        prevPathname.current = location.pathname
        navTopRef.current.focus()
      }
    }, [navTopRef.current, history.action, location.pathname])
  }

  return null
}

ResetFocus.propTypes = {
  navTopRef: PropTypes.object,
}

export default ResetFocus
