import Cookies from "js-cookie"

import { history } from "app-store-v2"
import { isTestMode } from "helpers/application"

const COLLECTIONS_CLICKED_ON_HISTORY_STORAGE =
  "COLLECTIONS_CLICKED_ON_HISTORY_STORAGE"

export const upsertCollectionStats = (content = {}) => {
  let collectionStats = Cookies.get(sl.config.cookies_config.collectionStats)

  collectionStats = { ...JSON.parse(collectionStats || "{}"), ...content }

  Cookies.set(sl.config.cookies_config.collectionStats, collectionStats, {
    secure: true,
  })
}

export const logCurrentPageInCollectionStats = (path) => {
  // Remove slash from pathname unless it's from homepage '/'
  const logPath = (path || history.location.pathname).replace(/\/(?=\w)/g, "")
  upsertCollectionStats({ cslug: logPath })
}

export const trackSuperFiltersEvent = (currentPath, filterLink) => {
  if (!isTestMode) {
    try {
      dataLayer.push({
        customPagePath: currentPath.replace(/\/(?=\w)/g, ""),
        event: "superfilters-clicked",
        filterLink: filterLink.replace(/\/(?=\w)/g, ""),
      })
    } catch (e) {
      console.log("GTM ERROR: ", e)
    }
  }
}

const readAndParseHistory = () => {
  let parsedHistory
  try {
    const storage = window.localStorage.getItem(
      COLLECTIONS_CLICKED_ON_HISTORY_STORAGE
    )
    parsedHistory = storage ? JSON.parse(storage) : {}
  } catch (error) {
    // LocalStorage not support
    // Using Cookies
    const c_storage = Cookies.get(COLLECTIONS_CLICKED_ON_HISTORY_STORAGE)
    parsedHistory = c_storage ? JSON.parse(c_storage) : {}
  }
  return parsedHistory
}

export const setProductsClickedOnHistory = (styleCode, data) => {
  const history = readAndParseHistory()
  history[styleCode] = data
  try {
    window.localStorage.setItem(
      COLLECTIONS_CLICKED_ON_HISTORY_STORAGE,
      JSON.stringify(history)
    )
  } catch (error) {
    // LocalStorage not support
    // Using Cookies
    Cookies.set(
      COLLECTIONS_CLICKED_ON_HISTORY_STORAGE,
      JSON.stringify(history),
      { secure: true }
    )
  }
  return readAndParseHistory()
}

export const setClickedOnHistoryCookie = (style_code) => {
  try {
    const style_code_history = readAndParseHistory()[style_code]
    if (style_code_history) {
      upsertCollectionStats(style_code_history)
    }
  } catch (error) {
    console.error(error)
  }
}
