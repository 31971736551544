/* eslint-disable no-case-declarations */
import { combineReducers } from "redux"

import { creditCardCheckoutMethod } from "helpers/checkout/checkout_options"
import { getInitialPanels } from "helpers/checkout/ssr-data-load"

import {
  ERROR_MODAL_OPEN,
  ERROR_MODAL_CLOSE,
  LOAD_ORDER_DATA,
  PANEL_COMPLETE,
  PANEL_OPEN,
  SAVE_KLARNA_CLIENT_TOKEN,
  SET_DELIVERY_METHODS,
  SET_LOADING_ORDER_SUMMARY,
  SET_LOADING_SHIPPING_METHODS,
  SET_LOGIN_CHECKOUT,
  SET_ORDER_SUBMIT_RESULTS,
  SET_PAYMENT_METHOD,
  SET_USER_ON_CHECKOUT_LOGIN,
  TOGGLE_PROCESSING_MODAL,
  UPDATE_ORDER_DATA,
} from "./actions"

function order(
  state = {
    cc_error: null,
    delivery: null,
    error: null,
    error_modal: false,
    loading: true,
    selectedPaymentMethod: null,
  },
  action
) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case SAVE_KLARNA_CLIENT_TOKEN:
      newState.klarna_widget_info = action.klarnaWidgetInfo
      return newState
    case ERROR_MODAL_OPEN:
      newState.error_modal = true
      newState.error = action.error_msg || newState.error
      return newState
    case ERROR_MODAL_CLOSE:
      newState.error_modal = false
      return newState
    case LOAD_ORDER_DATA:
      newState = {
        ...action.data,
        loading: false,
        selectedPaymentMethod: newState.selectedPaymentMethod,
      }
      return newState
    case SET_DELIVERY_METHODS:
      newState.delivery = action.data
      const newShipMethod = action.data.available_ship_methods.filter(
        (sm) => sm.service_type == action.data.current_ship_method
      )
      if (newShipMethod.length > 0) {
        newState.order.shipping_adjustment.description =
          newShipMethod[0].service_label
        newState.order.shipping_adjustment.carrier_label = newShipMethod[0]
          .carrier_label
          ? ` (${newShipMethod[0].carrier_label})`
          : ""
      }
      return newState
    case SET_ORDER_SUBMIT_RESULTS:
      newState.cc_error = action.results.cc_error
      newState.error = action.results.error
      newState.success = action.results.success
      if (action.results.react) {
        newState.order = action.results.react
      }

      return newState
    case SET_PAYMENT_METHOD:
      newState.payment_method = action.paymentMethod
      newState.selectedPaymentMethod = action.paymentMethod
      return newState
    case SET_USER_ON_CHECKOUT_LOGIN:
      newState.order.user = action.user
      if (action.data.order) {
        newState.order = action.data.order
      }
      return newState
    case UPDATE_ORDER_DATA:
      newState.order = action.data.react
      newState.error = action.data.error
      return newState
    default:
      return state
  }
}

const initialLoadingOrderSummary = {
  giftCards: false,
  redemptions: false,
  shipping: false,
}

function form(
  state = {
    ccCheckoutMethod: "stripeCheckout",
    currentStep: 0,
    loadingOrderSummary: initialLoadingOrderSummary,
    loadingShippingMethods: false,
    panels: [],
    showProcessingModal: false,
  },
  action
) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case LOAD_ORDER_DATA:
      const data = action.data
      newState.ccCheckoutMethod = creditCardCheckoutMethod()
      newState.paymentMethod = data.payment_method
      newState.currentStep = 0
      newState.panels = getInitialPanels(data)
      return newState
    case SET_LOADING_SHIPPING_METHODS:
      newState.loadingShippingMethods = action.loading
      return newState
    case SET_LOADING_ORDER_SUMMARY:
      newState.loadingOrderSummary = {
        ...newState.loadingOrderSummary,
        ...action.data,
      }
      return newState
    case SET_DELIVERY_METHODS:
      newState.loadingShippingMethods = false
      return newState
    case SET_LOGIN_CHECKOUT:
      newState.checkoutLogin = true
      return newState
    case PANEL_COMPLETE:
      newState.currentStep = action.forceOpenStep || action.step + 1
      newState.panels = newState.panels.map((panel, index) => ({
        ...panel,
        opened: index === (action.forceOpenStep || action.step + 1),
        ...(index === action.step && {
          complete: true,
          opened: false,
        }),
      }))
      return newState
    case PANEL_OPEN:
      newState.currentStep = action.step
      newState.panels = newState.panels.map((panel, index) => ({
        ...panel,
        opened: index === action.step,
        ...(action.step < index && {
          complete: false,
        }),
      }))
      return newState
    case TOGGLE_PROCESSING_MODAL:
      newState.showProcessingModal = action.show
      return newState
    case UPDATE_ORDER_DATA:
      newState.loadingOrderSummary = initialLoadingOrderSummary
      return newState
    default:
      return state
  }
}

const checkoutApp = combineReducers({
  form,
  order,
})
export default checkoutApp
