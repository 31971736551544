import { push } from "connected-react-router"
import { put, select } from "redux-saga/effects"

import initializeFacebook from "helpers/facebook-initialize"

import { setFacebookLoginStatus, facebookConnect } from "../actions"

const getLoginStatus = () =>
  new Promise((resolve) => {
    initializeFacebook(() => {
      FB.getLoginStatus(resolve)
    })
  })

export default function* facebookLogin() {
  try {
    const state = yield select()
    const routertHash = state.router.location.hash

    const response = yield getLoginStatus()
    if (response.status === "connected") {
      if (routertHash === "#referral") {
        yield put(push("/"))
      }
      yield put(facebookConnect(response))
    } else {
      yield put(setFacebookLoginStatus(response))
    }
  } catch (error) {
    console.error("Failed to authenticate", error)
  }
}
