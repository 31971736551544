import { select } from "redux-saga/effects"

import { isTestMode } from "helpers/application"

let debouncePrefs = {}

const isEngravingField = (pref) => {
  var regExp = RegExp("e[0-9][0-9]?")
  return regExp.test(pref)
}

export default function* recordPersonalizationEvent(action) {
  const state = yield select()

  // We can't send any personalization events while there is no style code
  // to track
  if (!state.choices.style_code) {
    return
  }

  if (!isTestMode) {
    switch (action.type) {
      case "UPDATE_CHOICE":
        if (action.fromUser) {
          if (isEngravingField(action.pref)) {
            if (!debouncePrefs.engravings) {
              dataLayer.push({
                event: "entered-product-engraving",
                styleCode: state.choices.style_code?.value,
              })
              debouncePrefs.engravings = true
            }
          } else {
            dataLayer.push({
              event: "chose-product-option",
              optionName: action.pref,
              optionValue: action.data.value,
              styleCode: state.choices.style_code?.value,
            })
          }
        }
        break
      case "TOGGLE_SIZE_GUIDE":
        dataLayer.push({
          event: "opened-product-size-guide",
          styleCode: state.choices.style_code?.value,
        })
        break
      case "TOGGLE_PANEL_INDEX":
        dataLayer.push({
          event: "opened-product-panel",
          optionName: action.panel,
          styleCode: state.choices.style_code?.value,
        })
        break
    }
  }
}
