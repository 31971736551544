import { replace } from "connected-react-router"
import { call, cancelled, put, select } from "redux-saga/effects"

import api from "apis"

import {
  errorModalOpen,
  orderCompleted,
  setOrderSubmitResults,
  updateOrderData,
} from "../actions"

export default function* submitOrder({
  actions: { onError, onSuccess },
  values,
}) {
  const state = yield select()
  const payment_method = state.checkout.order.payment_method

  let orderToken = state.checkout.order.order.token

  if (payment_method == "affirm" || payment_method == "financing") {
    try {
      // We used to get the order values from the API - but we actually can just load it all from the store
      let currentLink = window.location.href
      let confirmationUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        `${sl.basename}/api/v10/checkout/affirm_complete?order_token=${orderToken}`

      if (currentLink.indexOf("_ga") > -1) {
        var ga = currentLink.substring(currentLink.indexOf("_ga"))
        ga = ga.split("&")[0]
        confirmationUrl += "&" + ga
      }

      const order = state.checkout.order.order
      const delivery = state.checkout.order.delivery
      const ship_method = delivery.available_ship_methods.find(
        (sm) => sm.service_type == values.ship_method_id
      )
      let checkoutRequest = {
        currency: order.currency,
        merchant: {
          name: sl.config.site_title,
          user_cancel_url:
            (currentLink.indexOf("token=true") > -1
              ? currentLink
              : currentLink + "&token=true") + "&payment_method=financing",
          user_confirmation_url: confirmationUrl,
        },
        order_id: order.number,
        phone_number:
          values.order.customer_phone ||
          values.order.ship_address_attributes.phone,
        shipping: {
          address: {
            city: values.order.ship_address_attributes.city,
            country: values.order.ship_address_attributes.country,
            line1: values.order.ship_address_attributes.address1,
            line2: values.order.ship_address_attributes.address2,
            state: values.order.ship_address_attributes.state,
            zipcode: values.order.ship_address_attributes.postal_code,
          },
          email: values.order.email,
          name: {
            first: values.order.ship_address_attributes.first_name,
            last: values.order.ship_address_attributes.last_name,
          },
        },
        shipping_amount: Math.round(
          parseFloat(ship_method.shipping_charge) * 100
        ),

        tax_amount: Math.round(
          parseFloat(order.tax_adjustment?.amount || 0) * 100
        ),
        total: Math.round(parseFloat(order.balance) * 100),
      }

      checkoutRequest.items = []
      for (i in order.line_items) {
        var item = {
          display_name: order.line_items[i].title,
          item_image_url: order.line_items[i].image_url,
          item_url:
            state.checkout.order.site_url +
            "/" +
            order.line_items[i].product_url,
          qty: 1,
          sku: order.line_items[i].item_shorthand,
          unit_price: Math.round(parseFloat(order.line_items[i].price) * 100),
        }
        checkoutRequest.items.push(item)
      }

      checkoutRequest.discounts = {}
      if (order.coupon_adjustment.label) {
        checkoutRequest.discounts.coupon = {
          discount_amount: Math.abs(
            Math.round(parseFloat(order.totals_summary.promo_code.amount) * 100)
          ),
          discount_display_name: order.coupon_adjustment.label,
        }
      }

      if (order.totals.discount < 0) {
        checkoutRequest.discounts["Multi-item Discount"] = {
          discount_amount: Math.abs(
            Math.round(parseFloat(order.totals.discount) * 100)
          ),
          discount_display_name: "Buy More Save More",
        }
      }

      for (var i = 1; i <= order.giftcards.length; i++) {
        checkoutRequest.discounts["gift_card_" + i] = {
          discount_amount: Math.abs(
            Math.round(parseFloat(order.giftcards[i - 1].amount) * 100)
          ),
          discount_display_name: "Gift Card " + i,
        }
      }

      checkoutRequest.metadata = {
        shipping_type: order.shipping_adjustment.description,
      }

      window.affirm.checkout(checkoutRequest)
      window.affirm.ui.ready(() => {
        window.affirm.ui.error.on("close", () => {
          onSuccess()
        })
      })
      window.affirm.checkout.post()
    } catch (e) {
      console.log("Affirm Checkout Error", e)
      const message =
        "There was an error proceeding to Affirm. Please try again or try completing your payment by credit card instead."
      yield put(
        setOrderSubmitResults({
          error: message,
        })
      )
      // Open error modal in mobile
      if (state.browser.is.mobile) {
        yield put(errorModalOpen())
      }
      onError(message)
    }
  } else if (payment_method == "paypal") {
    window.location.href = sl.basename + "/api/v10/checkout/paypal_express"
  } else if (payment_method === "klarna") {
    const { order, klarna_widget_info } = state.checkout.order
    const { customer, ship_address } = order

    const shipping_address = {
      city: ship_address.city,
      country: ship_address.country,
      email: customer.email,
      family_name: ship_address.last_name,
      given_name: ship_address.first_name,
      phone: customer.phone,
      postal_code: ship_address.postal_code,
      street_address: ship_address.address1,
      street_address2: ship_address.address2,
    }

    if (ship_address.state) {
      shipping_address.region = ship_address.state
    }

    const payload = {
      shipping_address,
    }

    const { authorize } = yield authorizeKlarna(klarna_widget_info, payload)

    const isAuthorized =
      authorize.authorization_token && authorize.approved && authorize.show_form

    if (isAuthorized) {
      const checkoutUrl = "/secure/api/checkout/klarna_charge"

      try {
        const response = yield api.post(checkoutUrl, {
          order_token: orderToken,
          token: authorize.authorization_token,
          user_agent: navigator.userAgent,
        })

        if (response.error) {
          const message =
            "Klarna has rejected your request. Please go back to the cart and select a different payment method."

          // Should show error only when coming from jewlrorders api on capture
          yield put(
            setOrderSubmitResults({
              cc_error: message,
            })
          )
          onError(message)
          return
        }

        if (response.redirect_url) {
          window.location = response.redirect_url
        }
      } catch (err) {
        console.error("Error Submiting Order", err)
        onError(err)
        return
      }
    }

    onSuccess()
  } else {
    let url = "/checkout.json"
    try {
      const result = yield call(api.put, url, values)
      if (result) {
        if (result.redirect) {
          window.location = result.redirect
        } else if (result.redirect_url) {
          try {
            yield put(orderCompleted())
            yield put(updateOrderData(result))
            yield put(replace(result.redirect_url))
            window.scrollTo(0, 0)
          } catch (e) {
            console.error("Submit Order Redirect Failed", e)
            throw e
          }
        } else if (result.error_redirect_url) {
          window.location.href = result.error_redirect_url
        } else {
          try {
            yield put(setOrderSubmitResults(result))
            if (result.cc_error) {
              onError(result.cc_error)

              // Open error modal in mobile
              if (state.browser.is.mobile) {
                yield put(errorModalOpen())
              }
            }
          } catch (e) {
            console.error("Submit Order CC Error Failed", e)
          }
        }

        onSuccess()
      }
    } catch (e) {
      console.error("Error Updating Checkout", e)
      // This is usually a network error, but the payment may have gone through on the server still
      // Therefore, we wait for 10 seconds and then reload the entire page. If the payment went through,
      // the customer should be redirected to the thank-you page
      setTimeout(() => {
        window.location.reload()
      }, 10000)
    } finally {
      if (yield cancelled()) {
        console.error("Order Processing Cancelled")
      }
    }
  }
}

function authorizeKlarna(klarna_widget_info, payload) {
  return new Promise((resolve) => {
    window?.Klarna.Payments.load(klarna_widget_info, (loadResponse) => {
      window?.Klarna.Payments.authorize(
        {
          payment_method_category: klarna_widget_info.payment_method_category,
        },
        payload,
        (authorizeResponse) => {
          const responses = {
            authorize: authorizeResponse,
            load: loadResponse,
          }

          resolve(responses)
        }
      )
    })
  })
}
