import { all, debounce, takeLatest, takeLeading } from "redux-saga/effects"

import addUserAddress from "./sagas/add-user-address"
import displayUserSearchHistory from "./sagas/display-user-search-history"
import doLogin from "./sagas/do-login"
import doLogout from "./sagas/do-logout"
import facebookConnect from "./sagas/facebook-connect"
import facebookLogin from "./sagas/facebook-login"
import fetchCartList from "./sagas/fetch-cart-list"
import fetchLoginResult from "./sagas/fetch-login-result"
import fetchReferralName from "./sagas/fetch-referral-name"
import fetchSearchSuggestions from "./sagas/fetch-search-suggestions"
import fetchTrackingInfo from "./sagas/fetch-tracking-info"
import forgotPassword from "./sagas/forgot-password"
import handleFacebookStatus from "./sagas/handle-facebook-status"
import handleRouteLocation from "./sagas/handle-route-location"
import removeFromCart from "./sagas/remove-from-cart"
import removeUserAddress from "./sagas/remove-user-address"
import resetPassword from "./sagas/reset-password"
import saveSearchHistory from "./sagas/save-search-history"
import sendUserInfo from "./sagas/send-user-info"
import setCookieLoginStatus from "./sagas/set-cookie-login-status"
import setExperiment from "./sagas/set-experiment"
import submitRegistration from "./sagas/submit-registration"
import switchCountry from "./sagas/switch-country"
import updateUserAddress from "./sagas/update-user-address"

function* appSagas() {
  yield all([
    takeLeading("FETCH_CART_LIST", fetchCartList),
    takeLatest("FETCH_LOGIN_RESULT", fetchLoginResult),
    takeLatest("FETCH_REFERRAL_NAME", fetchReferralName),
    takeLatest("REMOVE_FROM_CART", removeFromCart),
    debounce(1000, "FETCH_SEARCH_SUGGESTIONS", fetchSearchSuggestions),
    takeLatest("SEND_USER_INFO", sendUserInfo),
    takeLatest("DO_LOGIN", doLogin),
    takeLatest("DO_LOGOUT", doLogout),
    takeLatest("FORGOT_PASSWORD", forgotPassword),
    takeLatest("RESET_PASSWORD", resetPassword),
    takeLatest("SET_FACEBOOK_LOGIN_STATUS", handleFacebookStatus),
    takeLatest("SET_COOKIE_LOGIN_STATUS", setCookieLoginStatus),
    takeLatest("FACEBOOK_CONNECT", facebookConnect),
    takeLatest("FACEBOOK_LOGIN", facebookLogin),
    takeLatest("SUBMIT_REGISTRATION", submitRegistration),
    takeLatest("@@router/LOCATION_CHANGE", handleRouteLocation),
    takeLatest("FETCH_TRACKING_INFO", fetchTrackingInfo),
    takeLatest("SAVE_USER_SEARCH_HISTORY", saveSearchHistory),
    takeLatest("DISPLAY_USER_SEARCH_HISTORY", displayUserSearchHistory),
    takeLatest("ADD_USER_ADDRESS", addUserAddress),
    takeLatest("REMOVE_USER_ADDRESS", removeUserAddress),
    takeLatest("UPDATE_USER_ADDRESS", updateUserAddress),
    takeLatest("SET_EXPERIMENT", setExperiment),
    takeLatest("SWITCH_COUNTRY", switchCountry),
  ])
}

export default appSagas
