import { call, put, select } from "redux-saga/effects"

import api from "apis"
import { setExperiment } from "app-store-v2/app/actions"
import { updateShipping } from "areas/product/store/actions"

export default function* checkAvailability(action) {
  const state = yield select()
  const choices = action.data.choices
  if (!choices || !choices.style_code || !choices.style_code.value) return
  let url = buildUrlFromUserChoices(action.data)
  try {
    const result = yield call(api.get, url)
    if (result?.data) {
      yield put(updateShipping(result.data.react_ship_info))
      const freeShippingExp = state?.experiment?.freeShipping
      if (
        freeShippingExp &&
        result?.data?.react_ship_info?.main?.run_free_shipping_test
      ) {
        yield put(setExperiment({ freeShipping: freeShippingExp }))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

function buildUrlFromUserChoices(state) {
  const choices = state.choices
  let params = new URLSearchParams()
  Object.keys(choices)
    .filter((key) => !choices[key].disabled)
    .forEach((key) => {
      const choice = choices[key]
      params.append(key, encodeURIComponent(choice.value || ""))
    })
  if (state.wizardUI.today) {
    params.append("today", state.wizardUI.today)
  }
  if (state.wizardUI.time) {
    params.append("time", state.wizardUI.time)
  }
  params.append("adminMode", state.wizard.adminMode)
  return `/api/v10/products/${
    choices.style_code.value
  }/check_availability?${params.toString()}`
}
