import { call, put, select } from "redux-saga/effects"

import api from "apis"
import { openModal } from "components/modal"

import { updateInventory } from "../actions"

export default function* fetchInventory(action) {
  const state = yield select()

  let params = new URLSearchParams()
  Object.keys(state.choices).forEach((key) => {
    if (!state.choices[key].disabled) {
      params.append(key, state.choices[key].value)
    }
  })
  if (state.wizardUI.today) {
    params.append("today", state.wizard.today)
  }
  if (action.options.get_all_sizes) {
    params.append("get_all_sizes", true)
  }

  const url = `/products/${
    state.choices.style_code?.value
  }/pop_check_inventory?${params.toString()}`
  let data = yield call(api.getNoCache, url)
  yield put(updateInventory(data))

  if (location.hash === "" && !location.hash.includes("#inventory")) {
    openModal("#inventory")
  }
}
