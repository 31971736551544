import Cookies from "js-cookie"

import api from "apis"
import { isTestMode } from "helpers/application"

export default function* resetPassword({
  actions: { onError, onSuccess },
  values,
}) {
  try {
    let params = {
      ...values,
      token: Cookies.get("password_reset_token"),
    }
    if (isTestMode) {
      params.test_mode = true
    }

    const result = yield api.post("/secure/api/v10/user/reset_password", params)

    if (result.success) {
      onSuccess()
    }
  } catch (err) {
    onError(err)
    console.log(err)
  }
}
