import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"

import {
  activateMenu,
  deactivateMenu,
  activateAccountMenu,
  deactivateAccountMenu,
  deactivateSubmenu,
  setHeaderDropdown,
} from "app-store-v2/app/actions"
import { openModal } from "components/modal"

export default function withHeader(WrappedComponent) {
  const InternalWithHeader = (props) => {
    return <WrappedComponent {...props} />
  }

  const mapStateToProps = (state, ownProps) => {
    return {
      loggedIn: Object.keys(state.user.jewlrorders).length > 0,
      showAccountMenu: state.menu.showAccountMenu,
      showDropdown: state.header.dropdown === ownProps.dropdown,
      showMenu: state.menu.showMenu,
      showSubmenu: state.menu.showSubmenu,
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      closeMenu: () => {
        dispatch(deactivateMenu())
        dispatch(deactivateSubmenu())
      },
      dispatch,
      toggleMenu: (show) => {
        if (show) {
          dispatch(deactivateMenu())
          dispatch(deactivateSubmenu())
        } else {
          dispatch(activateMenu())
          dispatch(deactivateAccountMenu())
        }
        dispatch(setHeaderDropdown(""))
      },
    }
  }

  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { loggedIn } = stateProps
    const { dispatch } = dispatchProps

    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      toggleAccountMenu: (accountShow) => {
        if (accountShow) {
          dispatch(deactivateAccountMenu())
        } else {
          if (loggedIn) {
            dispatch(activateAccountMenu())
          } else {
            openModal("#authenticate")
          }
        }
        dispatch(deactivateMenu())
        dispatch(setHeaderDropdown(""))
      },
    }
  }

  InternalWithHeader.propTypes = {
    closeMenu: PropTypes.func,
    showDropdown: PropTypes.bool,
    toggleAccountMenu: PropTypes.func,
    toggleMenu: PropTypes.func,
  }

  const WithHeader = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(InternalWithHeader)

  return WithHeader
}
