import { put, select } from "redux-saga/effects"

import { fetchWishList } from "app-store-v2/wishlist/actions"
import { initCart } from "areas/cart/store/actions"
import { validateLocalStorage } from "helpers/application"

import { setUserData } from "../actions"

// Parses the user_obj siting the local storage and sets wishlist
// and cart details.
export default function* setCookieLoginStatus() {
  const state = yield select()
  try {
    const parsedUser = JSON.parse(window.localStorage.getItem("user_obj"))
    const user = validateLocalStorage(parsedUser, "user_obj")

    yield put(setUserData(user))
    if (state.user.jewlrorders.guid_token) {
      if (state.router.location.pathname !== "/cart") {
        yield put(initCart())
      }
      yield put(fetchWishList(true))
    }
  } catch (ex) {
    console.error("Exception", ex)
  }
}
