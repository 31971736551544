import Cookies from "js-cookie"
import { call, put } from "redux-saga/effects"

import api from "apis"
import {
  setWishList,
  fetchWishList,
  setWishlistLoading,
} from "app-store-v2/wishlist/actions"
import { confirmAddToWishlist } from "areas/collection/store/actions"

export default function* removeWishlist(action) {
  const wishlistToken = Cookies.get(sl.config.cookies_config.wishlist_token)

  let url = `/api/v10/wishlist/${wishlistToken}/remove/${action.style_code}`

  try {
    yield put(setWishlistLoading(true))

    const result = yield call(api.delete, url)
    // Update wishlist dropdown in header
    if (result) {
      yield put(setWishList(result.data))
      yield put(confirmAddToWishlist(true))
    }
  } catch (e) {
    console.error(e)
    if (typeof window?.Raven !== "undefined") {
      window.Raven.captureException(e)
    }
  }
  yield put(fetchWishList())
  yield put(setWishlistLoading(false))
}
