import { put, select } from "redux-saga/effects"

import { storePref } from "helpers/user-prefs"

import {
  updateChoice,
  updateDiamond,
  removeChooseGemstoneStep,
} from "../actions"

export default function* diamondStartOver() {
  let state = yield select()

  yield put(
    updateDiamond("d_stone_type", state?.diamondSelector?.d_stone_type?.[0])
  )
  yield put(removeChooseGemstoneStep())

  yield put(
    updateChoice("s1", {
      display_price: null,
      price: null,
      value: null,
    })
  )

  storePref("d_shape", null)
  storePref("d_carat", null)
  storePref("d_cut", null)
  storePref("d_color", null)
  storePref("d_clarity", null)
  storePref("d_stone_type", null)
  storePref("s1", null)
}
