import { call, put, select } from "redux-saga/effects"

import api from "apis"
import { setLocalStorage } from "helpers/application"

import { setUserAddresses } from "../actions"

export default function* removeUserAddress({
  actions: { onError, onSuccess },
  address,
}) {
  const state = yield select()

  try {
    const userToken = state.user?.jewlrorders?.guid_token
    const url =
      `/secure/api/v10/user/address?guid_token=${userToken}` +
      `&country=${sl.config.site_country.toLowerCase()}&id=${address.id}`

    const response = yield call(api.delete, url)

    if (response?.success && response.data) {
      yield put(setUserAddresses(response.data.address_list))
      setLocalStorage(state.user.jewlrorders, "user_obj")
      onSuccess(response.data.address_list)
    }
  } catch (err) {
    onError(err?.errors?.[0])
    console.log(err)
  }
}
