import { put, select } from "redux-saga/effects"

import { history } from "app-store-v2/index"

import { fetchReferralName } from "../actions"

export default function* handleRouteLocation(action) {
  const state = yield select()
  const { location } = action.payload
  const params = new URLSearchParams(location.search)

  if (params.get("ref") && !state.user.referralName) {
    yield put(fetchReferralName(params.get("ref")))
    params.delete("ref")
    history.replace({ search: params.toString() })
  }
}
