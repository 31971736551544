export const SET_HEADER_DROPDOWN = "SET_HEADER_DROPDOWN"
export const REMOVE_FROM_CART = "REMOVE_FROM_CART"
export const FETCH_CART_LIST = "FETCH_CART_LIST"
export const FETCH_CART_LIST_CHECKOUT = "FETCH_CART_LIST_CHECKOUT"

export function fetchCartListCheckout() {
  return { type: FETCH_CART_LIST_CHECKOUT }
}
export function setHeaderDropdown(dropdownName) {
  return { dropdownName, type: SET_HEADER_DROPDOWN }
}
export function fetchCartList() {
  return { type: FETCH_CART_LIST }
}
export function removeFromCart(id, isAddon) {
  return { id, isAddon, type: REMOVE_FROM_CART }
}
