import Cookies from "js-cookie"
import { call, put, select } from "redux-saga/effects"

import api from "apis"
import { setCart } from "areas/cart/store/actions"
import { isTestMode } from "helpers/application"

export default function* removeItem(action) {
  try {
    const store = yield select()
    const removedItem = store.cart.line_items.find(
      (item) => item.id == action.payload.id
    )
    const cartToken = Cookies.get(sl.config.cookies_config.cart_token)

    let url = `/api/v10/cart/${cartToken}/remove_item/${action.payload.id}`
    const result = yield call(api.delete, url, "application/json")
    if (result) {
      if (removedItem && !isTestMode) {
        const cartLineItems = result.data.line_items_gtm

        // GTM record event
        dataLayer.push({
          cartLineItems,
          currencyCode: sl.config.currency_code,
          event: "removed-from-cart",
          ga4Items: [
            {
              currency: sl.config.currency_code,
              item_category: removedItem.product_category,
              item_id: removedItem.item_variant,
              item_name: removedItem.style_code_gtm,
              item_variant: removedItem.sku,
              price: removedItem.price,
              quantity: 1,
            },
          ],
          productPrice: removedItem.price,
          removedItem: {
            itemCurrencyCode: removedItem.currency_code,
            itemMetal: removedItem.sku,
            itemPrice: removedItem.price,
            itemRegularPrice: removedItem.regular_price,
            itemShorthand: removedItem.item_shorthand,
            itemStyleCode: removedItem.style_code,
          },
        })
      }
      yield put(setCart(result.data))
    }
  } catch (error) {
    console.log(error)
  }
}
