import Cookies from "js-cookie"
import { call, put } from "redux-saga/effects"

import api from "apis"
import { setWishList, setWishlistLoading } from "app-store-v2/wishlist/actions"
import { confirmAddToWishlist } from "areas/collection/store/actions"

export default function* removeFromWishlist(action) {
  const wishlistToken = Cookies.get(sl.config.cookies_config.wishlist_token)

  if (!wishlistToken) return

  let url = ""
  if (action.id === "all") {
    url = `/api/v10/wishlist/${wishlistToken}/remove_all`
  } else {
    url = `/api/v10/wishlist/${wishlistToken}/remove/${action.id}`
  }

  yield put(setWishlistLoading(true))

  const result = yield call(api.delete, url)

  if (result) {
    yield put(setWishList(result.data))
    yield put(confirmAddToWishlist(true))
  }
  yield put(setWishlistLoading(false))
}
