import { joinNames } from "helpers/name"

export const mapOrderDataToFormValues = (data) => ({
  bill_full_name: joinNames(
    data.order.bill_address?.first_name || data.order.customer?.first_name,
    data.order.bill_address?.last_name || data.order.customer?.last_name
  ),
  cc: {
    cc_expiry_month: data.cc.expiry_month || "",
    cc_expiry_year: data.cc.expiry_year || "",
    cc_number: data.cc.number || "",
    cc_verification_value: data.cc.verification_value || "",
    expiry: "",
  },
  coupon_code: data.coupon_code || "",
  customer_full_name: joinNames(
    data.order.bill_address?.first_name || data.order.customer?.first_name,
    data.order.bill_address?.last_name || data.order.customer?.last_name
  ),
  gift_code: "",
  gift_ids: data.order.line_items.map((li) => li.id),
  gift_message: data.order.line_items.map((li) => li.gift_message),
  gift_package: data.order.line_items.map((li) => li.selected_packaging),
  order: {
    account_create_password: "",
    balance: data.order.balance,
    bill_address_attributes: {
      address1: data.order.bill_address?.address1 || "",
      address2: data.order.bill_address?.address2 || "",
      city: data.order.bill_address?.city || "",
      country: data.order.bill_address?.country || "",
      first_name:
        data.order.bill_address?.first_name ||
        data.order.customer?.first_name ||
        "",
      id: data.order.bill_address?.id,
      last_name:
        data.order.bill_address?.last_name ||
        data.order.customer?.last_name ||
        "",
      phone: data.order.bill_address?.phone || data.order.customer?.phone || "",
      postal_code: data.order.bill_address?.postal_code || "",
      state: data.order.bill_address?.state || "",
    },
    create_account: !!data.order.customer?.create_account,
    customer_first_name:
      data.order.bill_address?.first_name || data.order.customer?.first_name,
    customer_last_name:
      data.order.bill_address?.last_name || data.order.customer?.last_name,
    customer_phone: data.order.customer?.phone || "",
    email: data.order.user
      ? data.order.user.email
      : data.order.customer?.email || "",
    opt_in:
      data.order.customer.opt_in == null
        ? data.order.customer.default_opt_in
        : data.order.customer.opt_in,
    payment_method: data.payment_method,
    same_as_shipping_address_b: data.order.billing_same_as_shipping,
    ship_address_attributes: {
      address1: data.order.ship_address?.address1 || "",
      address2: data.order.ship_address?.address2 || "",
      city: data.order.ship_address?.city || "",
      country: data.order.ship_address?.country || "",
      first_name:
        data.order.ship_address?.first_name ||
        data.order.customer?.first_name ||
        "",
      id: data.order.ship_address?.id,
      last_name:
        data.order.ship_address?.last_name ||
        data.order.customer?.last_name ||
        "",
      phone: data.order.ship_address?.phone || data.order.customer?.phone || "",
      postal_code: data.order.ship_address?.postal_code || "",
      state: data.order.ship_address?.state || "",
    },
    sms_opt_in: !!data.order.customer?.sms_opt_in,
  },
  order_balance: data.order.balance,
  order_token: data.order.token,
  payment_method: data.payment_method,
  paypal_unsuccessful_cart_checkout: data.paypal_unsuccessful ? "true" : null,
  recipient_name: data.order.line_items.map((li) => li.recipient_name),
  reward_amount: data.order.points.redeem_value || "",
  same_as_shipping_address_b: data.order.billing_same_as_shipping,
  ship_full_name: joinNames(
    data.order.ship_address?.first_name || data.order.customer?.first_name,
    data.order.ship_address?.last_name || data.order.customer?.last_name
  ),
  ship_method_id:
    data.order.shipping_adjustment?.label || data.delivery.current_ship_method,
})

export default mapOrderDataToFormValues
