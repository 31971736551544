import Cookies from "js-cookie"
import { call, put, select } from "redux-saga/effects"

import api from "apis"

import { updateShipping } from "../actions"

export default function* getShipEstimate({
  actions: { onError, onSuccess },
  values,
}) {
  try {
    const state = yield select()
    if (!state.wizardUI.prefStore) {
      return
    }

    // GET EST DELIVERY DATE FROM JEWLRORDERS
    var query_string = ""
    for (var pair in values) {
      query_string += pair + "=" + values[pair] + "&"
    }

    let ship_cookie = state.wizard.shipping.estimator.ship_cookie

    let data = yield call(
      api.get,
      sl.config.secure_url + "/apis/estimated_delivery_days?" + query_string
    )
    if (data && data.address) {
      // Set user_postal_code and shiptime cookie
      Cookies.set(
        sl.config.cookies_config["user_postal_code"],
        {
          po_box: data.address.po_box,
          postal_code: data.address.postal_code,
        },
        { secure: true }
      )
    }
    Cookies.set(ship_cookie.name, data, {
      expires: ship_cookie.expiry,
      secure: true,
    })

    // var style_code = state.choices.style_code.value
    // let result = yield call(
    //   api.getNoCache,
    //   "/products/" + style_code + "/product_ship_time"
    // )
    // yield put(updateShipping(result.react_ship_info))

    yield* checkAvailability(state)

    onSuccess()
  } catch (err) {
    console.log(err)
    onError(err?.errors?.[0])
  }
}

function* checkAvailability(state) {
  const choices = state.choices
  if (!choices || !choices.style_code || !choices.style_code.value) return
  let url = buildUrlFromUserChoices(state)
  const result = yield call(api.get, url)
  yield put(updateShipping(result.react_ship_info))
}

function buildUrlFromUserChoices(state) {
  const choices = state.choices
  let params = new URLSearchParams()
  Object.keys(choices)
    .filter((key) => !choices[key].disabled)
    .forEach((key) => {
      const choice = choices[key]
      params.append(key, choice.value || "")
    })
  if (state.wizardUI.today) {
    params.append("today", state.wizard.today)
  }
  return `/products/${
    choices.style_code.value
  }/product_ship_time?${params.toString()}`
}
