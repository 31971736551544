import Cookies from "js-cookie"
import { call, put, select } from "redux-saga/effects"
import URI from "urijs"

import api from "apis"
import { toggleFullLoading } from "app-store-v2/actions"
import countries from "site-data/jewlr/countries"

export default function* switchCountry(action) {
  const { country } = action
  const state = yield select()
  const cart_token = Cookies.get(sl.config.cookies_config.cart_token)
  const url = `/api/v10/cart/${cart_token}/country/${country}`

  yield put(toggleFullLoading(true))

  try {
    const response = yield call(api.get, url)
    if (response?.data?.token) {
      const { cookie_key, token } = response.data
      if (cookie_key && token) {
        Cookies.set(cookie_key, token, { expires: 365, secure: true })
      }
    }
  } catch (error) {
    console.log(error)
  }

  const countryData = countries.find(({ geo }) => geo === country)
  if (countryData) {
    const site_url = sl.config.site_url_by_country[countryData.human_country]
    const { pathname, search, hash } = state.router.location
    const uri = new URI(`${site_url}${pathname}${search}${hash}`)
    uri.addQuery({ geo: country }).removeQuery("locale")
    window.location.href = uri.toString()
  }
}
