import loadable from "@loadable/component"
import { replace } from "connected-react-router"
import { call, put, select } from "redux-saga/effects"

import api from "apis"
import { history } from "app-store-v2/index"
import { retry } from "helpers/application"
import StorageHelper from "helpers/local-storage-cache"
import { storePref } from "helpers/user-prefs"

import { updateChoice } from "../actions"

const cropperJs = loadable.lib(() => retry(() => import("cropperjs")))

function toBlob(tempCanvas, type, quality) {
  return new Promise(function (resolve) {
    tempCanvas.toBlob(
      function (blob) {
        return resolve(blob)
      },
      type,
      quality
    )
  })
}

function cloneCropperImage(pref, cropper) {
  return new Promise((resolve, reject) => {
    // retrieve current cropper info
    const data = cropper.getData()
    const canvasData = cropper.getCanvasData()
    const cropBoxData = cropper.getCropBoxData()
    // build new cropper for original image, image upload
    const image = document.getElementById(`${pref}-original-image`)
    cropperJs
      .load()
      .then((Cropper) => {
        const dummyCropper = new Cropper.default(image, {
          autoCropArea: 1,
          background: false,
          center: false,
          cropBoxMovable: false,
          cropBoxResizable: false,
          data: null,
          dragMode: "move",
          guides: false,
          highlight: false,
          modal: false,
          ready() {
            this.cropper.rotate(data.rotate)
            this.cropper.setData({
              height: data.height,
              width: data.width,
              x: data.x,
              y: data.y,
            })
            this.cropper.setCanvasData(canvasData)
            this.cropper.setCropBoxData(cropBoxData)
            const croppedCloneImg = this.cropper.getCroppedCanvas()
            resolve({
              croppedCloneImg,
              dummyCropper,
            })
          },
          responsive: !Modernizr.touchevent,
          toggleDragModeOnDblclick: false,
          zoomOnTouch: false,
        })
      })
      .catch((e) => reject(e))
  })
}

export default function* cropUploadUserImage(action) {
  let pref = action.pref
  let cropper = action.cropper
  let options = action.options
  const { handwritingEngraving } = options

  const state = yield select()
  if (!state.wizardUI.prefStore) {
    return
  }

  let cropParams = state.wizard.data[pref].cropper

  // Current cropped image
  var croppedImg, cleanupCropper
  if (handwritingEngraving) {
    const { croppedCloneImg, dummyCropper } = yield call(
      cloneCropperImage,
      pref,
      cropper
    )
    croppedImg = croppedCloneImg
    cleanupCropper = dummyCropper
  } else {
    croppedImg = cropper.getCroppedCanvas({ maxHeight: 4096, maxWidth: 4096 })
  }

  // Need to store this croppedImg.width and cropParams.aspect_ratio_width
  // to get proper s7 and imgsmbly resolution in img url for preview
  const resolutionFactor =
    (cropParams.aspect_ratio_width * 2) / croppedImg.width

  // previously resolution was hardcoded for all images and set to 20
  // now we dynamically set resolution based on the cropped image dimensions
  const resolution = 20 / resolutionFactor

  // context of cropped image
  var ctx = croppedImg.getContext("2d")
  // Store the current globalCompositeOperation
  // var compositeOperation = ctx.globalCompositeOperation
  // set to draw behind current content
  ctx.globalCompositeOperation = "destination-over"

  let croppedImageFormat = "image/png"
  if (!cropParams.use_imgsmbly || !cropParams.transparent_img) {
    // White background for JPEG export
    ctx.fillStyle = "#FFFFFF"
    croppedImageFormat = "image/jpeg"
  }

  ctx.fillRect(0, 0, croppedImg.width, croppedImg.height)

  const imgUploader = document.querySelector("#" + pref + "_upload")
  const qualityFactor = pref == "box" ? 0.7 : 1

  let blob = yield call(toBlob, croppedImg, croppedImageFormat, qualityFactor)

  try {
    if (typeof FS !== "undefined") {
      const {
        canvasData,
        containerData,
        cropBoxData,
        imageData,
        initialCanvasData,
        initialCropBoxData,
        initialImageData,
        options: { ready: _, ...restOptions },
        image,
      } = cropper
      FS.event("Upload User Image Data", {
        blob: {
          size: blob.size,
          type: blob.type,
        },
        croppedImg: {
          height: croppedImg.height,
          width: croppedImg.width,
        },
        cropper: {
          canvasData,
          containerData,
          cropBoxData,
          image: {
            naturalHeight: image.naturalHeight,
            naturalWidth: image.naturalWidth,
          },
          imageData,
          initialCanvasData,
          initialCropBoxData,
          initialImageData,
          options: restOptions,
        },
      })
    }
  } catch (exception) {
    console.error("Log FullStory cropImage error", exception)
  }

  var formData = new FormData()
  formData.append("file", blob)
  formData.append("resolution", resolution)

  const uploadButton = document.querySelector("#" + pref + "_upload_button")
  uploadButton.classList.add("uploading")
  uploadButton.innerHTML =
    "Processing... <i class='fas fa-spinner fa-spin'></i>"
  try {
    let response = yield call(api.post, imgUploader.dataset.url, formData, null)
    if (cleanupCropper) cleanupCropper = null

    var cacheKey = `cropper-${pref}-${cropParams.aspect_ratio_width}-${cropParams.aspect_ratio_height}`
    StorageHelper.store(cacheKey, {
      canvasData: cropper.getCanvasData(),
      cropBoxData: cropper.getCropBoxData(),
      data: cropper.getData(),
      naturalWidth: cropper.image.naturalWidth,
      path: response.path,
    })

    // Use the crop width and height to store in user pref
    var cropWidth = cropParams.aspect_ratio_width
    var cropHeight = cropParams.aspect_ratio_height
    let imgPref = pref
    if (state.wizard.adminMode) {
      imgPref = "admin_" + pref
    }
    storePref(imgPref + "_" + cropWidth + "_" + cropHeight, response.name)
    if (handwritingEngraving) {
      storePref(
        imgPref + "_" + "th" + "_" + cropWidth + "_" + cropHeight,
        state.choices[pref].threshold_value
      )
    }

    if (pref == "box") {
      var opt = appStore
        .getState()
        .wizard.data.box.options.find((opt) => opt.selected_type == "photo")
      opt.value = opt.photo_value = response.name
      opt.photoUploaded = true
      yield put(updateChoice("box", opt, true))
    } else {
      yield put(
        updateChoice(pref, { photoUploaded: true, value: response.name }, true)
      )
    }

    yield put(replace(`${history.location.pathname}${history.location.search}`))
  } catch (response) {
    console.error("Upload error", response)
    alert("There was a problem uploading your photo. Please try again.")
  }
}
