import { all, call, put, select } from "redux-saga/effects"

import api from "apis"
import { getPref } from "helpers/user-prefs"

import {
  addWizardData,
  updateChoice,
  setExtraWizardPrefOrder,
  finishAddBuilderItem,
} from "../actions"

export default function* addBuilderItem(action) {
  const state = yield select()
  const builderIndex = state.choices.cc.value
  const newPrefName = "c" + builderIndex
  const styleCode = action.style_code

  let url = `/products/${styleCode}/product_defaults?prefPrefix=${newPrefName}`
  const result = yield call(api.get, url)
  yield put(
    updateChoice(newPrefName, {
      title: result.product_seo_name,
      value: styleCode,
    })
  )
  yield put(updateChoice("cc", { value: builderIndex }))

  let data_loads = []
  for (let key in result.react.wizard) {
    const datas = result.react.wizard[key]
    datas.forEach((data) => {
      if (data.pref) {
        // Check for existing pref, if doesn't exist, use the product's current selection or
        // a user pref
        const currChoice = state.choices[data.pref]
          ? state.choices[data.pref].value
          : null
        const basePref = data.pref.replace(newPrefName + "_", "")
        if (!currChoice) {
          const altChoice = state.choices[basePref]
            ? state.choices[basePref].value
            : null
          const userChoice = getPref(basePref)
          const findChoice = altChoice || userChoice
          if (findChoice) {
            if (data.options) {
              const findOpt = data.options.find(
                (opt) => opt.value == findChoice
              )
              if (findOpt) {
                data_loads.push(put(updateChoice(data.pref, findOpt)))
              } else {
                data_loads.push(
                  put(updateChoice(data.pref, { value: findChoice }))
                )
              }
            } else {
              data_loads.push(
                put(updateChoice(data.pref, { value: findChoice }))
              )
            }
          }
        }
      }
      data_loads.push(put(addWizardData(data)))
      if (data.engravings) {
        data.engravings.forEach((engraving) => {
          if (state.wizard.adminMode) {
            engraving.max_chars = engraving.admin_max_chars
          }
          data_loads.push(put(addWizardData(engraving)))
        })
      }
      if (data.stones) {
        data.stones.forEach((stone) => {
          data_loads.push(put(addWizardData(stone)))
        })
      }
      if (data.default_sku) {
        data_loads.push(put(addWizardData(data.default_sku)))
      }

      //Disable stone inputs with only one option
      if (
        /s[0-9]+/.test(key) &&
        data.options.length <= 1 &&
        !data.override_text
      ) {
        // data_loads.push(
        //   put(setWizardData(Object.assign({}, data, { disabled: true })))
        // )
      }
    })
  }

  if (result.react.wizard_pref_order) {
    yield put(
      setExtraWizardPrefOrder(newPrefName, result.react.wizard_pref_order)
    )
  }

  yield all(data_loads)

  yield put(finishAddBuilderItem())
}
