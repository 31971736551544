import { LOAD_USER_PREFERENCES, SET_USER_PREFERENCES } from "./actions"

const INITIAL_STATE = {
  userPreferences: {
    data: null,
    loaded: false,
  },
}

export default function accountReducer(state = INITIAL_STATE, action) {
  let newState = Object.assign({}, state)

  switch (action.type) {
    case LOAD_USER_PREFERENCES:
      newState.userPreferences.loaded = false
      return newState
    case SET_USER_PREFERENCES:
      newState.userPreferences.data = action.preferences
      newState.userPreferences.loaded = true
      return newState
    default:
      return state
  }
}
