import { all, takeLatest } from "redux-saga/effects"

import { FETCH_WISH_LIST, REMOVE_FROM_WISHLIST } from "./actions"
import fetchWishList from "./sagas/fetch-wish-list"
import removeFromWishlist from "./sagas/remove-from-wishlist"

function* shareSagas() {
  yield all([
    takeLatest(FETCH_WISH_LIST, fetchWishList),
    takeLatest(REMOVE_FROM_WISHLIST, removeFromWishlist),
  ])
}

export default shareSagas
