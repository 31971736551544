import { call, put, select } from "redux-saga/effects"

import { setAppleGooglePay } from "areas/cart/store/actions"

export default function* checkAppleGooglePay() {
  try {
    const store = yield select()
    if (
      store.cartUI.canProcessApplePay === null &&
      store.cartUI.canProcessGooglePay === null
    ) {
      const canMakeAppleGooglePayment = yield call(() =>
        import("helpers/payment-helpers")
      )
      const result = yield call(canMakeAppleGooglePayment.default)
      if (result) {
        const { applePay, googlePay } = result
        yield put(setAppleGooglePay(applePay, googlePay))
      } else if (result === null) {
        // https://stripe.com/docs/js/payment_request/can_make_payment
        // The can_make_payment promise will resolve "null" If no wallet is available
        yield put(setAppleGooglePay(false, false))
      }
    }
  } catch (error) {
    console.error(error)
  }
}
