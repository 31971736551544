import Cookies from "js-cookie"
import { call, put, select } from "redux-saga/effects"

import api from "apis"
import { setCart } from "areas/cart/store/actions"
import { isTestMode } from "helpers/application"

export default function* removeFromCart(action) {
  const store = yield select()
  let removedItem
  const cartToken = Cookies.get(sl.config.cookies_config.cart_token)

  let url
  let result

  if (action.isAddon) {
    url = `/api/v10/cart/${cartToken}/addon/${action.id}`
    result = yield call(api.delete, url)
  } else {
    removedItem = store.cart.line_items.find((item) => item.id == action.id)
    url = `/api/v10/cart/${cartToken}/remove_item/${action.id}`
    result = yield call(api.delete, url, "application/json")
  }
  if (result) {
    if (!action.isAddon && removedItem && !isTestMode) {
      const cartLineItems = result.data.line_items_gtm

      // GTM record event
      dataLayer.push({
        cartLineItems,
        currencyCode: removedItem.currency_code,
        event: "removed-from-cart",
        removedItem: {
          itemCurrencyCode: removedItem.currency_code,
          itemMetal: removedItem.sku,
          itemPrice: removedItem.price,
          itemRegularPrice: removedItem.regular_price,
          itemShorthand: removedItem.item_shorthand,
          itemStyleCode: removedItem.style_code,
        },
      })
    }
    yield put(setCart(result.data))

    // if (store.router.location.pathname === "/cart")
    // if the user is currently on the cart page, reload the page
    // location.reload()
  }
}
