import { select, put } from "redux-saga/effects"

import { loadReactCollection } from "areas/collection/store/actions"

import { setFetchingElementsLoading } from "../actions"

export default function* fetchLandingElements() {
  const state = yield select()
  if (
    !state.landing.all_elements_fetched &&
    !state.landing.fetching_elements_loading
  ) {
    yield put(setFetchingElementsLoading(true))
    yield put(loadReactCollection({ url_path: state.landing.url_path }))
  }
}
