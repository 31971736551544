import {
  SHOW_SHARE_EMAIL_MODAL,
  TOGGLE_EMAIL_SHARE_MODAL,
  EMPTY_SHARE_DATA,
  SET_MODAL_TYPE
} from './actions'

export default function share(state = {
  emailFormData: {},
  showModal: false,
  modalType: null
}, action) {
  switch (action.type) {
    case SHOW_SHARE_EMAIL_MODAL:
      return Object.assign({}, state, { emailFormData: action.data })
    case TOGGLE_EMAIL_SHARE_MODAL:
      return {
        ...state,
        showModal: action.toggle
      }
    case EMPTY_SHARE_DATA:
      return {
        ...state,
        emailFormData: {}
      }
    case SET_MODAL_TYPE:
      return {
        ...state,
        modalType: action.modal
      }
    default:
      return state
  }
}