import Cookies from "js-cookie"
import { all, call, takeLatest } from "redux-saga/effects"

import doJavaScriptTracking from "./sagas/do-javascript-tracking"
import loadDeliveryMethods from "./sagas/load-delivery-methods"
import redeemPoints from "./sagas/redeem-points"
import submitOrder from "./sagas/submit-order"
import updateUserData from "./sagas/update-user-data"

function* orderCompleted() {
  yield call(Cookies.remove, sl.config.cookies_config.cart_token)
}

function* rootSaga() {
  yield all([
    takeLatest("SUBMIT_ORDER", submitOrder),
    takeLatest("REDEEM_REWARDS", redeemPoints),
    takeLatest("DO_JAVASCRIPT_TRACKING", doJavaScriptTracking),
    takeLatest("ORDER_COMPLETED", orderCompleted),
    takeLatest("UPDATE_USER_DATA", updateUserData),
    takeLatest("LOAD_DELIVERY_METHODS", loadDeliveryMethods),
  ])
}

export default rootSaga
