/* eslint-disable require-yield */
const SEARCH_STORAGE = "jewlr4-suggestions"

export default function* saveSearchHistory(action) {
  const query = action.query.trim()

  const savedQueries = localStorage.getItem(SEARCH_STORAGE)
  let savedSuggestions = savedQueries ? JSON.parse(savedQueries) : []

  const newQuery = {
    keyword: query,
    name: query,
    query_string: query,
    url: `/search?k=${query}`
  }

  savedSuggestions = savedSuggestions.filter(
    suggestion => suggestion.name !== query
  )

  savedSuggestions.unshift(newQuery)
  localStorage.setItem(SEARCH_STORAGE, JSON.stringify(savedSuggestions))
}
