import { replace } from "connected-react-router"
import { call, put, select } from "redux-saga/effects"

import api from "apis"
import { setLanding } from "app-store-v2/landing/actions"
import {
  replaceCollectionData,
  setCollection,
  setPageNotFound,
  setProductResult,
  setPriceRange,
} from "areas/collection/store/actions"
import { submit_ga_tracker } from "helpers/analytics-helpers"
import { isTestMode } from "helpers/application"

import { interpolateProductsWithPages } from "./helpers/collection"

export default function* loadReactCollection(action) {
  const state = yield select()

  let result = null
  let url = `/api/v10/collections/${
    action.data.url_path || "homepage"
  }/load_react_collection${state.router.location.search}`

  if (action.data.data) {
    result = action.data.data
  } else {
    yield put(setPageNotFound(false))
    try {
      result = yield call(api.get, url)
    } catch (e) {
      console.error("Collection load error: ", e)
    }
  }

  if (result && !(result instanceof Error)) {
    if (result.not_found) {
      yield put(setPageNotFound(true))
      yield put(
        replaceCollectionData({
          model: "",
          mountedPath: "",
        })
      )
    } else {
      if (result.redirect_url) {
        yield put(
          replaceCollectionData({
            model: result.model,
          })
        )

        if (result.custom_page_view) {
          submit_ga_tracker(result.custom_page_view)
        }
        yield put(replace(result.redirect_url))
        result = result.redirect_model
      }

      if (result && result.model === "landing") {
        // SPA navigation, update the landing unique event id
        if (result.unique_event_id) {
          window.cache_free_ssr_data.unique_event_ids.landing =
            result.unique_event_id
        }

        // Set landing page data
        yield put(setLanding(result))
        yield put(
          replaceCollectionData({
            model: result.model,
            mountedPath: `/${result.url_path}`,
          })
        )
      } else if (result) {
        // SPA navigation, update the collection unique event id
        if (result.unique_event_id) {
          window.cache_free_ssr_data.unique_event_ids.collection =
            result.unique_event_id
        }
        if (!isTestMode) {
          dataLayer.push({
            event: "google-remarketing",
            googleRemarketing: {
              ecomm_category:
                result.title == "Search"
                  ? null
                  : result.filter_row.clear_all_link.replace("/", ""),
              ecomm_pagetype:
                result.title == "Search" ? "searchresults" : "category",
              ecomm_prodid: result.product_result.map((p) => p.style_code),
              ecomm_totalvalue: result.product_result.reduce((total, p) => {
                if (p.looks && p.default_look && p.looks[p.default_look]) {
                  return total + (p.looks[p.default_look].selling || 0)
                } else {
                  return total
                }
              }, 0),
            },
          })
        }

        // Set collection page data
        yield put(setCollection(result))
        result = interpolateProductsWithPages(result)
        yield put(setProductResult(result.product_result))

        let priceRange = result.filter_menu.default_price_range
        yield put(setPriceRange(priceRange.min, priceRange.max))

        // Trigger a dataLayer event for analytics
        if (!isTestMode) {
          if (action.data.url_path == "search") {
            dataLayer.push({
              contentIds: result.product_result
                .slice(0, 9)
                .map((p) => p.rm_style_code),
              contentType: "product",
              event: "search",
              searchString: result.preserve_cat_path,
            })
            submit_ga_tracker(`/search?k=${result.preserve_cat_path}`)
          }
        }

        yield put(
          replaceCollectionData({
            model: result.model,
          })
        )
      }
    }
  }
}
