/**
 * @deprecated
 * Due to migration to HalfModal
 */
import { call, put, select } from "redux-saga/effects"

export default function* editUserImage(action) {
  const state = yield select()
  if (!state.wizardUI.prefStore) {
    return
  }

  let isUpload = state.choices[action.pref].photoUploaded

  //We have the original image, so edit it
  if (isUpload) {
    $(`#${action.pref}_upload_button`).prop("disabled", false)
  }
}
