import Cookies from "js-cookie"

export const ADD_ADDON = "ADD_ADDON"
export const REMOVE_ADDON = "REMOVE_ADDON"

export const INIT_CART = "INIT_CART"
export const SET_CART = "SET_CART"
export const EMPTY_CART = "EMPTY_CART"
export const TOGGLE_CART_PREVIEW = "TOGGLE_CART_PREVIEW"
export const REMOVE_LINE_ITEM = "REMOVE_LINE_ITEM"
export const SHOW_LINE_ITEM_DETAILS = "SHOW_LINE_ITEM_DETAILS"
export const ENTER_COUPON = "ENTER_COUPON"
export const APPLY_COUPON = "APPLY_COUPON"
export const REMOVE_COUPON = "REMOVE_COUPON"
export const REMOVE_GIFT_WRAP = "REMOVE_GIFT_WRAP"
export const SET_GIFT_WRAPPING = "SET_GIFT_WRAPPING"
export const SET_GREETING_CARD = "SET_GREETING_CARD"

export const TOGGLE_GIFT = "TOGGLE_GIFT"
export const SET_GOOGLE_APPLE_PAY = "SET_GOOGLE_APPLE_PAY"
export const CHECK_APPLE_GOOGLE_PAY = "CHECK_APPLE_GOOGLE_PAY"
export const TOGGLE_CHECKOUT_ERROR_MODAL = "TOGGLE_CHECKOUT_ERROR_MODAL"
export const GENERATE_GIFT_VIDEO = "GENERATE_GIFT_VIDEO"
export const UPDATE_GIFT_PROPERTIES = "UPDATE_GIFT_PROPERTIES"
export const SET_GIFT_PROPERTIES = "SET_GIFT_PROPERTIES"
export const FETCH_TAX_AND_SHIP_OPTIONS = "FETCH_TAX_AND_SHIP_OPTIONS"
export const SET_TAX_AND_SHIP_OPTIONS = "SET_TAX_AND_SHIP_OPTIONS"
export const UPDATE_SELECTED_SHIPPING_OPTION = "UPDATE_SELECTED_SHIPPING_OPTION"
export const ADD_SAVED_ITEM = "ADD_SAVED_ITEM"
export const MOVE_SAVED_ITEM_TO_CART = "MOVE_SAVED_ITEM_TO_CART"
export const REMOVE_SAVED_ITEM = "REMOVE_SAVED_ITEM"
export const TOGGLE_SAVED_ITEM_ALERT = "TOGGLE_SAVED_ITEM_ALERT"
export const DELETE_SAVED_CART = "DELETE_SAVED_CART"
export const SET_WARRANTY = "SET_WARRANTY"

const cartToken = () => Cookies.get(sl.config.cookies_config.cart_token)

export function initCart() {
  return { type: INIT_CART }
}

export function setCart(cart) {
  return { cart, type: SET_CART }
}

export function toggleCartPreview(toggle) {
  return { toggle, type: TOGGLE_CART_PREVIEW }
}

export function removeLineItem(id, type) {
  return { payload: { id, type }, type: REMOVE_LINE_ITEM }
}

export function showLineItemDetails(id) {
  return { id, type: SHOW_LINE_ITEM_DETAILS }
}

export function enterCoupon(coupon_code) {
  return { coupon_code, type: ENTER_COUPON }
}

export function removeGiftWrap(id) {
  return { id, type: REMOVE_GIFT_WRAP }
}

export function setGiftWrapping(id, gift_wrapping, added_from) {
  return {
    api_url: `/api/v10/cart/${cartToken()}/package`,
    method: "patch",
    payload: {
      added_from: added_from,
      line_item_id: id,
      ...gift_wrapping,
    },
    type: SET_GIFT_WRAPPING,
  }
}

export function setGreetingCard(id, greeting_card, callbacks) {
  return {
    api_url: `/api/v10/cart/${cartToken()}/greeting_card`,
    callbacks,
    method: "patch",
    payload: {
      line_item_id: id,
      ...greeting_card,
    },
    type: SET_GREETING_CARD,
  }
}

export function toggleGift(id) {
  return { id, type: TOGGLE_GIFT }
}

export function checkAppleGooglePay() {
  return { type: CHECK_APPLE_GOOGLE_PAY }
}

export function setAppleGooglePay(applePay, googlePay) {
  return {
    applePay,
    googlePay,
    type: SET_GOOGLE_APPLE_PAY,
  }
}

export function emptyCart() {
  return {
    type: EMPTY_CART,
  }
}

export function toggleCheckoutErrorModal(toggle, errorMessage = null) {
  return {
    errorMessage,
    toggle,
    type: TOGGLE_CHECKOUT_ERROR_MODAL,
  }
}

export function generateGiftVideo(values, lineItemId, actions) {
  return {
    actions,
    lineItemId,
    type: GENERATE_GIFT_VIDEO,
    values,
  }
}

export function updateGiftProperties(lineItemId, values) {
  return {
    lineItemId,
    type: UPDATE_GIFT_PROPERTIES,
    values,
  }
}
export function setGiftProperties(lineItemId, values) {
  return {
    lineItemId,
    type: SET_GIFT_PROPERTIES,
    values,
  }
}
export function fetchTaxAndShipOptions(values, formActions) {
  return {
    formActions,
    type: FETCH_TAX_AND_SHIP_OPTIONS,
    values,
  }
}

export function setTaxAndShipOptions(values) {
  return {
    type: SET_TAX_AND_SHIP_OPTIONS,
    values,
  }
}

export function updateSelectedShippingOption(shippingOptionId, shippingAmount) {
  return {
    shippingAmount,
    shippingOptionId,
    type: UPDATE_SELECTED_SHIPPING_OPTION,
  }
}

export function addSavedItem(lineItemId) {
  return {
    lineItemId,
    type: ADD_SAVED_ITEM,
  }
}

export function moveSavedItemToCart(lineItemId, toggleLoading) {
  return {
    lineItemId,
    toggleLoading,
    type: MOVE_SAVED_ITEM_TO_CART,
  }
}

export function removeSavedItem(lineItemId) {
  return {
    lineItemId,
    type: REMOVE_SAVED_ITEM,
  }
}

export function toggleSavedItemAlert(toggle) {
  return {
    toggle,
    type: TOGGLE_SAVED_ITEM_ALERT,
  }
}

export function deleteSavedCart() {
  return {
    type: DELETE_SAVED_CART,
  }
}

export function addAddon(
  addon_code,
  addon_type,
  added_from,
  toggleDisableActions
) {
  return {
    api_url: `/api/v10/cart/${cartToken()}/addon`,
    method: "post",
    payload: {
      added_from,
      addon_code,
      addon_type,
    },
    toggleDisableActions,
    type: ADD_ADDON,
    variable: {
      action: "adding",
      id: addon_code,
    },
  }
}

export function removeAddon(addon_id, toggleDisableActions) {
  return {
    api_url: `/api/v10/cart/${cartToken()}/addon/${addon_id}`,
    method: "delete",
    payload: {},
    toggleDisableActions,
    type: REMOVE_ADDON,
    variable: {
      action: "removing",
      id: addon_id,
    },
  }
}

export function applyCoupon(coupon_code, ref, setApplying, setCouponError) {
  return {
    coupon_code,
    ref,
    setApplying,
    setCouponError,
    type: APPLY_COUPON,
  }
}

export function removeCoupon() {
  return {
    api_url: `/api/v10/cart/${cartToken()}/coupon`,
    method: "delete",
    type: REMOVE_COUPON,
  }
}

export function setWarranty(id, plan) {
  return {
    api_url: `/api/v10/cart/${cartToken()}/select_warranty_plan`,
    method: "patch",
    payload: {
      line_item_id: id,
      plan,
    },
    type: SET_WARRANTY,
  }
}
