export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST"
export const CLEAR_CATEGORY_FILTERS = "CLEAR_CATEGORY_FILTERS"
export const LOAD_REACT_COLLECTION = "LOAD_REACT_COLLECTION"
export const REPLACE_COLLECTION_DATA = "REPLACE_COLLECTION_DATA"
export const SET_COLLECTION = "SET_COLLECTION"
export const SET_GRID_VIEW = "SET_GRID_VIEW"
export const SET_LOADING = "SET_LOADING"
export const SET_LOADING_MORE_PRODUCTS = "SET_LOADING_MORE_PRODUCTS"
export const SET_PAGE_NOT_FOUND = "SET_PAGE_NOT_FOUND"
export const SET_PRICE_RANGE = "SET_PRICE_RANGE"
export const SET_PRODUCT_RESULT = "SET_PRODUCT_RESULT"
export const TOGGLE_FILTER_MENU = "TOGGLE_FILTER_MENU"
export const TOGGLE_SORT_MENU = "TOGGLE_SORT_MENU"
export const UPDATE_CURRENT_PAGE = "UPDATE_CURRENT_PAGE"
export const UPDATE_CURRENT_SORT = "UPDATE_CURRENT_SORT"
export const UPDATE_FILTER = "UPDATE_FILTER"
export const UPDATE_UNIQUE_FILTER = "UPDATE_UNIQUE_FILTER"
export const UPDATE_PRICE_RANGE = "UPDATE_PRICE_RANGE"
export const UPDATE_NEW_PAGE_START_INDEX = "UPDATE_NEW_PAGE_START_INDEX"
export const APPEND_PRODUCT_RESULT = "APPEND_PRODUCT_RESULT"
export const PREPEND_COLLECTION_PLACEHOLDERS = "PREPEND_COLLECTION_PLACEHOLDERS"
export const PREPEND_PRODUCT_RESULT = "PREPEND_PRODUCT_RESULT"
export const UNSELECT_FROM_WISHLIST = "UNSELECT_FROM_WISHLIST"
export const RELOAD_COLLECTION = "RELOAD_COLLECTION"
export const CONFIRM_ADD_TO_WISHLIST = "CONFIRM_ADD_TO_WISHLIST"

/**
 * Clear all filters matching category name.
 * Triggers update-filter saga
 * @param  {string} category - category name or "all". Sets `is_active_filter` to false
 */
export function clearCategoryFilters(category) {
  return { category, type: CLEAR_CATEGORY_FILTERS }
}

/**
 * Triggers load-react-collection saga
 * @param  {object} data - Set in collection/show.html react_component. Params to send to /load_react_collection
 */
export function loadReactCollection(data) {
  return { data, type: LOAD_REACT_COLLECTION }
}

/**
 * Replaces `state.collection[key]`
 * @param {object} data - Replaces data in `state.collection`
 */
export function replaceCollectionData(data) {
  return { data, type: REPLACE_COLLECTION_DATA }
}

/**
 * Populates `state.collection`
 * @param {object} collection - all collection data needed to render page
 */
export function setCollection(collection) {
  return { collection, type: SET_COLLECTION }
}

/**
 * Show/hide loading gif, updates `state.collectionUI.loading`
 * @param {string='full','content' | bool} loading - Updates `state.collectionUI.loading`. "full" shows full page loading with placeholders. "content" shows only products area loading.
 */
export function setLoading(loading) {
  return { loading, type: SET_LOADING }
}

/**
 * Show/hide spinner gif, updates 'state.collectionUI.loading_more_products'
 * @param {bool} loading show/hide loading more products
 */
export function setLoadingMoreProducts(loading) {
  return { loading, type: SET_LOADING_MORE_PRODUCTS }
}

/**
 * Populates `state.collection.gridView`
 * @param {"small" | "large"} column="small" - columns to show in mobile
 */
export function setGridView(view) {
  return { type: SET_GRID_VIEW, view }
}

/**
 * Shows the Page Not Found dialog
 * @param {Boolean} active Whether or not to make the 404 page active
 */
export function setPageNotFound(active) {
  return { active, type: SET_PAGE_NOT_FOUND }
}

/**
 * Sets min and max of `state.collection.default_price_range`
 * @param {integer} min - minimum of filtered range
 * @param {integer} max - maximum of filtered range
 */
export function setPriceRange(min, max) {
  return { max, min, type: SET_PRICE_RANGE }
}

/**
 * Updates `state.collection.product_result`
 * @param {array} productResult - array of products to show
 */
export function setProductResult(productResult) {
  return { productResult, type: SET_PRODUCT_RESULT }
}

/**
 * Updates min and max of `state.collection.filtered_price_range`
 * @param {integer} min - minimum of filtered range
 * @param {integer} max - maximum of filtered range
 */
export function updatePriceRange(min, max) {
  return { max, min, type: UPDATE_PRICE_RANGE }
}

/**
 * Sets `state.collection.product_result[style_code].wishlistAdded` to true
 * Triggers add-wishlist saga
 * @param  {string} style_code - style_code of product to be updated
 * @param  {string} selectedProduct - selected product options to add to wishlist
 */
export function addToWishlist(style_code, selectedProduct, source) {
  return { selectedProduct, source, style_code, type: ADD_TO_WISHLIST }
}

/**
 * Sets `state.collection.product_result[style_code].wishlistAdded` to false
 * Triggers remove-wishlist saga
 * @param  {string} style_code - style_code of product to be updated
 */
export function unselectFromWishlist(style_code, source) {
  return { source, style_code, type: UNSELECT_FROM_WISHLIST }
}

/**
 * Toggles filter menu, updates `state.collection.open_filter_menu`
 * @param {bool} open - show/hide filter menu
 */
export function toggleFilterMenu(open) {
  return { open, type: TOGGLE_FILTER_MENU }
}

/**
 * Toggles sort menu, updates `state.collectionUI.open_sort_menu`
 * @param {bool} open - show/hide sort menu
 */
export function toggleSortMenu(open) {
  return { open, type: TOGGLE_SORT_MENU }
}

/**
 * Triggers update-filter saga
 * @param  {integer} page - updates `state.pagination.current_page_number`
 */
export function updateCurrentPage(page) {
  return { page, type: UPDATE_CURRENT_PAGE }
}

/**
 * Triggers update-filter saga
 * @param  {string} option - value of sort filter to set in `state.sort_menu.current_sort`
 */
export function updateCurrentSort(option) {
  return { option, type: UPDATE_CURRENT_SORT }
}
/**
 * Triggers update-filter saga
 * @param  {string} filter - name of filter to toggle in `state.filter_menu`
 */
export function updateFilter(filter) {
  return { filter, type: UPDATE_FILTER }
}

/**
 * Triggers update-filter saga
 * @param {object} param - contains two keys:
 *    filter - name of filter to toggle in `state.filter_menu`,
 *    belongs_to - category name or "all". Sets `is_active_filter` to false
 */
export function updateUniqueFilter(param) {
  return { param, type: UPDATE_UNIQUE_FILTER }
}

/**
 * Append products to end of `state.collection.product_result` array
 * @param  {array} productResult array of product results to be appeneded
 */
export function appendProductResult(productResult) {
  return { productResult, type: APPEND_PRODUCT_RESULT }
}

/**
 * Prepend collection placeholders when the user uses back/forward to go back
 * to a collection page
 */
export function prependCollectionPlaceholders(numPages) {
  return { numPages, type: PREPEND_COLLECTION_PLACEHOLDERS }
}

/**
 * Prepend products to start of `state.collection.product_result` array
 * @param  {array} productResult array of product results to be prepeneded
 */
export function prependProductResult(productResult) {
  return { productResult, type: PREPEND_PRODUCT_RESULT }
}

export function reloadCollection(query, repeat) {
  return { query, repeat, type: RELOAD_COLLECTION }
}

/**
 * Cancels/Continues the addToWishlist
 * @param {Boolean} toggle confirm add to wishlist
 */
export function confirmAddToWishlist(toggle) {
  return { toggle, type: CONFIRM_ADD_TO_WISHLIST }
}

/**
 * For maintaining focus on Load More
 * @param {Number} index new page index
 */
export function updateNewPageStartIndex(index) {
  return { index, type: UPDATE_NEW_PAGE_START_INDEX }
}
