import Cookies from "js-cookie"
import { call, put } from "redux-saga/effects"

import api from "apis"
import { isTestMode } from "helpers/application"

import { setCart } from "../actions"

export default function* applyCouponChanges(action) {
  try {
    const cartToken = Cookies.get(sl.config.cookies_config.cart_token)

    const url = `/api/v10/cart/${cartToken}/coupon`
    const result = yield call(api.patch, url, {
      code: action.coupon_code,
    })
    if (result) {
      if (action.setApplying) {
        action.setApplying(false)
      }

      yield put(setCart(result.data))

      const { summary } = result.data
      if (summary.promo_code?.promo_code_applied == action.coupon_code) {
        // GTM record event
        if (!isTestMode) {
          dataLayer.push({
            event: "applied-promo-code",
            promoCode: summary.promo_code_applied,
          })
        }

        window._conv_q = window._conv_q || []
        window._conv_q.push([
          "triggerConversion",
          sl.config.env == "production" ? "10028058" : "10027950",
        ])

        if (action.ref) {
          action.ref.blur()
        }
      }
    }
  } catch (error) {
    if (action.setApplying) {
      action.setApplying(false)
    }

    if (error.errors && action.setCouponError) {
      action.setCouponError(error.errors[0])
    }
    if (action.ref) {
      action.ref.focus()
    }
  }
}
