import { call, put } from "redux-saga/effects"

import api from "apis"

import { setCart } from "../actions"

export default function* moveSavedItemToCart(action) {
  try {
    const result = yield call(
      api.post,
      "/api/v5/carts/remove_item_from_saved_cart",
      {
        line_item_id: action.lineItemId,
      }
    )
    if (result.data.cart) {
      yield put(setCart(result.data.cart))
    }
  } catch (error) {
    console.error(error)
  }
}
