import { all, takeLatest } from "redux-saga/effects"

import {
  FETCH_SHARE_EMAIL,
  FETCH_CART_SHARE,
  TOGGLE_SHARE_MODAL,
} from "./actions"
import fetchCartShare from "./sagas/cart-share"
import emailShare from "./sagas/email-share"
import toggleShareModal from "./sagas/toggle-share"

function* shareSagas() {
  yield all([
    takeLatest(FETCH_SHARE_EMAIL, emailShare),
    takeLatest(FETCH_CART_SHARE, fetchCartShare),
    takeLatest(TOGGLE_SHARE_MODAL, toggleShareModal),
  ])
}

export default shareSagas
