import { call, put, select } from "redux-saga/effects"

import api from "apis"
import { setLocalStorage } from "helpers/application"

import { setUserAddresses } from "../actions"

export default function* updateUserAddress({
  actions: { onError, onSuccess },
  address,
}) {
  // Required body params
  // {
  //   user_address: {
  //     address1: '123 Melbourne Ave.'
  //     address2: ''
  //     first_name: 'Steve'
  //     last_name: 'Jobs'
  //     city: 'Miami'
  //     state: 'CA'
  //     postal_code: 54123
  //     country: 'US'
  //     phone: 5554443333
  //     residential: ''
  //     is_primary: false
  //     address_type: '',
  //   },
  //  token: '123da087da0a1'
  // }
  const state = yield select()
  const url = "/secure/api/v10/user/address"
  const userToken = state.user?.jewlrorders?.guid_token

  const body = {
    country: sl.config.site_country.toLowerCase(),
    guid_token: userToken,
    user_address: address,
  }

  try {
    const response = yield call(api.put, url, body)

    if (response?.success && response.data) {
      yield put(setUserAddresses(response.data.address_list))
      setLocalStorage(state.user.jewlrorders, "user_obj")
      onSuccess(response.data.address_list)
    }
  } catch (err) {
    onError(err?.errors?.[0])
    console.log(err)
  }
}
