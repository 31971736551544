export const LOAD_USER_PREFERENCES = "LOAD_USER_PREFERENCES"
export const SET_USER_PREFERENCES = "SET_USER_PREFERENCES"
export const UPDATE_USER_PREFERENCES = "UPDATE_USER_PREFERENCES"
export const UPDATE_PREFERENCE_FREQUENCY = "UPDATE_PREFERENCE_FREQUENCY"
export const UNSUBSCRIBE_USER = "UNSUBSCRIBE_USER"
export const RESUBSCRIBE_USER = "RESUBSCRIBE_USER"

export const loadUserPreferences = (guidToken) => {
  return {
    guidToken,
    type: LOAD_USER_PREFERENCES,
  }
}

export const setUserPreferences = (preferences) => {
  return {
    preferences,
    type: SET_USER_PREFERENCES,
  }
}

export const updatePreferences = (guidToken, values, actions) => {
  return {
    actions,
    guidToken,
    type: UPDATE_USER_PREFERENCES,
    values,
  }
}

export const updateFrequency = (guidToken, frequency, actions) => {
  return {
    actions,
    frequency,
    guidToken,
    type: UPDATE_PREFERENCE_FREQUENCY,
  }
}

export const unsubscribeUser = (guidToken) => {
  return {
    guidToken,
    type: UNSUBSCRIBE_USER,
  }
}

export const resubscribeUser = (guidToken, actions) => {
  return {
    actions,
    guidToken,
    type: RESUBSCRIBE_USER,
  }
}
