import { all, call, put, select } from "redux-saga/effects"

import api from "apis"

import {
  addChooseGemstoneStep,
  addChooseStonetypeStep,
  addFinalDiamond,
  diamondGoToStep,
  removeChooseGemstoneStep,
  removeChooseStonetypeStep,
  addWizardData,
  setWizardData,
  setWizardPrefOrder,
  updateDiamondData,
  updateTmpDiamond,
  updateChoice,
  updateChoiceDirectly,
  updateProductSpecs,
  resetPaneFilters,
  diamondStartOver,
} from "../actions"
import { mapShapeToStylecode, mapCaratToStylecode } from "../helpers/diamond"

export default function* updateDiamond(action) {
  let state = yield select()
  const ssr = state.router.action == "POP"
  // Don't call api when initializing diamond 4Cs choices
  if (!state.wizard.hasDiamondSelector) {
    return
  }
  if (!state.wizardUI.prefStore && action.pref !== "init_diamond") {
    return
  }

  // Update selected value into state.choices
  if (action.pref?.startsWith("d_")) {
    yield put(updateChoiceDirectly(action.pref, action.data))
    let tmpDiamond = { ...state.diamondUI.tmpDiamond }
    tmpDiamond[action.pref] = action.data.name
    yield put(updateTmpDiamond(tmpDiamond))
  }
  state = yield select()

  // Construct updated style_code
  const currentStylecode = state.choices.style_code?.value
  const lastDash = currentStylecode.lastIndexOf("-")
  const baseStylecode = currentStylecode.slice(0, lastDash + 1)
  let endStylecode = currentStylecode.slice(lastDash + 1)

  const diamondShape =
    action.pref === "d_shape" ? action.data.value : state.choices.d_shape.value
  const diamondCarat =
    action.pref === "d_carat" ? action.data.value : state.choices.d_carat.value

  let basket = endStylecode[2] || ""
  if (state.diamondSelector.signatureBasket) {
    basket = "A"
  } else if (state.wizard.data.m2?.base_basket) {
    basket = state.wizard.data.m2.base_basket.value || "B"
  }

  let updatedStylecode =
    baseStylecode +
    mapShapeToStylecode[diamondShape] +
    mapCaratToStylecode[diamondCarat] +
    basket +
    endStylecode.slice(3)

  console.log(
    "CURRENT STYLE: ",
    currentStylecode,
    " UPDATED: ",
    updatedStylecode
  )

  let params = new URLSearchParams()
  Object.keys(state.choices)
    .filter((pref) => pref.startsWith("d_"))
    .forEach((pref) => {
      params.append(pref, state.choices[pref].value || "")
    })
  // Update wizard data like accent stones only on shape/carat change
  if (
    ["d_shape", "d_carat"].includes(action.pref) ||
    action.type === "SET_SIGNATURE_BASKET"
  ) {
    params.append("update_options", true)
  }

  const url = `/api/v10/products/${updatedStylecode}/diamond?${params.toString()}`
  try {
    const result = yield call(api.get, url)
    if (result?.data?.diamond) {
      const diamondData = result.data.diamond
      let dataUpdates = []
      // Update stylecode if a different available stylecode combo is found
      if (diamondData.style_code !== updatedStylecode) {
        updatedStylecode = diamondData.style_code
      }

      // If stylecode is changed:
      // - update choice to trigger getPreviewImage saga
      // - update wizard data with stones, sku and product specs
      if (currentStylecode !== updatedStylecode) {
        yield put(updateChoice("style_code", { value: updatedStylecode }))
        const productOptions = result?.data?.product_options?.options
        if (productOptions) {
          dataUpdates.push(put(resetPaneFilters()))
          productOptions.forEach((option) => {
            // Sets option to paneFilters
            dataUpdates.push(put(addWizardData(option)))
            // Sets option to wizard.data[option.pref]
            dataUpdates.push(put(setWizardData(option)))
          })
          dataUpdates.push(
            put(updateProductSpecs(result?.data?.product_options?.specs))
          )
          // Updating pref order because we may show or hide the s2 based on basket change
          dataUpdates.push(
            put(setWizardPrefOrder(result?.data?.product_options?.pref_order))
          )
        }
      }

      // Update data into state.diamondSelector
      Object.keys(diamondData).forEach((key) => {
        let data = {}
        data[key] = diamondData[key]
        dataUpdates.push(put(updateDiamondData(data)))
      })
      yield all(dataUpdates)

      // Only triggers from `init-wizard`
      if (action.pref === "init_diamond") {
        let startOver = action.data.startOver
        if (diamondData.d_stone_type) {
          yield put(addChooseStonetypeStep())
          if (state.diamondUI.steps.includes("choose_gemstone")) {
            startOver = true
            yield put(removeChooseGemstoneStep())
          }
        } else if (state.diamondUI.steps.includes("choose_stone_type")) {
          // start over when previous diamond had stone type choice
          startOver = true
          yield put(removeChooseStonetypeStep())
        }
        // Show final diamond UI if selected_diamond exists
        if (diamondData.selected_diamond) {
          console.log("SELECTED: ", diamondData.selected_diamond)
          // Overrides above startOver with what we are setting from init-wizard
          startOver = action.data.startOver
          if (diamondData.selected_diamond.is_diamond) {
            yield put(removeChooseGemstoneStep())
          } else {
            yield put(addChooseGemstoneStep())
          }
          if (!action.data.startOver) {
            yield put(diamondGoToStep("final_diamond"))
            yield put(addFinalDiamond(diamondData.selected_diamond))
          }
        }
        yield put(
          updateChoiceDirectly("d_stone_type", {
            value: diamondData.selected_stone_type,
          })
        )
        if (startOver) {
          yield put(diamondStartOver())
          return
        }
      }

      // Update tmpDiamond to 'selected diamond'.
      let tmpDiamond
      if (diamondData.selected_diamond) {
        const recommendedIndex = diamondData.recommended_stones.findIndex(
          (s) => s.stone.stone_code === diamondData.selected_diamond.stone_code
        )
        tmpDiamond = {
          d_clarity: diamondData.selected_diamond.clarity,
          d_color: diamondData.selected_diamond.color,
          d_cut: diamondData.selected_diamond.cut,
          d_stone_type: diamondData.selected_diamond.stone_type,
          is_diamond: diamondData.selected_diamond.is_diamond,
          price: diamondData.selected_diamond.price,
          recommendedIndex: recommendedIndex === -1 ? 0 : recommendedIndex,
          stone_code: diamondData.selected_diamond.stone_code,
          stone_size: diamondData.selected_diamond.stone_size,
        }
      }

      if (["init_diamond", "d_stone_type"].includes(action.pref)) {
        if (diamondData.recommended_stones.length > 0) {
          // Preselect 'recommended' attributes
          let theOne = diamondData.recommended_stones.find(
            (stone) => stone.the_one
          )
          tmpDiamond = {
            d_clarity: theOne.stone.clarity,
            d_color: theOne.stone.color,
            d_cut: theOne.stone.cut,
            d_stone_type: theOne.stone.stone_type,
            is_diamond: theOne.stone.is_diamond,
            price: theOne.stone.price,
            recommendedIndex: 0,
            stone_code: theOne.stone.stone_code,
            stone_size: theOne.stone.stone_size,
          }
          yield put(
            updateChoiceDirectly("d_stone_type", {
              value: tmpDiamond.d_stone_type,
            })
          )
          yield put(
            updateChoiceDirectly("d_color", { value: tmpDiamond.d_color })
          )
          yield put(updateChoiceDirectly("d_cut", { value: tmpDiamond.d_cut }))
          yield put(
            updateChoiceDirectly("d_clarity", { value: tmpDiamond.d_clarity })
          )
        }
      }
      if (tmpDiamond) {
        yield put(
          updateTmpDiamond(tmpDiamond, !(ssr && action.pref == "init_diamond"))
        )
      }

      // Trigger addFinalDiamond saga to add to s1
      if (action.pref === "final_diamond" && diamondData.selected_diamond) {
        yield put(addFinalDiamond(diamondData.selected_diamond))
      }
    } else {
      throw new Error("API response error for /diamond")
    }
  } catch (error) {
    console.error(`Error in updateDiamond: ${error}`)
  }
}
