import { put, select } from "redux-saga/effects"

import { isTestMode } from "helpers/application"
import { storePref } from "helpers/user-prefs"

import {
  updateChoiceDirectly,
  updateDiamondData,
  updateChoice,
} from "../actions"

export default function* addFinalDiamond(action) {
  const state = yield select()
  const currentStylecode = state.choices.style_code?.value

  // Set final diamond selection to choices
  yield put(updateDiamondData({ selected_diamond: action.diamond }))
  yield put(updateChoiceDirectly("d_shape", { value: action.diamond.shape }))
  yield put(updateChoiceDirectly("d_carat", { value: action.diamond.carat }))
  yield put(updateChoiceDirectly("d_color", { value: action.diamond.color }))
  yield put(updateChoiceDirectly("d_cut", { value: action.diamond.cut }))
  yield put(
    updateChoiceDirectly("d_clarity", { value: action.diamond.clarity })
  )
  yield put(
    updateChoiceDirectly("d_stone_type", { value: action.diamond.stone_type })
  )

  // Set final diamond to 's1'
  yield put(
    updateChoice(
      "s1",
      {
        display_price: action.diamond.price,
        price: action.diamond.price,
        value: action.diamond.stone_code,
      },
      true
    )
  )
  if (!isTestMode) {
    dataLayer.push({
      currencyCode: sl.config.currency_code,
      diamondCarat: action.diamond.carat,
      diamondClarity: action.diamond.clarity,
      diamondColor: action.diamond.color,
      diamondCut: action.diamond.cut,
      diamondPrice: action.diamond.price,
      diamondShape: action.diamond.shape,
      event: "diamond-configured",
      stoneCode: action.diamond.stone_code,
      styleCode: currentStylecode,
    })
  }

  // Update user prefs
  storePref("d_shape", action.diamond.shape, currentStylecode)
  storePref("d_carat", action.diamond.carat, currentStylecode)
  storePref("d_cut", action.diamond.cut, currentStylecode)
  storePref("d_color", action.diamond.color, currentStylecode)
  storePref("d_clarity", action.diamond.clarity, currentStylecode)
  storePref("d_stone_type", action.diamond.stone_type, currentStylecode)
}
