import queryString from "query-string"
import { call, put, select } from "redux-saga/effects"

import api from "apis"
import {
  setDeliveryMethods,
  setLoadingShippingMethods,
} from "areas/checkout/store/actions"

export default function* loadDeliveryMethods({ setValue, values }) {
  const state = yield select()
  const orderData = state.checkout.order

  // We don't make this call until the shipping address has a valid postal code to work with
  if (
    orderData?.order?.currency !== "EUR" &&
    !values?.order?.ship_address_attributes?.postal_code?.trim()
  ) {
    return
  }

  if (!orderData?.delivery?.refetch_on_change) {
    return
  }

  yield put(setLoadingShippingMethods(true))

  const query = {
    order_json: JSON.stringify(values?.order),
    order_token: orderData?.order?.token,
  }
  const result = yield call(
    api.get,
    `/secure/api/v10/checkout/shipping_methods?${queryString.stringify(query)}`
  )
  const data = result?.data

  if (data && data.delivery_json) {
    yield put(setDeliveryMethods(data.delivery_json))

    if (data.delivery_json.current_ship_method) {
      setValue("ship_method_id", data.delivery_json.current_ship_method)
    }
  }
}
