import { call, put } from "redux-saga/effects"

import api from "apis"

import { setUserPreferences } from "../actions"

function* updateUserPreferences({
  actions: { onError, onSuccess },
  guidToken,
  values,
}) {
  try {
    const response = yield call(
      api.patch,
      `/secure/api/v10/preferences/${guidToken}`,
      values
    )

    if (response.data) {
      yield put(setUserPreferences(response.data))
    }
    onSuccess()
  } catch (err) {
    console.log("Error while updating preferences", err)
    onError(err)
  }
}
export default updateUserPreferences
