import { faBagShopping } from "@jewlr/pro-light-svg-icons/faBagShopping"
import { Box, Flex } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { fetchCartList } from "app-store-v2/header/actions"

import { NavIcon } from "./components"

const CountBadge = styled(Flex)`
  align-items: center;
  background-color: ${(props) => props.theme.colors.link};
  border-radius: 50%;
  color: white;
  font-size: 10px;
  height: 15px;
  justify-content: center;
  margin-left: 12px;
  margin-top: -26px;
  position: absolute;
  text-align: center;
  width: 15px;
  ${(props) => props.theme.mediaQueries.widescreen`
    margin-left: 14px;
    margin-top: -30px;
  `}
`

const CartIcon = ({ count, fetchCartList, isMobile }) => {
  useEffect(() => {
    fetchCartList()
  }, [])

  return (
    <Box
      aria-label="Cart"
      as={Link}
      data-cy={`header-cart-icon-${isMobile ? "mobile" : "desktop"}`}
      data-lc="cart-icon"
      display="inline-block"
      position="relative"
      to="/cart"
    >
      <NavIcon icon={faBagShopping} />
      {count > 0 && <CountBadge>{count}</CountBadge>}
    </Box>
  )
}

CartIcon.propTypes = {
  count: PropTypes.number,
  fetchCartList: PropTypes.func,
  isMobile: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  count: state.cart.count,
})

const mapDispatchToProps = (dispatch) => ({
  fetchCartList: () => dispatch(fetchCartList()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CartIcon)
