import { call, put } from "redux-saga/effects"

import api from "apis"

import { setCart, toggleSavedItemAlert } from "../actions"

export default function* addSavedItem(action) {
  try {
    const result = yield call(api.post, "/api/v5/carts/save_for_later", {
      line_item_id: action.lineItemId,
    })
    if (result.data.cart) {
      yield put(setCart(result.data.cart))
      yield put(toggleSavedItemAlert(true))
    }
  } catch (error) {
    console.error(error)
  }
}
