import { call, put, select } from "redux-saga/effects"

import api from "apis"

import { addWizardData } from "../actions"

export default function* fetchWarrantyOffers(action) {
  const state = yield select()
  let price = action.price

  // Remove chosen warranty from overall price when refetching to prevent fetching wrong warranty
  // ie. $199 product + $20.99 warranty = $219.99 we still want to fetch for $199 and not $219.99
  if (state.choices.warranty.price) {
    price = price - state.choices.warranty.price
  }

  let url = `/api/v10/products/${state.choices.style_code?.value}/warranty_offers?display_price=${price}`
  let result = yield call(api.get, url)

  if (result?.data) {
    let warrantyData = {
      heading: { pref: "warranty", title: "Warranty" },
      options: result.data.plans.adh,
      pref: "warranty",
    }

    yield put(addWizardData(warrantyData))
  }
}
