import { call, put, select } from "redux-saga/effects"

import api from "apis"
import { toggleSearching } from "app-store-v2/search/actions"
import processSuggestions from "helpers/process-suggestions"

export default function* fetchSearchSuggestions(action) {
  const query = action.query.trim()
  const state = yield select()

  if (state.search.currentQuery === query) {
    yield put(toggleSearching(false))
    return
  }

  const per_page = 6
  let url = `/api/v10/collections/search/load_react_collection?auto_suggest=true&per_page=${per_page}&k=${encodeURI(
    query
  )}`

  let auto_suggest_results = []

  yield put(toggleSearching(true))

  const result = yield call(api.get, url)

  yield processSuggestions(result, query, auto_suggest_results)
}
