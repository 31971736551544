export const mapShapeToStylecode = {
  CU: "S",
  EM: "M",
  OV: "O",
  PC: "P",
  PE: "T",
  RD: "R",
}

export const mapShapecodeToName = {
  CU: "Cushion",
  EM: "Emerald",
  OV: "Oval",
  PC: "Princess",
  PE: "Pear",
  RD: "Round",
}

export const mapCaratToStylecode = {
  "0.50": "1",
  "0.70": "2",
  "1.00": "3",
}
