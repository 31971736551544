import {
  put,
  call,
  select
} from 'redux-saga/effects'
import api from 'apis'
import {
  clearShareData,
  setModalType,
  toggleEmailShareModal
} from 'app-store-v2/share/actions'
import cartShare from './cart-share'
import emailShare from './email-share'



export default function* toggleShareModal({ modal }) {
  yield put(clearShareData())
  yield put(setModalType(modal))
  if (modal === 'cart' || modal === 'wishlist') {
    yield call(cartShare, { cart_type: modal })
  } else {
    yield call(emailShare)
  }
  yield put(toggleEmailShareModal(true))
}