import { call, put, select } from "redux-saga/effects"

import api from "apis"
import { filterProductOptionsV2 } from "helpers/product/product-filtering"

import { updateImages } from "../actions"

export default function* getPreviewImage(action) {
  const state = yield select()

  let overwriteChoices = {}
  try {
    if (
      action.pref === "gift" ||
      (!state.wizardUI.prefStore && action.type != "LOAD_DEFAULTS")
    ) {
      return
    }

    // For UPDATE_TMP_DIAMOND only proceeds to fetch images if needed (getPreviewImage == true)
    if (action.type == "UPDATE_TMP_DIAMOND") {
      if (!action.getPreviewImage) {
        return
      }
      // Overwrite s1 with diamond stone
      overwriteChoices["s1"] = action.data.stone_code
    } else if (
      state.diamondUI.currentStep > 0 &&
      state.diamondUI.currentStep < state.diamondUI.steps.length &&
      state?.diamondUI?.tmpDiamond?.stone_code
    ) {
      // Overwrite s1 with the temp diamond stone, e.g:
      // When user change the metal/engraving... before finalize the diamond
      overwriteChoices["s1"] = state.diamondUI.tmpDiamond.stone_code
    }

    let product_style_code = state.choices.style_code?.value

    // Retrieve product defaults
    let params = new URLSearchParams(
      `locale=${sl.locale}&dim=500&use_srcset=true`
    )
    Object.keys(state.choices)
      .filter((key) => !state.choices[key].disabled)
      .forEach((key) => {
        const choice = state.choices[key]

        if (/^e[0-9]$/.test(key)) {
          const default_engraving =
            state.wizard.data[key]?.default_engraving || ""
          params.append(
            key,
            choice.value || (choice.is_default ? default_engraving : "")
          )
        } else {
          params.append(key, choice.value || "")
        }

        if (choice.value && ["img1", "img2"].indexOf(key) !== -1) {
          const handwritingEngraving =
            state.wizard.data[key]?.handwriting_engraving
          if (handwritingEngraving)
            params.append(`${key}_th`, choice.threshold_value)
        }
      })

    Object.keys(overwriteChoices).forEach((key) => {
      params.append(key, overwriteChoices[key])
    })
    // CHANGE:

    // if choice pref "ch" exists, and value is empty
    // will append the default chain type, first one in the chainTypes or user selected
    // and the first chain code in the options

    const chains = ["ch", "ch2"]
    chains.forEach((ch) => {
      if (state.choices[ch]?.value === "" && !state.choices[ch]?.disabled) {
        const wizardData = state.wizard.data[ch]
        const chainTypes = filterProductOptionsV2(
          wizardData.types,
          state.choices,
          true
        )
        let defaultType = chainTypes[0]
        const selectedChainType = state.choices[ch].chain_type
        if (selectedChainType) {
          defaultType = chainTypes.find((t) => t.type === selectedChainType)
          if (!defaultType) defaultType = chainTypes[0]
        }
        if (defaultType?.options) {
          const formattedOptions = filterProductOptionsV2(
            defaultType.options,
            state.choices,
            true
          )
          const chainValue = formattedOptions[0]?.value || ""
          if (chainValue) params.set(ch, chainValue)
        }
      }
    })

    // END OF CHANGE

    const url = `/api/v10/products/${product_style_code}/images?${params.toString()}`
    const result = yield call(api.get, url)

    yield put(updateImages(result?.data?.product_image))

    // If the default prefs text area is available, populate the choices
    if (
      document.body.contains(document.querySelector("#default_product_params"))
    ) {
      try {
        const firstImg =
          state.previewImage.data.images[
            state.previewImage.data.all_views[0] + "_view"
          ]
        var default_params_string = "{"
        var params_hash_string = "{\n"
        params_hash_string += `  :'style-code' => "${product_style_code}",\n`
        params_hash_string += `  :'image-url' => "${
          firstImg ? firstImg.src : ""
        }",\n`
        params_hash_string += "  :data => {\n"
        params_hash_string += `    :sku => "${
          state.choices.sku ? state.choices.sku.value : ""
        }",\n`
        for (let i = 2; i < 15; i++) {
          if (
            state.choices["m" + i] &&
            state.choices["m" + i].value &&
            state.choices["m" + i].value.trim() != ""
          ) {
            default_params_string +=
              "'m" + i + "'=>'" + state.choices["m" + i].value.trim() + "',"
          }
        }
        for (let i = 0; i < 15; i++) {
          if (
            state.choices["e" + i] &&
            state.choices["e" + i].value &&
            state.choices["e" + i].value.trim() != ""
          ) {
            default_params_string +=
              "'e" +
              i +
              "'=>'" +
              state.choices["e" + i].value.trim().replace("'", "\\'") +
              "',"
            params_hash_string += `    :e${i} => "${state.choices[
              "e" + i
            ].value.trim()}",\n`
          }
        }
        if (
          state.choices["sc"] &&
          state.choices["sc"].value &&
          state.choices["sc"].value.trim() != ""
        ) {
          params_hash_string +=
            "    :sc => " + state.choices["sc"].value.trim() + ",\n"
        }
        for (let i = 1; i < 15; i++) {
          if (
            state.choices["s" + i] &&
            state.choices["s" + i].value &&
            state.choices["s" + i].value != ""
          ) {
            default_params_string +=
              "'s" + i + "'=>'" + state.choices["s" + i].value.trim() + "',"
            params_hash_string += `    :s${i} => "${state.choices[
              "s" + i
            ].value.trim()}",\n`
          }
        }
        for (let i = 1; i < 15; i++) {
          if (
            state.choices["a" + i] &&
            state.choices["a" + i].value &&
            state.choices["a" + i].value != ""
          ) {
            default_params_string +=
              "'a" + i + "'=>'" + state.choices["a" + i].value.trim() + "',"
            params_hash_string += `    :a${i} => "${state.choices[
              "a" + i
            ].value.trim()}"`
          }
        }
        default_params_string += "}"
        params_hash_string += "  }\n},"
        if (default_params_string != "{}") {
          const defProductParams = document.querySelector(
            "#default_product_params"
          )
          if (defProductParams) {
            defProductParams.value = default_params_string.replace(",}", "}")
          }
        }
        const productParamsHash = document.querySelector("#product_params_hash")
        if (productParamsHash) {
          productParamsHash.value = params_hash_string.replace(
            ",\n  }",
            "\n  }"
          )
        }
      } catch (e) {
        console.error("DEFAULT PARAMS BOX ERROR", e)
      }
    }
  } catch (e) {
    console.error(e)
  }
}
