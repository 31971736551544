import api from "apis"
import { isTestMode } from "helpers/application"

export default function* forgotPassword({
  email,
  actions: { onError, onSuccess },
}) {
  try {
    let params = {
      email: email,
      site: sl.config.site_url,
    }
    if (isTestMode) {
      params.test_mode = true
    }
    const result = yield api.post(
      "/secure/api/v10/user/forgot_password",
      params
    )

    if (result.success) {
      onSuccess(email)
    }
  } catch (err) {
    onError(err?.errors?.[0])
    console.log(err)
  }
}
