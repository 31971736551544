import { all, throttle } from "redux-saga/effects"

import { FETCH_LANDING_ELEMENTS } from "./actions"
import fetchLandingElements from "./sagas/fetch-landing-elements"

function* landingSagas() {
  yield all([
    // We need to throttle the event because the action will dispatch on every scroll event
    throttle(2500, FETCH_LANDING_ELEMENTS, fetchLandingElements),
  ])
}

export default landingSagas
