import { all, call, spawn } from "redux-saga/effects"

import accountSagas from "areas/account/store/sagas"
import cartSagas from "areas/cart/store/sagas"
import checkoutSagas from "areas/checkout/store/sagas"
import collectionSagas from "areas/collection/store/sagas"
import productSagas from "areas/product/store/sagas"

import appSagas from "./app/sagas"
import headerSagas from "./header/sagas"
import landingSagas from "./landing/sagas"
import shareSagas from "./share/sagas"
import wishlistSagas from "./wishlist/sagas"

// https://redux-saga.js.org/docs/advanced/RootSaga.html#keeping-everything-alive
function* rootSaga() {
  const sagas = [
    appSagas,
    accountSagas,
    cartSagas,
    collectionSagas,
    productSagas,
    headerSagas,
    checkoutSagas,
    shareSagas,
    wishlistSagas,
    landingSagas,
  ]

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga)
            break
          } catch (e) {
            console.error(e)
          }
        }
      })
    )
  )
}

export default rootSaga
