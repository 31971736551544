import { call, put } from "redux-saga/effects"

import api from "apis"
import { openModal } from "components/modal"

import { setReferralName } from "../actions"

export default function* fetchReferralName(action) {
  const url = `/secure/api/v10/user/referral/name/${action.ref}`

  try {
    const response = yield call(api.get, url)
    if (response?.data) {
      yield put(setReferralName(response.data.referral_name))
      openModal("#referral")
    }
  } catch (err) {
    console.error("Error fetching referral", err)
  }
}
