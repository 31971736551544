export const getPaymentInfo = (order) => {
  const payment =
    order.payments &&
    Array.isArray(order.payments.completed) &&
    order.payments.completed.length > 0
      ? order.payments.completed[0]
      : null
  let payment_source = "other"
  if (payment) {
    if (payment.payment_method === "paypal") {
      payment_source = "paypal"
    } else if (payment.payment_method === "apple_pay") {
      payment_source = "applepay"
    } else if (payment.payment_method == "creditcard") {
      switch (payment.cc_type) {
        case "visa":
          payment_source = "card_visa"
          break
        case "master":
          payment_source = "card_mastercard"
          break
        case "american express":
          payment_source = "card_amex"
          break
        case "discover":
          payment_source = "card_discover"
          break
        default:
          payment_source = "card_other"
      }
    }
  }
  return payment_source
}
