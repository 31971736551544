import URI from "urijs"

import { sRGB2BW } from "helpers/colorSpace"

// KEEP THIS IN SYNC WITH Strapi::Import
const DEVICE_SIZES = [
  320,
  360,
  420,
  640,
  768,
  828,
  1024,
  1280,
  1440,
  1600,
  1920,
  2048,
  3840,
]

/*
 * Resizes the image using Cloudflare in Staging/Prod environments
 * Available options: https://developers.cloudflare.com/images/about/#options
 * Example: resized(imgSrc, { width: 320 })
 */
export const resizeImg = (src, opts = {}) => {
  if (!src) return ""

  const isExternalSrc =
    src.includes("cms.jewlr.com") || src.includes("assets.jewlr.com")

  if (!isExternalSrc || sl.config.env === "development") {
    return src
  }

  // If src contains a base, the second parameter is ignored by URL
  const imgUrl = new URI(src, `https://${sl.config.site_url}`)

  // Interpolate the parameters into the Cloudflare format
  const optString = Object.keys(opts)
    .map((key) => `${key}=${opts[key]}`)
    .join(",")

  // f=auto is desired in all situations
  imgUrl.pathname(`/cdn-cgi/image/f=auto,${optString}${imgUrl.pathname()}`)

  return imgUrl.toString()
}

export const getImgPixels = (img) => {
  if (!(img instanceof HTMLCanvasElement)) img = copyImg(img)
  const ctx = img.getContext("2d")
  return ctx.getImageData(0, 0, img.width, img.height)
}

export const copyImg = (img) => {
  const image = createImg(img.width, img.height)
  const ctx = image.getContext("2d")
  if (img instanceof ImageData) {
    ctx.putImageData(img, 0, 0)
  } else {
    ctx.drawImage(img, 0, 0, img.width, img.height)
  }
  return image
}

export const createImg = (w, h) => {
  const can = document.createElement("canvas")
  can.width = w
  can.height = h
  return can
}

export const updateImgThreshold = (
  pixels,
  threshold,
  light = [255, 255, 255],
  dark = [0, 0, 0]
) => {
  let d = pixels.data,
    i = 0,
    l = d.length
  while (l-- > 0) {
    ;[d[i], d[i + 1], d[i + 2]] = sRGB2BW(
      [d[i], d[i + 1], d[i + 2]],
      threshold,
      light,
      dark
    )
    i += 4
  }
  return pixels
}

export const updateImgBrightness = (pixels, brightness) => {
  // multiply, e.g., brightness = 1.35
  let dA = pixels.data
  for (let i = 0; i < dA.length; i += 4) {
    let red = dA[i] // Extract original red color [0 to 255]
    let green = dA[i + 1] // Extract green
    let blue = dA[i + 2] // Extract blue
    let brightenedRed = brightness * red
    let brightenedGreen = brightness * green
    let brightenedBlue = brightness * blue
    dA[i] = brightenedRed
    dA[i + 1] = brightenedGreen
    dA[i + 2] = brightenedBlue
  }
  return pixels
}

export const updateImgContrast = (pixels, contrast) => {
  // contrast: [-100..100]
  let d = pixels.data
  contrast = contrast / 100 + 1 // convert to decimal & shift range: [0..2]
  const intercept = 128 * (1 - contrast)
  for (let i = 0; i < d.length; i += 4) {
    //r,g,b,a
    d[i] = d[i] * contrast + intercept
    d[i + 1] = d[i + 1] * contrast + intercept
    d[i + 2] = d[i + 2] * contrast + intercept
  }
  return pixels
}

export const thresholdSettings = {
  HIGH_1: 110,
  HIGH_2: 90,
  HIGH_3: 70,
  LOW_1: 150,
  LOW_2: 170,
  LOW_3: 190,
  MEDIUM: 130,
}

// scales the image by (float) scale < 1
// returns a canvas containing the scaled image.
export const downScaleImage = (img, scale) => {
  const imgCV = document.createElement("canvas")
  imgCV.width = img.width
  imgCV.height = img.height
  const imgCtx = imgCV.getContext("2d")
  imgCtx.drawImage(img, 0, 0)
  return _downScaleCanvas(imgCV, scale)
}

const _downScaleCanvas = (cv, scale) => {
  if (!(scale < 1) || !(scale > 0))
    throw new Error("scale must be a positive number < 1")
  var sqScale = scale * scale // square scale = area of source pixel within target
  var sw = cv.width // source image width
  var sh = cv.height // source image height
  var tw = Math.floor(sw * scale) // target image width
  var th = Math.floor(sh * scale) // target image height
  var sx = 0,
    sy = 0,
    sIndex = 0 // source x,y, index within source array
  var tx = 0,
    ty = 0,
    yIndex = 0,
    tIndex = 0 // target x,y, x,y index within target array
  var tX = 0,
    tY = 0 // rounded tx, ty
  var w = 0,
    nw = 0,
    wx = 0,
    nwx = 0,
    wy = 0,
    nwy = 0 // weight / next weight x / y
  // weight is weight of current source point within target.
  // next weight is weight of current source point within next target's point.
  var crossX = false // does scaled px cross its current px right border ?
  var crossY = false // does scaled px cross its current px bottom border ?
  var sBuffer = cv.getContext("2d").getImageData(0, 0, sw, sh).data // source buffer 8 bit rgba
  var tBuffer = new Float32Array(3 * tw * th) // target buffer Float32 rgb
  var sR = 0,
    sG = 0,
    sB = 0 // source's current point r,g,b

  for (sy = 0; sy < sh; sy++) {
    ty = sy * scale // y src position within target
    tY = 0 | ty // rounded : target pixel's y
    yIndex = 3 * tY * tw // line index within target array
    crossY = tY != (0 | (ty + scale))
    if (crossY) {
      // if pixel is crossing botton target pixel
      wy = tY + 1 - ty // weight of point within target pixel
      nwy = ty + scale - tY - 1 // ... within y+1 target pixel
    }
    for (sx = 0; sx < sw; sx++, sIndex += 4) {
      tx = sx * scale // x src position within target
      tX = 0 | tx // rounded : target pixel's x
      tIndex = yIndex + tX * 3 // target pixel index within target array
      crossX = tX != (0 | (tx + scale))
      if (crossX) {
        // if pixel is crossing target pixel's right
        wx = tX + 1 - tx // weight of point within target pixel
        nwx = tx + scale - tX - 1 // ... within x+1 target pixel
      }
      sR = sBuffer[sIndex] // retrieving r,g,b for curr src px.
      sG = sBuffer[sIndex + 1]
      sB = sBuffer[sIndex + 2]

      if (!crossX && !crossY) {
        // pixel does not cross
        // just add components weighted by squared scale.
        tBuffer[tIndex] += sR * sqScale
        tBuffer[tIndex + 1] += sG * sqScale
        tBuffer[tIndex + 2] += sB * sqScale
      } else if (crossX && !crossY) {
        // cross on X only
        w = wx * scale
        // add weighted component for current px
        tBuffer[tIndex] += sR * w
        tBuffer[tIndex + 1] += sG * w
        tBuffer[tIndex + 2] += sB * w
        // add weighted component for next (tX+1) px
        nw = nwx * scale
        tBuffer[tIndex + 3] += sR * nw
        tBuffer[tIndex + 4] += sG * nw
        tBuffer[tIndex + 5] += sB * nw
      } else if (crossY && !crossX) {
        // cross on Y only
        w = wy * scale
        // add weighted component for current px
        tBuffer[tIndex] += sR * w
        tBuffer[tIndex + 1] += sG * w
        tBuffer[tIndex + 2] += sB * w
        // add weighted component for next (tY+1) px
        nw = nwy * scale
        tBuffer[tIndex + 3 * tw] += sR * nw
        tBuffer[tIndex + 3 * tw + 1] += sG * nw
        tBuffer[tIndex + 3 * tw + 2] += sB * nw
      } else {
        // crosses both x and y : four target points involved
        // add weighted component for current px
        w = wx * wy
        tBuffer[tIndex] += sR * w
        tBuffer[tIndex + 1] += sG * w
        tBuffer[tIndex + 2] += sB * w
        // for tX + 1; tY px
        nw = nwx * wy
        tBuffer[tIndex + 3] += sR * nw
        tBuffer[tIndex + 4] += sG * nw
        tBuffer[tIndex + 5] += sB * nw
        // for tX ; tY + 1 px
        nw = wx * nwy
        tBuffer[tIndex + 3 * tw] += sR * nw
        tBuffer[tIndex + 3 * tw + 1] += sG * nw
        tBuffer[tIndex + 3 * tw + 2] += sB * nw
        // for tX + 1 ; tY +1 px
        nw = nwx * nwy
        tBuffer[tIndex + 3 * tw + 3] += sR * nw
        tBuffer[tIndex + 3 * tw + 4] += sG * nw
        tBuffer[tIndex + 3 * tw + 5] += sB * nw
      }
    } // end for sx
  } // end for sy

  // create result canvas
  var resCV = document.createElement("canvas")
  resCV.width = tw
  resCV.height = th
  var resCtx = resCV.getContext("2d")
  var imgRes = resCtx.getImageData(0, 0, tw, th)
  var tByteBuffer = imgRes.data
  // convert float32 array into a UInt8Clamped Array
  var pxIndex = 0 //
  for (
    sIndex = 0, tIndex = 0;
    pxIndex < tw * th;
    sIndex += 3, tIndex += 4, pxIndex++
  ) {
    tByteBuffer[tIndex] = Math.ceil(tBuffer[sIndex])
    tByteBuffer[tIndex + 1] = Math.ceil(tBuffer[sIndex + 1])
    tByteBuffer[tIndex + 2] = Math.ceil(tBuffer[sIndex + 2])
    tByteBuffer[tIndex + 3] = 255
  }
  // writing result to canvas.
  resCtx.putImageData(imgRes, 0, 0)
  return resCV
}

export const getImgSrcSet = (url, intrinsic_width) =>
  [
    ...[...DEVICE_SIZES]
      .filter((size) => size < intrinsic_width)
      .map((size) => `${url}?w=${size} ${size}w`),
    `${url} ${intrinsic_width}w`,
  ].join(", ")
