import { call, put } from "redux-saga/effects"

import api from "apis"
import {
  doLogin,
  setLoginNotification,
  fetchCartList,
} from "app-store-v2/app/actions"
import { history } from "app-store-v2/index"
import { fetchWishList } from "app-store-v2/wishlist/actions"
import { submitCheckoutSignedinEvent } from "helpers/cart"

export default function* fetchLoginResult(actions) {
  let url = "/api/v10/login"
  let result = null
  const { onSuccess, onError } = actions.action

  try {
    result = yield call(api.authPost, url, actions.credential)
    if (history?.location?.pathname === "/checkout") {
      submitCheckoutSignedinEvent()
    }
    let obj = result.data
    let remember_me =
      actions.action && actions.action.remember_me
        ? actions.action.remember_me
        : false
    yield put(doLogin(obj, "jewlrorders", remember_me))
    yield put(fetchCartList(true))
    yield put(fetchWishList(true))
    if (onSuccess) {
      onSuccess()
    }

    return result
  } catch (e) {
    yield put(setLoginNotification(e.errors?.[0]))
    if (onError) {
      onError(e.errors?.[0])
    }

    return
  }
}
